/* eslint-disable @typescript-eslint/no-unused-vars */
import { Height } from '@mui/icons-material';
import { FormHelperText } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
// import Select from 'react-dropdown-select';
import Select, { components, NoticeProps } from 'react-select';

type InputProps = {
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  extraStyle?: any;
  value?: any;
  error?: any;
  options?: any;
  labelKey?: string;
  valueKey?: string;
  id?: string;
  name?: string;
  ref?: any;
  color?: any;
  disabled?: boolean;
  selectedValue?: string;
  autoFocus?: boolean;
  searchable?: boolean;
  onClose?: any;
  tabIndex?: number;
  onFocus?: (src: any) => void;
  sorting?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  custmfn?: any;
  Position?: boolean;
  onBlur?: (src: any) => void;
  triggermodal?: (value: any) => void;
  report?: any;
};

const NoOptionsMessage = (props: NoticeProps) => {
  return (
    <components.NoOptionsMessage {...props}>
      <p>No Options Available</p>
    </components.NoOptionsMessage>
  );
};

const CustomSelect = (props: InputProps): ReactElement => {
  const {
    placeHolder,
    onChange,
    value = '',
    error,
    options,
    labelKey = 'label',
    valueKey = 'value',
    id,
    name,
    ref,
    disabled,
    color,
    autoFocus = false,
    searchable = true,
    isClearable = true,
    onFocus,
    tabIndex,
    sorting,
    isMulti = false,
    closeMenuOnSelect = true,
    Position,
    report = false,
    triggermodal,
    onBlur,
  } = props;
  const [option, setOption] = useState([]);
  const handlr = () => {
    props.custmfn && props.custmfn();
  };
  useEffect(() => {
    setOption(
      sorting
        ? options
            .filter((ch: any) => !_.isNull(ch[valueKey]) || !_.isUndefined(ch[valueKey]))
            .map((e: any) => ({
              ...e,
              label: e[labelKey] ? String(e[labelKey]) : '',
              // [labelKey]: e[labelKey] ? String(e[labelKey]) : '',
              value: e[valueKey],
            }))
            .sort((a: any, b: any) =>
              String(a[labelKey]).toLowerCase() > String(b[labelKey]).toLowerCase() ? 1 : -1,
            )
        : options
            ?.filter((ch: any) => !_.isNull(ch[valueKey]) || !_.isUndefined(ch[valueKey]))
            ?.map((e: any) => ({
              ...e,
              label: e[labelKey] ? String(e[labelKey]) : '',
              // [labelKey]: e[labelKey] ? String(e[labelKey]) : '',
              value: e[valueKey],
            })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const formatOptionLabel = ({ value, label, isSelectable, isDisabled, custmdwn }: any) => {
    if (options.length === 0 && custmdwn) {
      return (
        <div style={{ position: 'relative' }}>
          {' '}
          <div
            style={{
              maxWidth: '100%',
              overflow: 'hidden',
              // color: '#344051',
              color: '#BDBDBD',
              fontSize: '16px',
              textOverflow: 'ellipsis',
              // backgroundColor: 'none',
              whiteSpace: 'nowrap',
              // background: '#ffffff',
            }}
            className='noptioncustomselect'>
            <span
              style={{
                backgroundColor: 'white',
                position: 'relative',
                zIndex: 103,
              }}>
              No Options Available
            </span>
          </div>
          <div className='customoptionbg'>
            <div className='customoptionbg2'></div>
            <div className='customoptionbg3'></div>
          </div>
        </div>
      );
    }
    if (!isSelectable && custmdwn) {
      return (
        <div onClick={handlr} style={{ color: '#EE4B22' }}>
          {label}
        </div>
      );
    }
    if (Position && typeof triggermodal === 'function') {
      return (
        // <div onClick={() => triggermodal(value)}>{label} - {value.target}</div>
        <div onClick={() => triggermodal(value)}>
          {label}
          {value && value.target && Number(value.target) > 0 ? ` - ${value.target}` : ''}
        </div>
      );
    }
    if (color) {
      return (
        <div
          style={{
            height: '15px',
            width: '15px',
            backgroundColor: label,
            borderRadius: '10px',
          }}
        />
      );
    }
    return <div>{label}</div>;
  };

  return (
    <>
      <Select
        onBlur={onBlur}
        className={error ? 'select-error' : ''}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        autoFocus={autoFocus}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={isClearable}
        isSearchable={searchable}
        id={id}
        components={{
          IndicatorSeparator: (props) => {
            return null && NoOptionsMessage;
          },
        }}
        ref={ref}
        isMulti={isMulti}
        onFocus={onFocus && onFocus}
        formatOptionLabel={formatOptionLabel}
        styles={Position ? positionCustomStyles : report ? reportustomStyle : customStyles}
        isDisabled={disabled}
        name={name}
        tabIndex={tabIndex}
        placeholder={placeHolder}
        {...(value !== '' || isMulti
          ? {
              value:
                isMulti && Position
                  ? value && Array.isArray(value) && value.length
                    ? option.filter(
                        (el: any) =>
                          el[valueKey] &&
                          !_.isEmpty(el[valueKey].userId) &&
                          value.map((el1: any) => el1.userId).includes(el[valueKey].userId),
                      )
                    : // ? option.filter((el) => value.includes(el[valueKey]))
                      null
                  : isMulti
                    ? value && Array.isArray(value) && value.length
                      ? option.filter((el) => value.includes(el[valueKey]))
                      : null
                    : option &&
                        option.length &&
                        value &&
                        option.find((el) => String(el[valueKey]) === String(value))
                      ? option.find((el) => String(el[valueKey]) === String(value))
                      : null,
            }
          : {})}
        options={!_.isEmpty(option) && option.length ? option : []}
        onChange={(e: any) => {
          if (isMulti) {
            onChange &&
              onChange(
                e
                  ? {
                      target: {
                        value: e && Array.isArray(e) && e.length ? e.map((e) => e[valueKey]) : null,
                      },
                    }
                  : { target: { value: '' } },
              );
            return;
          }
          onChange && onChange(e ? { target: { value: e['value'] } } : { target: { value: '' } });
        }}
      />
      {error && (
        <FormHelperText
          style={{ color: 'red !important', marginLeft: '0px', fontSize: '12px !important' }}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};
const customStyles = {
  valueContainer: (provided: any) => ({
    ...provided,
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '2px 0px 2px 8px !important',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '6px !important',
    boxShadow: 'none !important',
    fontSize: '14px !important',
    minHeight: '40px !important',
    border: '1px solid #D0D5DD',
    textTransform: 'capitalize',
    '&:hover': {
      boxShadow: 'none !important',
      outline: 'none !important',
    },
  }),
  indicatorContainer: (provided: any, state: any) => ({
    padding: '6px !important',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: '10000',
    fontSize: '14px !important',
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    maxHeight: '250px !important',
    textTransform: 'capitalize',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    maxWidth: '100%',
    overflow: 'hidden',
    // color: '#344051',
    color: '#BDBDBD',
    fontSize: '14px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  option: (provided: any, state: any) => {
    if (state && state.data && state.data.custmdwn && state.data.custmdwn) {
      return {
        ...provided,
        fontSize: '14px !important',
        cursor: 'pointer',
        background: state.data.custmdwn ? '#FFF7F5' : 'EE4B22',
        fontFamily: 'Poppins-Medium !important',
        color: '#EE4B22',
        '&:hover': {
          backgroundColor: state.data.custmdwn ? '#FDE8E2' : 'EE4B22',
          color: '#ED3E12',
        },
        // textAlign:'center',
      };
    }
    return {
      ...provided,
      fontSize: '14px !important',
      cursor: 'pointer',
      background: state.isSelected ? 'EE4B22' : 'EE4B22',
      fontFamily: 'Poppins-Medium !important',
    };
  },
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px !important',
  }),
};

const positionCustomStyles = {
  ...customStyles,
  multiValueRemove: (styles: any, state: any) => ({
    ...styles,
    display: 'none',
  }),
};
const reportustomStyle = {
  ...customStyles,
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #EE4B22',
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    outline: state.isFocused ? 'none' : provided.outline,
    '&:hover': {
      border: '1px solid #EE4B22',
    },
    '&:focus': {
      border: '1px solid #EE4B22',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:active': {
      border: '1px solid #EE4B22',
    },
  }),
};
export default CustomSelect;
