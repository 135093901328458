/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
  Button,
} from '@mui/material';
import Loader from 'components/loader';
import React, { useRef, useState } from 'react';
import { To, useNavigate, useSearchParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { ResumesENDPOINT } from 'utils/Constant';
import ThreedotIcon from 'assets/png/threedot.svg';
import { noticePeriod } from 'utils/Config';
import { ApprovedComponent, PendingComponent, RejectedComponent } from '../CvUpload';
import moment from 'moment';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import PreIcon from 'assets/png/pre.svg';

type defaultprops = {
  positionId?: string;
  tableData: any;
  settableData: any;
  loading: any;
  setloading: any;
  getList: () => void;
};
const CvSent: React.FC<defaultprops> = ({
  tableData,
  settableData,
  setloading,
  loading,
  getList,
}) => {
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);

  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };

  return (
    <Grid container>
      {loading && <Loader />}
      <Grid item md={12} sx={{ position: 'relative' }}>
        <Button onClick={() => handleScroll(-200)} className='pre-btn'>
          <img src={PreIcon} alt='' />
        </Button>
        <Button onClick={() => handleScroll(200)} className='next-btn'>
          <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
        </Button>
        <TableContainer
          component={Paper}
          className='sb-table2'
          ref={containerRef}
          sx={{ width: '100% !important' }}>
          <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Candidate Name</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Pre. CTC</TableCell>
                <TableCell>Exp. CTC</TableCell>
                <TableCell>Notice </TableCell>
                <TableCell>Recruiter</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Sent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData && tableData.length ? (
                tableData.map((row: any) => {
                  const candidateName = `${row.personal.firstName} ${row.personal.lastName || ''}`;
                  const phoneNumber = `+${row.personal.countryCode} ${row.personal.phoneNumber}`;
                  const isCvSent =
                    row.approvalStatus === 'approved' && row.isCvSentToClient === true;
                  const noticePeriodData = noticePeriod.find(
                    (r: any) => r.value === row.personal.noticePeriod,
                  );
                  const isApproved = row.approvalStatus === 'approved';
                  const isRejected = row.approvalStatus === 'rejected';
                  const isPending = row.approvalStatus === 'inReview';
                  return (
                    <TableRow
                      key={row.name}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        // width: 'calc(100% - 93px)',
                      }}>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        CT-{row.code ? row.code : '-'}
                      </TableCell>
                      <TableCell sx={{ minwidth: '100px' }}>
                        <Typography
                          component={'p'}
                          sx={{
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            color: '#5F2EE5',
                            fontSize: '14px !important',
                          }}
                          onClick={() => {
                            gotoNavigate(`${AdminPrivateRoutes.RESUMES.RESUMESVIEW}?id=${row.id}`);
                          }}>
                          <TableTooltip value={row.personal ? candidateName : '-'}></TableTooltip>
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        <TableTooltip value={row.personal ? phoneNumber : '-'}></TableTooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        <TableTooltip
                          value={row.personal ? row.personal.email : '-'}></TableTooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        <TableTooltip
                          value={row.personal ? row.personal.presentLocation : '-'}></TableTooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}
                        width={'100px'}>
                        {row.personal
                          ? `${Number(row.personal.currentCtc).toLocaleString('en-IN')}`
                          : '-'}
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}
                        width={'100px'}>
                        {row.personal
                          ? `${Number(row.personal.expectedCtc).toLocaleString('en-IN')}`
                          : '-'}
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        {row.personal && noticePeriodData?.label ? noticePeriodData?.label : '-'}
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        <TableTooltip
                          value={
                            row.createdBy
                              ? `${row.createdBy.firstName} ${
                                  row.createdBy.lastName ? row.createdBy.lastName : ''
                                }`
                              : '-'
                          }></TableTooltip>
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }}>
                        {isApproved && <ApprovedComponent />}
                        {isRejected && <RejectedComponent />}
                        {isPending && (
                          <PendingComponent
                            resumeId={row.id}
                            candidateName={candidateName}
                            getList={getList}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        {row.createdAt ? `${moment(row.createdAt).format('DD-MMM-YYYY')}` : '-'}
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        {row.cvSentOn ? `${moment(row.cvSentOn).format('DD-MMM-YYYY')}` : '-'}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <tr className={'text-center'}>
                  <td colSpan={12} align={'center'}>
                    No Record(s) Found.
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
export default CvSent;
