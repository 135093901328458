/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Button,
  // Button,
  // Checkbox,
  // FormControlLabel,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import * as R from 'ramda';

import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Bigprint from 'assets/png/print-blue.svg';
import { To, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useEffect, useRef, useState } from 'react';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import moment from 'moment';
import _ from 'lodash';
import Commoncontent from '../common/commoncontent';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Editbluepen from 'assets/png/Frame.svg';

const ViewPositions = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    menuList,
    userInfo: { organizationId: orgdy, id: Useruniqueid },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [clientModule, setClientModule] = useState([]);

  // const [orgdy,setorgdy] = useState('');
  const navigate = useNavigate();
  const [params, setParams] = useState<any>({});
  const [searchParams] = useSearchParams();
  const [Ld, setLd] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });

  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  const { state } = useLocation();

  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'Position');
      setClientModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              {/* <Link underline='hover' color='inherit' href=''>
                  <img src={ArrowIcon} alt='arrow' className='arrows' />
                  Setting
                </Link> */}
              <Typography
                color='text.primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSVIEW)}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Position
              </Typography>
              <Typography
                color='text.primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => gotoNavigate(AdminPrivateRoutes.POSITIONS.VIEWPOSITIONS)}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> View Position
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>View Position</Typography>
            <Typography
              component={'div'}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {R.findIndex(R.propEq('Add Position', 'screenName'))(
                clientModule ? clientModule : [],
              ) !== -1 && state?.edit ? (
                <Typography
                  component={'div'}
                  sx={{ marginRight: '24px', cursor: 'pointer' }}
                  onClick={() =>
                    gotoNavigate(
                      `${
                        AdminPrivateRoutes.POSITIONS.POSITIONSADD
                      }?mode=edit&positionid=${searchParams.get('id')}`,
                    )
                  }>
                  <img src={Editbluepen} alt='edit' />
                </Typography>
              ) : (
                <></>
              )}
              {/* <Typography
                component={'div'}
                //   onClick={() => gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSADD)}
              >
                <img src={Bigprint} alt='plus' />
              </Typography> */}
            </Typography>
          </Typography>
          <Commoncontent />
        </Grid>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default ViewPositions;
