/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  Typography,
  Breadcrumbs,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  TableContainer,
  Paper,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@mui/material';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import Loader from 'components/loader';
import React, { useEffect, useRef, useState } from 'react';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import PreIcon from 'assets/png/pre.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import { To, useNavigate } from 'react-router-dom';
import { BillingFilterDrawer } from './ViewBillingFilter/BillingFilterDrawer';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import { BillingENDPOINT } from 'utils/Constant';
import { useDebounce } from 'hooks';
import { getAllListData } from 'services/CommonServices';
// import { useAppSelector } from 'store/hooks';
import Paginate from 'components/Pagination/Pagination';
import moment from 'moment';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { AccessDenied, BillingStatus, ResumeStatusType } from 'utils/helper';
import { useAppSelector } from 'store/hooks';
import _ from 'lodash';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { finalCurrency } from 'utils/Config';

const Defaultparam = {
  search: null,
  resumeId: null,
  order: null,
  skip: 0,
  sortby: null,
  status: null,
  take: 50,
  from: null,
  to: null,
  organizationId: null,
  createdBy: null,
};
const Billing = () => {
  const [Ld, setLd] = useState(false);
  const {
    menuList,
    userInfo: { organizationId, roleId, id: userId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [approvalTotal, setApprovalTotal] = useState<number>(0);
  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);
  const navigate = useNavigate();
  const [clientModule, setClientModule] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [billingData, setBillingData] = useState<any>([]);
  const gotoNavigate = (link: To, state?: any) => {
    navigate(link, state);
  };
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const containerRef = useRef(null) as any;
  const handleScroll = (scrollAmount: number) => {
    const newScrollPosition = scrollPosition + scrollAmount;
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      element.scrollLeft = newScrollPosition;
    }
  };
  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  function handleFilterPag(filteredobj1: any) {
    setParams((prev: any) => ({
      ...prev,
      ...filteredobj1,
    }));
  }
  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  }

  const listdataapi = () => {
    const searcharr = [
      'resumeStatusTypes=billing,l1BillingApproval,finalBillingApproval',
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${organizationId ? `organizationId=${organizationId}` : null}`,
      `${debouncedParams.status ? `approvalStatus=${debouncedParams.status}` : null}`,
      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${debouncedParams.resumeId ? `resumeId=${debouncedParams.resumeId}` : null}`,
      `${![14, 6].includes(roleId) && userId ? `createdBy=${userId}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);

    getAllListData(
      `${BillingENDPOINT.billing}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        setBillingData(val.data);
        setpagetotal(Number(val.total));
        setApprovalTotal(val.totalApprovalPending ? Number(val.totalApprovalPending) : 0);
        setLd(!true);
      })
      .catch((err) => {
        setLd(!true);
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };
  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'Billing');
      setClientModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>

              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Billing
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>Billing</Typography>
            <Typography
              component={'div'}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className='expot-icon'>
                <span>
                  <ExcelIcon
                    url={`${BillingENDPOINT.billing}${
                      Object.values({ ...params }).length > 0 && '?'
                    }${[
                      'resumeStatusTypes=billing,l1BillingApproval,finalBillingApproval',
                      `${
                        debouncedParams.search
                          ? `search=${encodeURIComponent(debouncedParams.search)}`
                          : null
                      }`,
                      `${organizationId ? `organizationId=${organizationId}` : null}`,
                      `${
                        debouncedParams.status ? `approvalStatus=${debouncedParams.status}` : null
                      }`,
                      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
                      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
                      `${debouncedParams.resumeId ? `resumeId=${debouncedParams.resumeId}` : null}`,
                      `${roleId !== 6 && userId ? `createdBy=${userId}` : null}`,
                      'order=desc',
                      'sortby=createdAt',
                      'type=export',
                    ]
                      .filter((ea: any) => ea !== 'null')
                      .join('&')}`}
                    fileName={'billings'}
                    setLoader={setLd}
                  />
                </span>
                <span style={{ width: '12.17px' }}></span>
                <span>{/* <PdfIcon /> */}</span>
              </div>
              {/* {R.findIndex(R.propEq('Approval Client', 'screenName'))(
                clientModule ? clientModule : [],
              ) !== -1 && ( */}
              {R.findIndex(R.propEq('Approve Billing', 'screenName'))(
                clientModule ? clientModule : [],
              ) !== -1 && (
                <Typography
                  component={'div'}
                  className='add-btn-apruv'
                  onClick={() => {
                    if (approvalTotal) {
                      gotoNavigate(AdminPrivateRoutes.BILLING.BILLINGAPPROVE);
                    }
                  }}>
                  <Typography component={'div'}>Approve Billing</Typography>{' '}
                  <Typography className='apruv-cunt'>{approvalTotal}</Typography>
                </Typography>
              )}

              {/* )} */}
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <Typography component={'div'} className='d-flex-cc flex-sb'>
                <TextField
                  className='custom-search'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '320px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 50,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIcon style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Typography
                  component={'div'}
                  className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                  //   onClick={dweropn}
                >
                  <img src={Funnelimg} alt='filter funnel icon' />
                  <span className='filterfunnel' onClick={dweropn}>
                    More Filters
                  </span>
                </Typography>
              </Typography>
              <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                  <Table
                    sx={{ overflowX: 'auto', minWidth: 650 }}
                    stickyHeader
                    className='custom-table custom-table-client'
                    aria-label='sticky Header'>
                    <TableHead>
                      <TableRow
                        sx={{
                          marginLeft: '50px',
                          marginRight: '43px',
                          width: 'calc(100% - 93px)',
                          position: 'relative',
                        }}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Candidate Name
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('candidateName', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('candidateName', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Position
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('position', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('position', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Client Name
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('clientName', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('clientName', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Billing Amount
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('billingAmount', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('billingAmount', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Billing Date
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('billingDate', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('billingDate', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Recruiter
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('recruiter', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('recruiter', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          CRM
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('crm', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('crm', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Created On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} align='center'>
                          Status
                        </TableCell>
                        {[14].includes(roleId) && (
                          <>
                            <TableCell sx={{ whiteSpace: 'nowrap' }} align='center'>
                              Invoice
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }} align='center'>
                              Payment
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {billingData && billingData.length > 0 ? (
                        billingData.map((row: any) => {
                          const findResumeStatusL1 = row?.resume?.resumeStatus?.find(
                            (e: any) => e.type === ResumeStatusType.L1BillingAppoval,
                          );
                          const findResumeStatusL2 = row?.resume?.resumeStatus?.find(
                            (e: any) => e.type === ResumeStatusType.L2BillingAppoval,
                          );
                          const InvoiceData = row?.invoice;
                          const paymentData = row?.payment;
                          return (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                // verticalAlign: 'baseline',
                                marginLeft: '50px',
                                marginRight: '43px',
                                width: 'calc(100% - 93px)',
                              }}>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '120px',
                                  hyphens: 'auto',
                                  cursor: 'pointer',
                                  color: '#5F2EE5 !important',
                                }}
                                onClick={() => {
                                  gotoNavigate(
                                    `${AdminPrivateRoutes.BILLING.BILLINGVIEW}?id=${row.id}`,
                                    {
                                      state: { isApproval: false, id: row.id, status: row.status },
                                    },
                                  );
                                }}>
                                <TableTooltip
                                  value={
                                    row && row?.resume?.personal?.firstName
                                      ? `${row?.resume?.personal?.firstName} ${
                                          row?.resume?.personal?.lastName
                                            ? row?.resume?.personal?.lastName
                                            : ''
                                        }`
                                      : '-'
                                  }></TableTooltip>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '100px',
                                }}>
                                <TableTooltip
                                  value={
                                    row && row?.resume?.position?.title
                                      ? row?.resume?.position?.title
                                      : '-'
                                  }></TableTooltip>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '200px',
                                }}>
                                {row && row?.resume?.client?.corporateName
                                  ? row?.resume?.client?.corporateName
                                  : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                {row && row?.billingAmount
                                  ? finalCurrency(
                                      row?.billingAmount,
                                      row?.billingAmountCurrency.toLocaleUpperCase(),
                                    )
                                  : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '120px',
                                }}>
                                {row && row?.billingDate
                                  ? moment(row?.billingDate).format('DD-MMM-YYYY')
                                  : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                {row && row?.resume?.createdBy
                                  ? `${row?.resume?.createdBy.firstName} ${
                                      row?.resume?.createdBy.lastName ? row?.createdBy.lastName : ''
                                    }`
                                  : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '120px',
                                }}>
                                <TableTooltip
                                  value={
                                    row && row?.resume?.position?.crm[0]?.crm?.user?.firstName
                                      ? `${row?.resume?.position?.crm[0]?.crm?.user?.firstName} ${
                                          row?.resume?.position?.crm[0]?.crm?.user?.lastName
                                            ? row?.resume?.position?.crm[0]?.crm?.user?.lastName
                                            : ''
                                        }`
                                      : '-'
                                  }></TableTooltip>
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: '120px',
                                }}>
                                {row && row?.createdAt
                                  ? moment(row.createdAt).format('DD-MMM-YYYY')
                                  : '-'}
                              </TableCell>
                              <TableCell sx={{ minWidth: '120px' }}>
                                {findResumeStatusL1?.status === BillingStatus.L1Rejected ? (
                                  <Tooltip
                                    title={`${findResumeStatusL1?.approver?.firstName} 
                                      ${findResumeStatusL1?.approver?.lastName} (${moment(
                                        findResumeStatusL1?.updatedAt,
                                      ).format('DD-MMM-YYYY hh:mm a')} Rejected)`}
                                    arrow={true}>
                                    <Typography
                                      className='billing-approved-btn'
                                      sx={{
                                        backgroundColor: '#FF0000',
                                        color: '#FFFFFF',
                                        width: '80px',
                                      }}>
                                      L1-R
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {findResumeStatusL1 ? (
                                      findResumeStatusL1?.status ===
                                      BillingStatus.L1ApprovalPending ? (
                                        <Tooltip
                                          title={`${findResumeStatusL1?.approver?.firstName} 
                                      ${findResumeStatusL1?.approver?.lastName} Approval Pending`}
                                          arrow={true}>
                                          <Typography
                                            className='billing-approved-btn'
                                            sx={{ backgroundColor: '#D9D9D9', color: '#1C314A' }}>
                                            L1-P
                                          </Typography>
                                        </Tooltip>
                                      ) : findResumeStatusL1?.status ===
                                        BillingStatus.L1Approved ? (
                                        <Tooltip
                                          title={`${findResumeStatusL1?.approver?.firstName} 
                                      ${findResumeStatusL1?.approver?.lastName} (${moment(
                                        findResumeStatusL1?.updatedAt,
                                      ).format('DD-MMM-YYYY hh:mm a')} Approved)`}
                                          arrow={true}>
                                          <Typography
                                            className='billing-approved-btn'
                                            sx={{ backgroundColor: '#16C06E', color: '#FFFFFF' }}>
                                            L1-A
                                          </Typography>
                                        </Tooltip>
                                      ) : findResumeStatusL1?.status ===
                                        BillingStatus.L1Rejected ? (
                                        <Tooltip
                                          title={`${findResumeStatusL1?.approver?.firstName} 
                                      ${findResumeStatusL1?.approver?.lastName} (${moment(
                                        findResumeStatusL1?.updatedAt,
                                      ).format('DD-MMM-YYYY hh:mm a')} Rejected)`}
                                          arrow={true}>
                                          <Typography
                                            className='billing-approved-btn'
                                            sx={{ backgroundColor: '#FF0000', color: '#FFFFFF' }}>
                                            L1-R
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        '-'
                                      )
                                    ) : (
                                      '-'
                                    )}

                                    {findResumeStatusL2 ? (
                                      findResumeStatusL2?.status ===
                                      BillingStatus.L2ApprovalPending ? (
                                        <Tooltip
                                          title={`${findResumeStatusL2?.approver?.firstName} 
                                      ${findResumeStatusL2?.approver?.lastName} Final Approval Pending`}
                                          arrow={true}>
                                          <Typography
                                            className='billing-approved-btn'
                                            sx={{ backgroundColor: '#D9D9D9', color: '#1C314A' }}>
                                            F-P
                                          </Typography>
                                        </Tooltip>
                                      ) : findResumeStatusL2?.status ===
                                        BillingStatus.L2Approved ? (
                                        <Tooltip
                                          title={`${findResumeStatusL2?.approver?.firstName} 
                                      ${findResumeStatusL2?.approver?.lastName} (${moment(
                                        findResumeStatusL2?.updatedAt,
                                      ).format('DD-MMM-YYYY hh:mm a')} Final Approved)`}
                                          arrow={true}>
                                          <Typography
                                            className='billing-approved-btn'
                                            sx={{ backgroundColor: '#16C06E', color: '#FFFFFF' }}>
                                            F-A
                                          </Typography>
                                        </Tooltip>
                                      ) : findResumeStatusL2?.status ===
                                        BillingStatus.L2Rejected ? (
                                        <Tooltip
                                          title={`${findResumeStatusL2?.approver?.firstName} 
                                      ${findResumeStatusL2?.approver?.lastName} (${moment(
                                        findResumeStatusL2?.updatedAt,
                                      ).format('DD-MMM-YYYY hh:mm a')} Final Rejected)`}
                                          arrow={true}>
                                          <Typography
                                            className='billing-approved-btn'
                                            sx={{ backgroundColor: '#FF0000', color: '#FFFFFF' }}>
                                            F-R
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        '-'
                                      )
                                    ) : (
                                      '-'
                                    )}
                                    {/* {findResumeStatusL1 || findResumeStatusBH ? (
                                      findResumeStatusL1?.status ===
                                        BillingStatus.L1ApprovalPending ||
                                      findResumeStatusBH?.status ===
                                        BillingStatus.BhApprovalPending ? (
                                        <Tooltip
                                          title={'Business Head Approval Pending'}
                                          arrow={true}>
                                          <Typography
                                            className='billing-approved-btn'
                                            sx={{ backgroundColor: '#D9D9D9', color: '#1C314A' }}>
                                            BH-P
                                          </Typography>
                                        </Tooltip>
                                      ) : findResumeStatusBH?.status ===
                                        BillingStatus.BhApproved ? (
                                        <Tooltip
                                          title={`${findResumeStatusBH?.approver?.firstName} 
                                      ${findResumeStatusBH?.approver?.lastName} (${moment(
                                        findResumeStatusBH?.updatedAt,
                                      ).format('DD-MM-YYYY hh:mm a')} Approved)`}
                                          arrow={true}>
                                          <Typography
                                            className='billing-approved-btn'
                                            sx={{ backgroundColor: '#16C06E', color: '#FFFFFF' }}>
                                            BH-A
                                          </Typography>
                                        </Tooltip>
                                      ) : findResumeStatusBH?.status ===
                                        BillingStatus.BhRejected ? (
                                        <Tooltip
                                          title={`${findResumeStatusBH?.approver?.firstName} 
                                      ${findResumeStatusBH?.approver?.lastName} (${moment(
                                        findResumeStatusBH?.updatedAt,
                                      ).format('DD-MM-YYYY hh:mm a')} Rejected)`}
                                          arrow={true}>
                                          <Typography
                                            className='billing-approved-btn'
                                            sx={{ backgroundColor: '#FF0000', color: '#FFFFFF' }}>
                                            BH-R
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        '-'
                                      )
                                    ) : (
                                      '-'
                                    )} */}
                                  </Typography>
                                )}
                              </TableCell>
                              {[14].includes(roleId) && (
                                <>
                                  <TableCell
                                    sx={{
                                      minWidth: '120px',
                                    }}
                                    align='center'>
                                    {findResumeStatusL2?.status === BillingStatus.L2Approved &&
                                    InvoiceData ? (
                                      <Typography
                                        className='billing-invoice-btn '
                                        sx={{
                                          backgroundColor: '#F5AA1A',
                                          color: '#FFFFFF',
                                          width: '80px',
                                        }}>
                                        Generated
                                      </Typography>
                                    ) : findResumeStatusL2?.status === BillingStatus.L2Approved &&
                                      !InvoiceData ? (
                                      <Typography
                                        className='billing-invoice-btn '
                                        sx={{
                                          backgroundColor: '#4E7FFF',
                                          color: '#FFFFFF',
                                          width: '80px',
                                        }}>
                                        Invoice
                                      </Typography>
                                    ) : (
                                      '-'
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      minWidth: '120px',
                                    }}
                                    align='center'>
                                    {InvoiceData && paymentData?.type === 'payment' ? (
                                      <Typography
                                        className='billing-invoice-btn '
                                        sx={{
                                          backgroundColor: '#70A415',
                                          color: '#FFFFFF',
                                          width: '80px',
                                        }}>
                                        Created
                                      </Typography>
                                    ) : InvoiceData && paymentData?.type === 'writeoff' ? (
                                      <Typography
                                        className='billing-invoice-btn '
                                        sx={{
                                          backgroundColor: '#f455ef',
                                          color: '#FFFFFF',
                                          width: '80px',
                                        }}>
                                        Write Off
                                      </Typography>
                                    ) : InvoiceData && !paymentData ? (
                                      <Typography
                                        className='billing-invoice-btn '
                                        sx={{
                                          backgroundColor: '#16C06E',
                                          color: '#FFFFFF',
                                          width: '80px',
                                        }}>
                                        Payment
                                      </Typography>
                                    ) : (
                                      '-'
                                    )}
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={14} align={'center'}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '43px',
                              }}>
                              <NCAdedIcon />
                              <Typography className='no-client'>No Billing added</Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>
        </Grid>

        <BillingFilterDrawer
          setParams={setParams}
          isOpen={dweropen}
          closeDrawer={dwerclose}
          callback={handleFilterPag}
          setBillingData={setBillingData}
          setLoading={setLd}
          setpagetotal={setpagetotal}
          listdataapi={listdataapi}
        />
      </Grid>
      {Ld && <Loader />}
    </>
  );
};
export default Billing;
