import React from 'react';
import {
  Grid,
  Typography,
  Popover,
  Box,
  Button,
  Modal,
  TextField,
  NativeSelect,
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Controller, useForm } from 'react-hook-form';
import { OfferStatusSchema } from 'utils/ValidatorSchema';
import { resumeStatusToBgColor } from 'utils/helper';
import CvReject from 'assets/png/cv-reject.svg';
import CvApprove from 'assets/png/cv-approve.svg';
// import { OfferStatusType } from 'types';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { offerrejectReason } from 'utils/Config';
import CloseIcon from 'assets/png/close.svg';
import { patchData } from 'services/CommonServices';
import { ResumesENDPOINT } from 'utils/Constant';
import { yupResolver } from '@hookform/resolvers/yup';
import { StatusTag } from './statusTag';
// import moment from 'moment';

export const OfferComponent: React.FC<{
  resumeId: string;
  candidateName: string;
  getList: () => void;
  offerStatus: string;
  userId: string;
  crmUserId: string;
}> = ({ offerStatus, candidateName, resumeId, getList, userId, crmUserId }) => {
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [isRejectModalOpen, setRejectModalOpen] = React.useState(false);
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setPAnchorEl(null);
  };
  const handleModalClose = () => {
    reset();
    setRejectModalOpen(false);
  };
  const handleModalOpen = (status: string) => {
    setValue('status', status);
    setPAnchorEl(null);
    setRejectModalOpen(true);
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      offeredCtc: '',
      offeredCtcCurrency: 'inr',
      reason: '',
      status: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(OfferStatusSchema),
  });

  const onSubmit = (data: any) => {
    if (data.offeredCtc) {
      data.offeredCtc = data.offeredCtc ? parseInt(data.offeredCtc.replace(/,/g, '')) : 0;
    }
    patchData(
      '',
      {
        ...data,
      },
      `${ResumesENDPOINT.resumeList}/offer-status/${resumeId}`,
      true,
    )
      .then(() => {
        handleModalClose();
        getList();
      })
      .catch((e: any) => console.log(e));
  };

  return (
    <div>
      <Popover
        id={paid}
        anchorEl={panchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ width: '100%', padding: '1rem' }}>
        <Typography component={'div'}>
          <Typography
            onClick={() => handleModalOpen('OA')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={CvReject} alt='approve' />
            Accepted
          </Typography>
          <Typography
            onClick={() => handleModalOpen('OR')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={CvApprove} alt='reject' />
            Declined
          </Typography>
        </Typography>
      </Popover>
      <Typography component={'p'}>
        <StatusTag
          color='#4A5569'
          backgroundColor={resumeStatusToBgColor[offerStatus]}
          tag={offerStatus}
          userId={userId}
          crmUserId={crmUserId}
          onClick={(e: any) => {
            offerStatus === 'OP' ? userId === crmUserId && handleClick(e) : '';
          }}
        />
      </Typography>
      <Modal open={isRejectModalOpen} onClose={handleModalClose}>
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
              {getValues('status') === 'OR' ? 'Offer Declined' : 'Offer Accepted'}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleModalClose}
              />
            </Typography>
          </Typography>
          <form id='offer-status' className='private-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Candidate Name</Typography>
                  <CustomInput
                    disabled
                    value={candidateName}
                    placeHolder='Candidate Name'
                    className='custom-input'
                  />
                </Typography>
              </Grid>
              {getValues('status') === 'OA' && (
                <Grid container columnSpacing={2} md={12} sm={12} xs={12}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Offered Date <span>*</span>
                      </Typography>
                      <Controller
                        name='offeredDate'
                        control={control}
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <TextField
                              id='date'
                              type='date'
                              className='clientfilter-time-from-to'
                              defaultValue={value}
                              name={name}
                              onChange={onChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              // InputProps={{
                              //   inputProps: { min: moment().format('YYYY-MM-DD') },
                              // }}
                            />
                            {errors && errors.offeredDate && errors?.offeredDate?.message && (
                              <div className='Custom-error '>
                                {String(errors?.offeredDate?.message)}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Offered CTC <span>*</span>
                      </Typography>
                      <Controller
                        name='offeredCtc'
                        control={control}
                        render={({ field: { onChange, value, name } }) => (
                          <CustomInput
                            className='custom-input'
                            onChange={(e) => {
                              const rawValue = e.target.value.replace(/[^\d.]/g, '');
                              const numericValue = parseFloat(rawValue) || 0;
                              const formatCurrency = (value: any, locale: any, currency: string) =>
                                new Intl.NumberFormat(locale, {
                                  currency,
                                  maximumFractionDigits: 0,
                                }).format(value);

                              const targetCurrency: any = getValues('offeredCtcCurrency')
                                ? getValues('offeredCtcCurrency').toLocaleUpperCase()
                                : 'INR';
                              const currencySettings: any = {
                                USD: { locale: 'en-US', currency: 'USD' },
                                INR: { locale: 'en-IN', currency: 'INR' },
                                AED: { locale: 'ar-AE', currency: 'AED' },
                              };
                              const { locale, currency } = currencySettings[targetCurrency];
                              const formattedValue = formatCurrency(numericValue, locale, currency);
                              onChange(formattedValue);
                            }}
                            value={value}
                            name={name}
                            placeHolder='Offered CTC'
                            currency
                            // type={'number'}
                            error={errors.offeredCtc?.message}
                            customComponent={
                              <Box
                                sx={{
                                  minWidth: 120,
                                  position: 'absolute',
                                  right: '-40px',
                                  transform: 'scale(0.9)',
                                }}>
                                <Controller
                                  control={control}
                                  name='offeredCtcCurrency'
                                  render={({ field: { value, name } }) => {
                                    return (
                                      <NativeSelect
                                        // defaultValue={getValues('minCurrency')}
                                        value={value}
                                        name={name}
                                        onChange={(e: any) => {
                                          if (e.target.value !== value) {
                                            setValue('offeredCtc', '');
                                          }
                                          setValue('offeredCtcCurrency', e.target.value);
                                        }}
                                        // disabled
                                        className='position-currency-option'
                                        IconComponent={ExpandMoreRoundedIcon}
                                        sx={{
                                          border: 'none !important',
                                          minHeight: '20px !important',
                                          '&::before': {
                                            borderBottom: 'transparent !important',
                                          },
                                          '& > select': {
                                            minWidth: '40px !important',
                                            border: 'none !important',
                                            outline: 'none !important',
                                            marginRight: '8px',
                                          },
                                        }}>
                                        <option value={'inr'}>INR</option>
                                        <option value={'usd'}>USD</option>
                                        <option value={'aed'}>AED</option>
                                      </NativeSelect>
                                    );
                                  }}
                                />
                              </Box>
                            }
                          />
                        )}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {getValues('status') === 'OR' && (
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Reason <span>*</span>
                    </Typography>
                    <Controller
                      name='reason'
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={offerrejectReason}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Reason'
                          error={errors.reason?.message}
                        />
                      )}
                    />
                  </Typography>
                </Grid>
              )}
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Remarks</Typography>
                  <Controller
                    name='remarks'
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <CustomInput
                        name={name}
                        value={value}
                        isTextArea={true}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className='custom-input'
                        placeHolder='Remarks'
                        error={errors.remarks?.message}
                      />
                    )}
                  />
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'flex-end' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button form={'offer-status'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
