import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import TagsAnnotator from './icon/tagsAnnotator';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';

const RADIAN = Math.PI / 180;

// Render customized label inside the pie chart slices
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

// Custom Tooltip component for the PieChart
const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload; // Accessing the name and value from payload

    return (
      <div
        style={{
          backgroundColor: 'white', // White background
          padding: '10px',
          borderRadius: '5px',
          color: 'black', // Black text
          fontSize: '14px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
        }}>
        <p style={{ margin: 0 }}>
          <strong>{name}</strong>
        </p>
        <p style={{ margin: '5px 0' }}>
          Value: <strong>{value}</strong>
        </p>
      </div>
    );
  }
  return null;
};

// Main Joinee component
const Joinee = ({ resumeData }: any) => {
  const Today = new Date();
  const oneWeekBefore = new Date(Today);
  oneWeekBefore.setDate(Today.getDate() - 6);
  const JoineesFinalData = resumeData && resumeData.length > 0;
  const defaultData = [
    { name: 'Joined', color: '#00C49F' },
    { name: 'Not Joined', color: '#0088FE' },
    { name: 'Deferred', color: '#FFBB28' },
  ];

  return (
    <div className='childcon2'>
      <header>
        <div className='title'>Joinees</div>
      </header>

      {!JoineesFinalData ? (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '225px',
            fontSize: '16px',
            fontWeight: '600',
          }}>
          No data available
        </p>
      ) : (
        <>
          <main>
            <div
              className='donutchartout'
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '50px',
                height: '300px',
              }}>
              <ResponsiveContainer width='100%' height='100%'>
                <PieChart>
                  <Pie
                    data={resumeData}
                    cx='50%'
                    cy='50%'
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill='#8884d8'
                    dataKey='value'>
                    {resumeData.map((entry: { color: string | undefined }, index: any) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  {/* Custom Tooltip */}
                  <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>
            </div>

            <div className='labelcon'>
              {defaultData.map((er: { name: string; color: string | undefined }, idx: any) => (
                <div className='labelItem' key={idx}>
                  <TagsAnnotator txt={er.name} bg={er.color} value={resumeData[idx]?.value} />
                </div>
              ))}
            </div>
          </main>
          <Grid item md={12} sm={12} xs={12}>
            <Typography
              component={'p'}
              className='custom-field'
              style={{
                marginTop: '110px',
                border: '1px solid #E4E7EC',
                borderRadius: '0px 0px 8px 8px',
                padding: '8px',
                backgroundColor: '#F2F4F7',
                fontSize: '14px',
                fontFamily: 'Poppins-Regular !important',
              }}>
              Joinees for the Period{' '}
              <Typography
                component={'span'}
                style={{ fontSize: '14px', fontFamily: 'Poppins-Regular !important' }}>
                {`${moment(oneWeekBefore).format('DD-MMM-YYYY')} to ${moment(Today).format(
                  'DD-MMM-YYYY',
                )}`}
              </Typography>
            </Typography>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Joinee;
