import {
  Breadcrumbs,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Paginate from 'components/Pagination/Pagination';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import { useDebounce } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { BillingENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import Loader from 'components/loader';
import moment from 'moment';

const Defaultparam = {
  search: null,
  order: null,
  skip: 0,
  sortby: null,
  take: 50,
  organizationId: null,
};

export const PaymentMail = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [params, setParams] = useState<Record<string, number | string | null>>(Defaultparam);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [isLoading, setLoading] = useState(false);
  const debouncedParams = useDebounce(params, 500);
  const [commonState, setcommonstate] = useState<any[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };

  const listdataapi = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${debouncedParams.search}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLoading(true);
    getAllListData(
      `${BillingENDPOINT.PaymentMail}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
          setpagetotal(Number(val.total));
        } else {
          setcommonstate([]);
        }
        setLoading(!true);
      })
      .catch(() => {
        setLoading(!true);
      });
  };

  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);
  return (
    <Grid container>
      {isLoading && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.DASHBOARD)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Sent Items
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>Sent Items</Typography>
        </Typography>

        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <Typography component={'div'} className='d-flex-cc flex-sb'>
              <TextField
                className='custom-search'
                placeholder='Search'
                id='outlined-start-adornment'
                value={params.search}
                sx={{ width: '320px' }}
                onChange={(e: any) =>
                  setParams((prev: any) => ({
                    ...prev,
                    search: e.target.value,
                    skip: 0,
                    take: 50,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img src={SearchIcon} alt='search' />
                    </InputAdornment>
                  ),
                  endAdornment: params.search ? (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={() => {
                          setParams(() => ({ ...Defaultparam, search: '' }));
                        }}>
                        <CloseIcon style={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Typography>
            <Typography
              component={'div'}
              className='mt-32'
              sx={{ position: 'relative', width: '100%' }}>
              <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                <Table
                  sx={{ overflowX: 'auto', minWidth: 650 }}
                  stickyHeader
                  className='custom-table custom-table-client'
                  aria-label='sticky Header'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>To</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>CC</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>Subject</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>Message</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>Sent Date</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>Sent By</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commonState.length ? (
                      commonState.map((row) => {
                        const to = row?.to ?? '-';
                        const subject = row?.subject ?? '-';
                        const cc = row?.cc ?? '-';
                        const message = row?.message.slice(0, 50).concat('...') ?? '-';
                        const sentDate = moment(row?.createdAt).format('DD-MMM-YYYY') ?? '-';
                        const sentBy = `${row?.user?.firstName ?? ' '} ${
                          row?.user?.lastName ?? ' '
                        }`;

                        return (
                          <TableRow key={row.id}>
                            <TableCell sx={{ whiteSpace: 'wrap', maxWidth: '100px' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {to}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'wrap', maxWidth: '100px' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {cc}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', maxWidth: '150px' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {subject}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', maxWidth: '300px' }}>
                              <div
                                style={{
                                  cursor: 'pointer',
                                  color: '#5F2EE5',
                                  fontSize: '12px',
                                  maxWidth: '200px',
                                }}
                                onClick={() =>
                                  gotoNavigate(
                                    `${AdminPrivateRoutes.PAYMENTMAIL.PAYMENTMAILVIEW}?id=${row.id}`,
                                  )
                                }
                                dangerouslySetInnerHTML={{ __html: message }}></div>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', maxWidth: '100px' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {sentDate}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', maxWidth: '100px' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {sentBy}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={14} align={'center'}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginBottom: '43px',
                            }}>
                            <NCAdedIcon />
                            <Typography className='no-client'>Mail items not found</Typography>
                          </div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
