import { Grid, Typography, Breadcrumbs, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import Loader from 'components/loader';
import { getSingleData } from 'services/CommonServices';
import { VERSIONHISTORYENDPOINT } from 'utils/Constant';
import moment from 'moment';

export const VersionHistoryView = () => {
  const [Ld, setLd] = useState(false);
  const [versionData, setVersionData] = useState<any>([]);
  const [descriptionData, setDescriptionData] = useState<any>([]);
  const { editId }: any = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    setLd(true);
    getSingleData(editId, VERSIONHISTORYENDPOINT.versionHistory)
      .then((res: any) => {
        if (
          res &&
          !_.isEmpty(res.data) &&
          res.data &&
          res.data.length > 0 &&
          !_.isEmpty(res.data[0])
        ) {
          const data = res?.data[0];

          if (!_.isEmpty(data)) {
            setVersionData((pl: any) => [
              ...pl,
              {
                title: 'Version Name',
                subtitle: data?.versionId ? `Version ${data?.versionId}` : '-',
              },
              {
                title: 'Release Date',
                subtitle: data?.createdAt ? moment(data?.createdAt).format('DD-MMM-YYYY') : '-',
              },
            ]);
            setDescriptionData((pl: any) => [
              ...pl,
              {
                title: 'Description',
                subtitle: data?.releaseNotes ? data?.releaseNotes : '-',
              },
            ]);
          }
        }
        setLd(false);
      })
      .catch((e: any) => {
        setLd(false);
        console.log(e);
      });
  }, []);
  return (
    <Grid container>
      {Ld && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href='/version-history'>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Version History
            </Link>
            <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> View Version
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'> View Version</Typography>

          <Typography component={'div'} className='d-flex'>
            <Typography
              component={'div'}
              sx={{
                width: '96px',
                height: '36px',
                borderRadius: '6px',
                padding: '12px 10px 10px 10px',
                border: '1px solid #D0D5DD',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 500,
                color: '#344051',
                cursor: 'pointer',
              }}
              onClick={() => navigate(AdminPrivateRoutes.VERSIONHISTORY.VERSIONHISTORYLIST)}>
              Cancel
            </Typography>
          </Typography>
        </Typography>
      </Grid>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='main-content view-position'
            style={{ padding: '20px', minHeight: '100%' }}>
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              style={{ minHeight: '100%' }}>
              <main className='main-content'>
                {versionData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className='sub-heading'>{dat1.subtitle}</div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
            </Typography>
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              sx={{ minHeight: '100% !important' }}>
              <Typography className='bdiveder' />
              <main className='main-content'>
                {descriptionData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx} style={{ maxWidth: '100%' }}>
                      <div className='heading'>{dat1.title}</div>
                      <div className='sub-heading'>{dat1.subtitle}</div>
                    </div>
                  );
                })}
              </main>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
