import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import PreIcon from 'assets/png/pre.svg';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from 'assets/png/nsearch.svg';
import CloseIcon from '@mui/icons-material/Close';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import Paper from '@mui/material/Paper';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import Paginate from 'components/Pagination/Pagination';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import moment from 'moment';
import ThreedotIcon from 'assets/png/threedot.svg';
import View from 'assets/png/View-leave.svg';
import ViewDrawer from '../ViewDrawer';
import { To, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import AddIcon from 'assets/png/addplus.svg';
import ApplyLeave from '../ApplyLeave';
import LeaveModal from '../LeaveModal';
import { getAllListData } from 'services/CommonServices';
import { LEAVE_ENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/loader';
import { useDebounce } from 'hooks';
import * as R from 'ramda';
import { CircularProgress } from '@mui/joy';
import { LeaveFilterDrawer } from '../LeaveFilter/LeaveFilterDrawer';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const Defaultparam = {
  search: null,
  order: null,
  skip: 0,
  sortby: null,
  take: 50,
  fromDate: null,
  toDate: null,
  leaveTypeId: null,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '865px',
  height: '700px',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '6px',
  p: 3,
};

const ViewLeaveList = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const [pagetotal, setPageTotal] = useState<number>(0);
  const [leaveData, setLeaveData] = useState<any>([]);
  const [panchorEl, setPAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const [drawerOpen, setdDrawerOpen] = useState(false);
  const [applyDrawerOpen, setApplyDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [listLeaveData, setListLeaveData] = useState<any>([]);
  const [totalPending, setTotalPending] = useState<any>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [holidayModalOpen, setHolidayOpen] = useState<boolean>(false);
  const debouncedParams = useDebounce(params, 500);
  const [branchId, setBranchId] = useState<string>('');
  const [listHoliday, setListHoliday] = useState<any>([]);
  const [leaveCountData, setLeaveCountData] = useState<any>([]);
  const [rowData, setRowData] = useState<any>({});
  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const { menuList, userInfo }: any = useAppSelector((store: any) => store.userLogin);
  const [clientModule, setClientModule] = useState([]);

  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const sortablecliks = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, row?: any) => {
    setRowData(row);
    setLeaveData([
      {
        key: 'Leave From',
        value: moment(row.fromDate).format('DD-MMM-YYYY'),
      },
      {
        key: 'Leave To',
        value: moment(row.toDate).format('DD-MMM-YYYY'),
      },
      {
        key: 'Leave Type',
        value: row?.policy?.type?.type,
      },
      {
        key: 'Session',
        value: row?.isFullDayLeave
          ? 'Full Day'
          : `${row?.sessionFrom === 1 ? 'First Half' : 'Second Half'} - ${
              row?.sessionTo === 2 ? 'Second Half' : 'First Half'
            }`,
      },
      {
        key: 'Reason',
        value: row?.note,
      },
      {
        key: 'Created By',
        value: `${row?.user?.firstName} ${row?.user?.lastName}`,
      },
      {
        key: 'Created On',
        value: moment(row?.createdAt).format('DD-MMM-YYYY'),
      },
      {
        key: 'status',
        value: row?.status,
      },
    ]);
    setPAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setPAnchorEl(null);
  };

  const getRandomColor = () => {
    const myArray = ['primary', 'neutral', 'danger', 'success', 'warning'];
    const randomIndex = Math.floor(Math.random() * myArray.length);
    const randomElement: any = myArray[randomIndex];
    return randomElement;
  };

  const toggleDrawer = (open: boolean) => {
    setdDrawerOpen(open);
  };

  const toggleApplyDrawer = (open: boolean) => {
    setApplyDrawerOpen(open);
  };

  const modalOpenFn = (open: boolean) => {
    setModalOpen(open);
  };

  const holidayModalClose = () => setHolidayOpen(false);

  const holiday = () => {
    setHolidayOpen(true);
  };
  function handleFilterPag(filteredobj1: any) {
    setParams((prev: any) => ({
      ...prev,
      ...filteredobj1,
    }));
  }
  const convertToDecimalString = (value: any) => {
    if (value == Math.floor(value)) {
      return parseFloat(value).toFixed(0);
    } else {
      const decimalPlaces = value.toString().split('.')[0].length;
      return parseFloat(value).toFixed(decimalPlaces);
    }
  };

  const getUserData = () => {
    setLoader(true);
    getAllListData(`${SETTING_ENDPOINT.users}?id=${userInfo.id}`, true)
      .then((res) => {
        setBranchId(res.data[0].branchId);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  };

  const getLeavePolicyData = () => {
    setLoader(true);
    getAllListData(
      `${LEAVE_ENDPOINT.policyTypeGet}?status=true&organizationId=${userInfo.organizationId}&userId=${userInfo.id}`,
    )
      .then((res) => {
        const addColorData = res.data.map((data: any) => {
          return { ...data, color: getRandomColor() };
        });
        setLeaveCountData(addColorData);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  };

  const getHolidayData = () => {
    setLoader(true);
    getAllListData(
      `${SETTING_ENDPOINT.leaves.holidays}?organizationId=${
        userInfo.organizationId
      }&branchId=${branchId}&year=${new Date().getFullYear()}&status=true&order=asc&sortby=holidayOn`,
      true,
    )
      .then((res) => {
        setListHoliday(res.data);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  };

  const getLeaveData = () => {
    setLoader(true);
    const searcharr = [
      `${userInfo.organizationId ? `organizationId=${userInfo.organizationId}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.fromDate ? `from=${debouncedParams.fromDate}` : null}`,
      `${debouncedParams.toDate ? `to=${debouncedParams.toDate}` : null}`,
      `${debouncedParams.leaveTypeId ? `typeId=${debouncedParams.leaveTypeId}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
      'screenType=view',
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    getAllListData(
      `${LEAVE_ENDPOINT.leaves}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((leaveData) => {
        setListLeaveData(leaveData.data);
        setPageTotal(leaveData.total);
        setTotalPending(leaveData?.totalPending ?? 0);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    getLeaveData();
  }, [debouncedParams]);

  useEffect(() => {
    getHolidayData();
  }, [branchId]);

  useEffect(() => {
    getUserData();
    getLeavePolicyData();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'My Leaves');
      setClientModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);

  return (
    <>
      {loader && <Loader />}
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit' href={''} style={{ cursor: 'pointer' }}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Leaves
              </Link>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>Leaves</Typography>
            <Typography
              component={'div'}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '16px' }}></div>
              <Typography component={'div'} className='d-flex-a'>
                {/* <Typography className='expot-icon'>
                  <span>
                    <ExcelIcon
                      // url={
                      //   'client?type=export&order=asc&sortby=corporateName&approvalStatus=approved'
                      // }
                      fileName={'Leaves'}
                    />
                  </span>
                </Typography> */}
                <Typography component={'div'} className='add-btn-apruv' onClick={holiday}>
                  <Typography component={'div'}> List of Holidays</Typography>
                </Typography>
                <div style={{ width: '16px' }}></div>
                {R.findIndex(R.propEq('Approve Leave', 'screenName'))(
                  clientModule ? clientModule : [],
                ) !== -1 && (
                  <Typography
                    component={'div'}
                    className='add-btn-apruv'
                    onClick={() => {
                      if (totalPending > 0) {
                        gotoNavigate(AdminPrivateRoutes.MyLeave.ApproveDetails);
                      }
                    }}>
                    <Typography component={'div'}>Approve Leave</Typography>{' '}
                    <Typography className='apruv-cunt'>{totalPending}</Typography>
                  </Typography>
                )}
                <div style={{ width: '16px' }}></div>
                {R.findIndex(R.propEq('Create Leave', 'screenName'))(
                  clientModule ? clientModule : [],
                ) !== -1 && (
                  <Typography
                    component={'div'}
                    className='client-add-btn'
                    onClick={() => toggleApplyDrawer(true)}>
                    <img src={AddIcon} alt='plus' />
                    <Typography>Request Leave</Typography>
                  </Typography>
                )}
              </Typography>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <Typography component={'div'} className='d-flex-cc flex-sb'>
                <TextField
                  className='custom-search'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '320px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 50,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIcon style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    // maxWidth: '700px',
                    // overflowX: 'auto',
                    // overflowY: 'hidden !important',
                  }}>
                  {leaveCountData?.map((data: any, i: number) => (
                    <Typography
                      key={i}
                      component={'div'}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: '20px',
                      }}>
                      {' '}
                      <Box
                        position='relative'
                        // display='inline-flex'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        <CircularProgress
                          determinate
                          color={data.color ? data.color : ''}
                          variant='soft'
                          value={
                            (Number(data?.policies[0]?.applicableTo[0].available) /
                              data?.policies[0]?.quota) *
                            100
                          }
                          thickness={6}
                          size={'lg'}
                        />
                        <Box position='absolute' textAlign='center'>
                          <Typography
                            variant='h6'
                            component='div'
                            sx={{
                              fontSize: '10px !important',
                              color: '#344051',
                              marginBottom: '10px !important',
                            }}>
                            {convertToDecimalString(data?.policies[0]?.applicableTo[0]?.available)}
                          </Typography>
                        </Box>
                        <Box position='absolute' textAlign='center'>
                          <Typography
                            variant='h6'
                            component='div'
                            sx={{
                              fontSize: '10px !important',
                              color: '#344051',
                              marginTop: '12px !important',
                            }}>
                            Days
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        className='d-flex-ja'
                        sx={{ color: '#637083', fontSize: '8px !important' }}>
                        {data?.type ? data?.type : '-'}
                      </Typography>
                    </Typography>
                  ))}
                </Typography>
                <Typography
                  className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                  onClick={dweropn}>
                  <img src={Funnelimg} alt='filter funnel icon' />
                  <span className='filterfunnel' />
                </Typography>
              </Typography>
              <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    className='custom-table custom-table-client'
                    aria-label='sticky Header'>
                    <TableHead>
                      <TableRow
                        sx={{
                          marginLeft: '50px',
                          marginRight: '43px',
                          // width: 'calc(100% - 93px)',
                          // position: 'relative',
                        }}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Leave From
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('fromDate', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('fromDate', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Leave To
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('toDate', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('toDate', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Leave Type
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('type', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('type', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Session</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Reason
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('note', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('note', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} align='center'>
                          Status
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('status', 'desc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('status', 'asc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          Created On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listLeaveData && listLeaveData.length > 0 ? (
                        listLeaveData?.map((row: any) => {
                          return (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                marginLeft: '50px',
                                marginRight: '43px',
                                width: 'calc(100% - 93px)',
                              }}>
                              <TableCell
                                sx={{
                                  minWidth: '180px',
                                }}>
                                {row?.fromDate ? moment(row.fromDate).format('DD-MMM-YYYY') : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: '180px',
                                }}>
                                {row?.toDate ? moment(row.toDate).format('DD-MMM-YYYY') : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '180px',
                                }}>
                                {row?.policy?.type?.type ? row?.policy?.type?.type : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                {row?.isFullDayLeave
                                  ? 'Full Day'
                                  : row?.sessionFrom === 1 && row?.sessionTo === 1
                                    ? 'First Half'
                                    : row?.sessionFrom === 2 && row?.sessionTo === 2
                                      ? 'Second Half'
                                      : `${
                                          row?.sessionFrom === 1 ? 'First Half' : 'Second Half'
                                        } - ${row?.sessionTo === 2 ? 'Second Half' : 'First Half'}`}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '180px',
                                }}>
                                {row?.note ? row.note : '-'}
                              </TableCell>

                              {/* <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '180px',
                                }}>
                                {row?.status ? row.status : '-'}
                              </TableCell> */}
                              <TableCell
                                align='center'
                                className='client-status-edit-icon-colum'
                                sx={{ textTransform: 'capitalize' }}>
                                <div className='innercontainer'>
                                  <Typography
                                    className={'leave-status-batch'}
                                    sx={{
                                      width: '135px !important',
                                      fontSize: '10px !important',
                                      color:
                                        row.status === 'pending'
                                          ? '#D9920A'
                                          : row?.status === 'rejected'
                                            ? '#A0290F'
                                            : row.status === 'approved'
                                              ? '#31AA27'
                                              : '#4A5569',
                                      background:
                                        row.status === 'pending'
                                          ? '#FFF0D2'
                                          : row?.status === 'rejected'
                                            ? '#FFE4DE'
                                            : row.status === 'approved'
                                              ? '#EBF6EB'
                                              : '#DEE4EC',
                                    }}>
                                    {row.status === 'pending'
                                      ? 'Awaiting Approval'
                                      : row.status === 'approved'
                                        ? row?.status
                                        : row?.status}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell
                                align='center'
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '180px',
                                }}>
                                {row?.createdAt ? moment(row.createdAt).format('DD-MMM-YYYY') : '-'}
                              </TableCell>

                              <TableCell align='center'>
                                <img
                                  src={ThreedotIcon}
                                  alt='ThreedotIcon'
                                  onClick={(e: any) => handleClick(e, row)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={14} align={'center'}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '43px',
                              }}>
                              <NCAdedIcon />
                              <Typography className='no-client'>No Record(s) found</Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>
          <Modal
            disableAutoFocus
            open={holidayModalOpen}
            onClose={holidayModalClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box sx={style}>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #D9D9D9 !important',
                  paddingBottom: '12px',
                }}>
                <Typography variant={'h5'} component={'h2'}>
                  List of Holidays - {new Date().getFullYear()}
                </Typography>
                <Typography>
                  <CloseIcon className='pointer' onClick={holidayModalClose} />
                </Typography>
              </Typography>
              <Grid
                direction={'row'}
                container
                sm={12}
                spacing={2}
                md={12}
                sx={{
                  msOverflowX: 'auto',
                  marginTop: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '650px',
                  maxWidth: '700px',
                  overflowY: 'auto',
                }}>
                {Array.isArray(listHoliday) && listHoliday.length ? (
                  listHoliday?.map((data: any, i: number) => (
                    <Grid
                      item
                      md={4}
                      sm={12}
                      key={i}
                      sx={{
                        minWidth: '287px !important',
                        marginTop: '10px',
                        opacity: moment().isAfter(data.holidayOn) ? 0.5 : '',
                      }}>
                      <Grid md={12} sm={12} sx={{ display: 'flex' }} container>
                        <Grid md={6} sm={6} item>
                          <Typography sx={{ width: '120px' }}>
                            <Typography
                              sx={{
                                backgroundColor: moment().isAfter(data.holidayOn)
                                  ? '#FDEDE9'
                                  : '#EE4B22',
                                height: '39px',
                                borderRadius: '8px 8px 0px 0px',
                                color: moment().isAfter(data.holidayOn)
                                  ? '#EE4B22'
                                  : ' #FFFFFF !important',
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '14px !important',
                                fontWeight: 500,
                                alignItems: 'center',
                              }}>
                              {moment(data.holidayOn).format('MMMM')}
                            </Typography>
                            <Typography
                              sx={{
                                height: '78px',
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: '500 !important',
                                color: '#344051 !important',
                                fontSize: '30px !important',
                                border: '1px solid #E4E7EC !important',
                                borderRadius: '0px 0px 8px 8px',
                                alignItems: 'center',
                              }}>
                              {moment(data.holidayOn).format('DD').toString().padStart(2, '0')}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid
                          md={6}
                          sm={6}
                          sx={{
                            display: 'flex !important',
                            alignItems: 'center !important',
                            paddingLeft: '10px !important',
                            // justifyContent: 'center !important',
                          }}>
                          <Typography>
                            <Typography
                              sx={{
                                fontSize: '14px !important',
                                fontWeight: '500 !important',
                                color: '#344051 !important',
                                display: 'flex',
                                alignItems: 'end',
                              }}>
                              {data.name}
                            </Typography>
                            <Typography
                              sx={{
                                color: '#637083',
                                fontSize: '12px',
                                display: 'flex',
                                alignItems: 'start',
                              }}>
                              {moment(data.holidayOn).format('dddd')}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    sx={{
                      height: '400px !important',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <h1
                      style={{
                        fontWeight: 500,
                      }}>
                      No Leaves Found
                    </h1>
                  </Typography>
                )}
              </Grid>
            </Box>
          </Modal>
          <Popover
            className='leave-profile-drop'
            id={paid}
            anchorEl={panchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <Typography
              className='leave-menu-item'
              onClick={() => (toggleDrawer(true), handleClose())}>
              <img src={View} alt='view-leave' />
              View Leave
            </Typography>
          </Popover>

          <ViewDrawer
            toggleDrawer={toggleDrawer}
            drawerOpen={drawerOpen}
            data={leaveData}
            viewType={'view'}
            modalOpen={modalOpenFn}
            setTitle={setTitle}
          />

          <ApplyLeave
            toggleDrawer={toggleApplyDrawer}
            drawerOpen={applyDrawerOpen}
            getLeaveData={getLeaveData}
            getLeavePolicyData={getLeavePolicyData}
          />
          <LeaveFilterDrawer
            setParams={setParams}
            isOpen={dweropen}
            closeDrawer={dwerclose}
            callback={handleFilterPag}
            setBillingData={setListLeaveData}
            setLoading={setLoader}
            setpagetotal={setPageTotal}
            listdataapi={getLeaveData}
          />
          <LeaveModal
            toggleDrawer={toggleDrawer}
            open={modalOpen}
            modalOpen={modalOpenFn}
            title={title}
            rowData={rowData}
            setRowData={setRowData}
            getLeaveData={getLeaveData}
            getLeavePolicyData={getLeavePolicyData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ViewLeaveList;
