// import { Typography } from '@mui/material';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  LabelList,
} from 'recharts';

// Custom label for each bar
// const CustomLabel = (props: any) => {
//   const { x, y, value, name, width } = props;

//   return (
//     <text
//       x={x + width / 2}
//       y={y - 5}
//       dy={-4}
//       textAnchor='middle'
//       fill={'#000'}
//       fontFamily='Arial'
//       fontWeight='bold'>
//       {`${value} - ${name}`}
//     </text>
//   );
// };

const renderCustomizedLabel = ({ x, y, width, height, value }: any) => {
  if (value) {
    return (
      <g>
        <text
          x={x + width / 2} // Horizontally center inside the bar
          y={y + height / 2} // Vertically center inside the bar
          fill='#fff' // Black text for contrast
          textAnchor='middle' // Align text horizontally in the center
          fontSize='13px' // Adjust font size for better visibility
          dominantBaseline='middle'>
          {' '}
          {/* Ensures text is vertically aligned */}
          {`${value}`} {/* Display value and name horizontally */}
        </text>
      </g>
    );
  }
  return null;
};

// Custom bar component
const CustomBar = (props: any) => {
  const { x, y, width, height, color } = props;
  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill={color} />
    </g>
  );
};

function HorizontalBarChart({ DailyActivityCardData }: any) {
  const minHeight = 300; // Set a minimum height
  const chartHeight = Math.max(minHeight, 57 * DailyActivityCardData.length);

  return (
    <ResponsiveContainer width='100%' height={chartHeight} maxHeight={320}>
      <BarChart data={DailyActivityCardData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis type='category' dataKey='name' axisLine={true} tick={false} />
        <YAxis
          type='number'
          axisLine={true}
          tick={{ fill: '#272727' }}
          domain={[0, 'dataMax']}
          allowDecimals={false}
        />
        <Tooltip />
        <Bar
          dataKey='value'
          fill='#B37FEB'
          barSize={Math.max(20, 26)} // Ensure bars are not too small
          shape={<CustomBar />}>
          <LabelList content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default HorizontalBarChart;
