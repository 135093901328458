/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Popover,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Loader from 'components/loader';
import React, { useEffect, useRef, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { To, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { EVENTS_ENDPOINT } from 'utils/Constant';
import moment from 'moment';
import CloseIcon from 'assets/png/close.svg';
import _ from 'lodash';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const MyEvents = () => {
  const {
    menuList,
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [clientModule, setClientModule] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([]);
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const [eventModalOpen, setEventModalOpen] = React.useState<any>({
    open: false,
    end: '',
    color: '',
    id: '',
    start: '',
    title: '',
    description: '',
    location: '',
  });

  const handleModalClose = () => {
    setEventModalOpen((prev: any) => ({
      ...prev,
      open: false,
      end: '',
      color: '',
      id: '',
      start: '',
      title: '',
      description: '',
      location: '',
    }));
  };

  const handleModalOpen = (data: any) => {
    setEventModalOpen((prev: any) => ({
      ...prev,
      open: true,
      end: data.event.end,
      color: data.event.borderColor,
      id: data.event.id,
      start: data.event.start,
      title: data.event.textColor,
      description: data.event.constraint,
      location: data.event.groupId,
    }));
  };
  const getList = () => {
    setLoading(true);

    getAllListData(`${EVENTS_ENDPOINT.event}?organizationId=${orgdy}&status=true`, true)
      .then((list: any) => {
        setEvents(
          list.data && Array.isArray(list.data)
            ? list.data.map((e: any) => ({
                end: new Date(e.endsAt),
                backgroundColor: e.colour,
                borderColor: e.colour,
                color: e.colour,
                id: e.id,
                classNames: `C${e.colour.slice(1)}`,
                start: new Date(e.startsAt),
                title: `${moment(new Date(e.startsAt)).format('hh:mm A')},   ${e.title}`,
                textColor: e.title,
                constraint: e.description,
                groupId: e.location,
              }))
            : [],
        );

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'My Events');
      setClientModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);

  return (
    <>
      <Grid container>
        {loading && <Loader />}
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Events
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>Events</Typography>
            <Typography
              component={'div'}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <>
                <Typography
                  component={'div'}
                  className='add-btn-apruv'
                  onClick={() => gotoNavigate(AdminPrivateRoutes.MyEvents.EventsView)}>
                  <Typography component={'div'}>View All Events</Typography>{' '}
                </Typography>
                <div style={{ width: '16px' }}></div>
                {R.findIndex(R.propEq('Add Event', 'screenName'))(
                  clientModule ? clientModule : [],
                ) !== -1 && (
                  <Typography
                    component={'div'}
                    className='client-add-btn'
                    onClick={() => gotoNavigate(AdminPrivateRoutes.MyEvents.EventsAdd)}>
                    <Typography>Add Events</Typography>
                  </Typography>
                )}
              </>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <div className='calendar'>
                <div className='calendar-sidebar'>
                  <FullCalendar
                    ref={calendarRef}
                    headerToolbar={{
                      center: 'prev,title,next',
                      left: 'today',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay',
                    }}
                    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    initialView='dayGridMonth'
                    weekends={true}
                    editable={true}
                    selectable={true}
                    themeSystem='bootstrap'
                    events={events}
                    displayEventTime={false}
                    eventTimeFormat={{
                      hour: 'numeric',
                      meridiem: false,
                    }}
                    expandRows={true}
                    eventClick={(e) => handleModalOpen(e)}
                    allDayText='All-day'
                  />
                </div>
              </div>
            </Typography>
          </Typography>
        </Grid>
        <Modal open={eventModalOpen.open} onClose={handleModalClose}>
          <Box className='email-modal' sx={{ width: '865px !important', minHeight: '45%' }}>
            <Typography component={'div'} className='d-flex-a flex-sb'>
              <Typography className='l-title' sx={{ display: 'flex' }}>
                <div
                  style={{
                    height: '15px',
                    width: '15px',
                    backgroundColor: eventModalOpen.color,
                    borderRadius: '10px',
                    marginRight: '10px',
                  }}
                />
                {'Event Details'}
              </Typography>
              <Typography className='text-right'>
                <img
                  src={CloseIcon}
                  alt='close'
                  onClick={handleModalClose}
                  style={{ cursor: 'pointer' }}
                />
              </Typography>
            </Typography>
            <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
              <TableContainer component={Paper}>
                <Table className='custom-table' aria-label='simple table'>
                  <TableBody>
                    {eventModalOpen && (
                      <>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            align='left'
                            sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                            Event
                          </TableCell>
                          <TableCell align='center'>
                            {eventModalOpen && eventModalOpen?.title
                              ? `${eventModalOpen?.title.slice(0, 1).toUpperCase()}` +
                                eventModalOpen?.title.slice(1)
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            align='left'
                            sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                            Start Time
                          </TableCell>
                          <TableCell align='center'>
                            {eventModalOpen && eventModalOpen?.start
                              ? moment(new Date(eventModalOpen?.start)).format('YYYY-MM-DD hh:mm A')
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            align='left'
                            sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                            End Time
                          </TableCell>
                          <TableCell align='center'>
                            {eventModalOpen && eventModalOpen?.end
                              ? moment(new Date(eventModalOpen?.end)).format('YYYY-MM-DD hh:mm A')
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            align='left'
                            sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                            Description
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              maxWidth: '350px',
                              lineHeight: '1.5 !important',
                            }}>
                            {eventModalOpen && eventModalOpen?.description
                              ? `${eventModalOpen?.description.slice(0, 1).toUpperCase()}` +
                                eventModalOpen?.description.slice(1)
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            align='left'
                            sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                            Location
                          </TableCell>
                          <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                            {eventModalOpen && eventModalOpen?.location
                              ? eventModalOpen?.location
                              : '-'}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};
export default MyEvents;
