import React, { useEffect } from 'react';
import AppRoutes from './routes';
import './style/style.scss';
import { useLocation,  } from 'react-router-dom';
import { addNewData } from 'services/CommonServices';
import {  useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import axios from 'axios';
import Storage from 'utils/Storage';

function PreApp() {
  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);
  const location = useLocation();

  async function perpagepost(uri: string) {
    if (uri && userInfo && userInfo.id && userInfo.refreshTokenId && !uri.includes('login')) {
      try {
        addNewData(
          {
            pageUrl: uri,
            userId: userInfo.id,
            tokenId: userInfo.refreshTokenId,
          },
          'activity',
          '',
          true,
        );
      } catch (err) {
        toast.error(String(err), alertOptions);
      }
    }
  }
  const getIpData = async () => {
    await axios
      .get('https://api.ipify.org?format=json', { timeout: 5000 })
      .then(async (res: any) => {
        if (Storage.getItem(Storage.KEYS.IP_ADDRESS)) {
          Storage.removeItem(Storage.KEYS.IP_ADDRESS);
        }
        Storage.setItem(Storage.KEYS.IP_ADDRESS, res.data.ip);
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });
  };

  const systemLocation = async () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`,
          );
          if (Storage.getItem(Storage.KEYS.LOCATION)) {
            Storage.removeItem(Storage.KEYS.LOCATION);
          }
          Storage.setItem(Storage.KEYS.LOCATION, response?.data?.address?.country);
        } catch (error) {
          console.error('Error fetching address:', error);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  useEffect((): any => {
    getIpData();
    systemLocation();
    return () => perpagepost(String(window.location));
  }, [window.location, window.location.pathname, location.pathname]);


  return <AppRoutes />;
}

export default PreApp;
