import { Grid, Box, Badge, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import './index.scss';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import { CategoryCount } from './CategoryCount';
import { CalculationTable } from './CalculationTable';
import { CalenderEvents } from './CalenderEvents';
import { DailyActivity } from './DailyActivity';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { AccessDenied } from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import { ResumeData } from './ResumeData';
import { InterviewData } from './InterviewData';
import Joinee from './Joinee';
import { RecentClients } from './RecentClients';
import { RecentPosition } from './RecentPosition';
import FinacialData from './FinacialData';
import OffersData from './OffersData';
import { Productivity } from './Productivity';
import { BusinessConversion } from './BusinessConversion';
import _ from 'lodash';
// import * as R from 'ramda';

const Dashboard = () => {
  const { menuList }: any = useAppSelector((store: any) => store.userLogin);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [positionModule, setpositionModule] = useState([]);
  // const [option, setoption] = useState<any>(
  //   R.findIndex(R.propEq('Add Position', 'screenName'))(positionModule ? positionModule : []) !== -1
  //     ? 'CRM'
  //     : 'Recruiter',
  // );
  const Today = new Date();
  const userDetail: any = useAppSelector((store: any) => store.userLogin.userInfo);
  const [option, setoption] = useState<any>(![2].includes(userDetail.roleId) ? 'CRM' : 'Recruiter');
  const [Ld, setLd] = useState(false);
  const [visible, setVisible] = useState(true);
  const [resumeData, setResumeData] = useState<any>([]);
  const [interviewData, setInterviewData] = useState<any>([]);
  const [joineesData, setJoineesData] = useState<any>([]);
  const [dweropen, setdweropen] = useState(false);
  const navigate = useNavigate();
  const WeeklyActivityData = () => {
    setLd(true);
    const oneWeekBefore = new Date(Today);
    oneWeekBefore.setDate(Today.getDate() - 7);
    getAllListData(
      `${DASHBOARD_ENDPOINT.Category}?type=${option.toLowerCase()}&fromDate=${moment(
        oneWeekBefore,
      ).format('YYYY-MM-DD')}&toDate=${moment(Today).format('YYYY-MM-DD')}`,
      true,
    )
      .then((val: any) => {
        const data = [
          {
            name: 'CV Sent',
            color: '#3B82F6',
            value: Number(val.data.cvSent),
          },
          {
            name: 'CV Shortlisted',
            color: '#14B8A6',
            value: Number(val.data.cvShortlisted),
          },
          {
            name: 'CV Rejected',
            color: '#F59E0B',
            value: Number(val.data.cvRejected),
          },
          {
            name: 'CV Feedback Pending',
            color: '#FACC15',
            value: Number(val.data.feedbackAwaited),
          },
        ];
        const interviewData = [
          {
            name: 'Interview Scheduled',
            color: '#14B8A6',
            value: Number(val.data.candidateInterviewScheduled),
          },
          {
            name: 'Interview Selected',
            color: '#F59E0B',
            value: Number(val.data.candidateInterviewSelected),
          },
          {
            name: 'Interview Rejected',
            color: '#3B82F6',
            value: Number(val.data.candidateInterviewRejected),
          },
          {
            name: 'Interview Rescheduled',
            color: '#A855F7',
            value: Number(val.data.interviewReScheduled),
          },
        ];
        const joineesData = [
          { name: 'Joined', color: '#00C49F', value: Number(val.data.joined) },
          { name: 'Not Joined', color: '#0088FE', value: Number(val.data.notJoined) },
          { name: 'Deferred', color: '#FFBB28', value: Number(val.data.joiningDeferred) },
        ];
        const filteredResume = data.filter((item) => item.value > 0);
        const filteredInterview = interviewData.filter((item) => item.value > 0);
        const filteredJoinees = joineesData.filter((item) => item.value > 0);
        setResumeData(filteredResume);
        setInterviewData(filteredInterview);
        setJoineesData(filteredJoinees);
        setLd(false);
      })
      .catch((err) => {
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(false);
      });
  };

  React.useEffect(() => {
    WeeklyActivityData();
  }, [option]);

  function HandleOptions(opt: string) {
    setoption(opt);
  }
  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'Position');
      setpositionModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);
  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Box className='commonFlex headercon'>
            <Box className='commonFlex welcome-text'>
              <div className='heading'>
                Welcome {`${userDetail.firstName} ${userDetail.lastName}!`}
              </div>
              <div className='description'>An aggregated view of all your data.</div>
            </Box>
            <Box className='commonFlex right-container'>
              <Typography
                component={'div'}
                className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                onClick={() => setdweropen(!dweropen)}>
                <Badge color='error' variant='dot' invisible={visible}>
                  <img src={Funnelimg} alt='filter funnel icon' />
                </Badge>
              </Typography>
              {/* <div
                className='filter-icon'
                style={{ height: '44px' }}
                onClick={() => setdweropen(!dweropen)}>
                <Badge color='error' variant='dot' invisible={visible}>
                  <FilterSvgIcon />
                </Badge>
              </div> */}
              <div className='commonFlex slider'>
                {/* {R.findIndex(R.propEq('Add Position', 'screenName'))(
                  positionModule ? positionModule : [],
                ) !== -1 ? (
                  <div
                    className={`options ${option && option === 'CRM' ? 'Activoptions' : ''}`}
                    onClick={() => HandleOptions('CRM')}>
                    CRM
                  </div>
                ) : (
                  ''
                )} */}
                {![2].includes(userDetail.roleId) && (
                  <div
                    className={`options ${option && option === 'CRM' ? 'Activoptions' : ''}`}
                    onClick={() => HandleOptions('CRM')}>
                    CRM
                  </div>
                )}
                <div
                  className={`options ${option && option === 'Recruiter' ? 'Activoptions' : ''}`}
                  onClick={() => HandleOptions('Recruiter')}>
                  Recruiter
                </div>
              </div>
            </Box>
          </Box>

          <CategoryCount
            setLd={setLd}
            option={option}
            setdweropen={setdweropen}
            dweropen={dweropen}
            setVisible={setVisible}
          />
          <CalculationTable setLd={setLd} option={option} />
          {option === 'Recruiter' ? (
            <div className='productivitygraph'>
              <Grid container spacing={2}>
                <Productivity setLd={setLd} option={option} />
                <BusinessConversion setLd={setLd} option={option} />
              </Grid>
            </div>
          ) : (
            ''
          )}
          <div className='dailyactivitycon'>
            <Grid container>
              <Grid item md={8} sm={12} xs={12}>
                <div className='leftcon'>
                  <DailyActivity setLd={setLd} option={option} />
                  <div className='borderdivider'></div>
                  <ResumeData resumeData={resumeData} />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className='rightcon'>
                  <CalenderEvents setLd={setLd} />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='Interviewcon'>
            <Grid container>
              <Grid item md={4} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='leftcon'>
                  <InterviewData resumeData={interviewData} />
                </div>{' '}
              </Grid>
              <Grid item md={8} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='rightcon'>
                  <OffersData setLd={setLd} option={option} />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='joineecon'>
            <Grid container>
              <Grid item md={4} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='leftcon'>
                  <Joinee resumeData={joineesData} />
                </div>{' '}
              </Grid>
              <Grid item md={8} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='rightcon'>
                  <RecentClients setLd={setLd} option={option} />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='Postioncon'>
            <Grid container>
              <Grid item md={8} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='leftcon'>
                  <RecentPosition setLd={setLd} option={option} />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12} sx={{ maxHeight: '600px' }}>
                <div className='rightcon' style={{ height: '700px' }}>
                  <FinacialData setLd={setLd} option={option} />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {Ld && <Loader />}
    </>
  );
};

export default Dashboard;
