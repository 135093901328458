import './index.scss';
const TagsAnnotator = ({ txt, bg, value }: { txt: string; bg?: string; value?: any }) => {
  return (
    <>
      <div className='tagcon'>
        <div className='circ' style={{ backgroundColor: bg ? bg : '#3B82F6' }}></div>
        <div className='txt'>
          {txt}
          {'   '}
          {`[${value ? value : 0}]`}
        </div>
      </div>
    </>
  );
};

export default TagsAnnotator;
