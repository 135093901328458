import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EditIcon from 'assets/png/edit.svg';
import PreIcon from 'assets/png/pre.svg';
// import EditIcon from 'assets/png/edit.svg';
// import DeleteIcon from 'assets/png/delete.svg';
import Dummyimgn from 'assets/png/cticon.png';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { To, useNavigate } from 'react-router-dom';
import { deleteData, getAllListData } from 'services/CommonServices';
import { useEffect, useRef, useState } from 'react';
import { alertOptions } from 'utils/Config';
import { toast } from 'react-toastify';
import { useDebounce } from 'hooks';
import { SETTING_ENDPOINT, ClientsENDPOINT } from 'utils/Constant';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Loader from 'components/loader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import Paginate from 'components/Pagination/Pagination';
import { AdminPrivateRoutes } from 'routes/routeConstans';
// import PdfIcon from 'assets/iconcomponents/pdficon';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import Modalclient from './Modal';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import _ from 'lodash';
import moment from 'moment';
import ImageWithAuth from 'components/ProfileImage';
import { addObject } from 'store/reducers/clientreducer';
import Modalclientinactive from './clientinactivemodal/indewx';
import * as R from 'ramda';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { storeclient } from 'store/reducers/clientcorporatereducer';
import { ClientFilterDrawer } from './ClientFilter/ClientFilterDrawer';
import { AccessDenied } from 'utils/helper';
import { Country } from 'country-state-city';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  // boxShadow:
  //   theme.palette.mode === 'dark'
  //     ? '0 0 0 1px rgb(16 22 26 / 40%)'
  //     : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  // backgroundImage:
  //   theme.palette.mode === 'dark'
  //     ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //     : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '1px auto #D0D5DD',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#0061FF',
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#0061FF',
  },
});

function createData(
  name?: string,
  calories?: number,
  fat?: number,
  carbs?: number,
  protein?: number,
) {
  return { name, calories, fat, carbs, protein };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rows = [
  createData('Frozen yoghurt'),
  createData('Ice cream sandwich'),
  createData('Eclair'),
  createData('Cupcake'),
  createData('Gingerbread'),
];

const Clients = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    menuList,
    userInfo: { id: userId, organizationId },
  }: any = useAppSelector((store: any) => store.userLogin);

  const dispatch = useAppDispatch();
  // const [orgdy,setorgdy] = useState('');
  const navigate = useNavigate();

  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [approvetotal, setapprovetotal] = useState<number>(0);
  const Defaultparam = {
    search: null,
    userId: null,
    order: null,
    skip: 0,
    sortby: null,
    status: null,
    take: 50,
    from: null,
    to: null,
    crm: null,
    organizationId: null,
    approvalStatus: null,
    industry: null,
    location: null,
  };
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const [Ld, setLd] = useState(false);
  const [clientModule, setClientModule] = useState([]);
  const debouncedParams = useDebounce(params, 500);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const gotoNavigate = (link: To, state?: any) => {
    navigate(link, state);
  };

  const listdataapi = () => {
    const searcharr = [
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.userId ? `userId=${debouncedParams.search}` : null}`,
      `${
        debouncedParams.approvalStatus ? `approvalStatus=${debouncedParams.approvalStatus}` : null
      }`,
      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
      `${debouncedParams.crm ? `crm=${debouncedParams.crm}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${debouncedParams.industry ? `industryId=${debouncedParams.industry}` : null}`,
      `${debouncedParams.location ? `location=${debouncedParams.location}` : null}`,
      `organizationId=${organizationId}`,
      `${
        debouncedParams.status
          ? `status=${
              debouncedParams.status === 'active'
                ? true
                : debouncedParams.status === 'inActive'
                  ? false
                  : null
            }`
          : null
      }`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
      'screenType=view',
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);
    // getAllListData(
    //   `${ClientsENDPOINT.Client}${
    //     Object.values({ ...params }).length > 0 && '?'
    //   }${searcharr}`,
    //   true,
    // )
    getAllListData(
      `${ClientsENDPOINT.Client}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        setcommonstate([...val.data]);
        setpagetotal(Number(val.total));
        setapprovetotal(Number(val.totalInReview));
        setLd(!true);
      })
      .catch((err) => {
        setLd(!true);
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };

  useEffect(() => {
    listdataapi();
    // setcommonstate([]);
  }, [debouncedParams]);

  const deletebtn = async (id: any) => {
    setLd(true);
    try {
      await deleteData(id, `${SETTING_ENDPOINT.functionalArea}`, true);
      // toast.success(deleted.message, alertOptions);
      setConfirmOpen({
        show: false,
        id: '',
      });
      listdataapi();
    } catch (err) {
      toast.error(String(err), alertOptions);
    } finally {
      setLd(!true);
    }
  };

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  }
  // function handleFilterPag(filteredobj1: any) {
  //   setParams((prev: any) => ({
  //     ...prev,
  //     ...filteredobj1,
  //   }));
  // }

  const localstorageclear: any = () => {
    localStorage.setItem('clientcrm', JSON.stringify([]));
    localStorage.setItem('clientcrm2', JSON.stringify([]));
    localStorage.setItem('clientdetail', JSON.stringify({}));
    localStorage.setItem('clientspoc', JSON.stringify([]));

    localStorage.setItem('refreshstate', JSON.stringify({}));
  };

  const [inactiveopen, setinactiveopen] = useState(false);
  const [mdopen, setmdopen] = useState(false);
  const [clientData, setclientData] = useState({});
  const [inactiveclidata, setinactiveclidata] = useState({ name: '', id: '' });
  // const handlemdopen = () => setmdopen(true);
  const handlemdclose = () => setmdopen(false);

  const handleinactiveopen = () => setinactiveopen(true);
  const handleinactiveclose = () => setinactiveopen(false);

  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'Client');
      setClientModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              {/* <Link underline='hover' color='inherit' href=''>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Setting
              </Link> */}
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Clients
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>Clients</Typography>
            <Typography
              component={'div'}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className='expot-icon'>
                <span>
                  <ExcelIcon
                    url={`${ClientsENDPOINT.Client}${
                      Object.values({ ...params }).length > 0 && '?'
                    }${[
                      `${
                        debouncedParams.search
                          ? `search=${encodeURIComponent(debouncedParams.search)}`
                          : null
                      }`,
                      `${debouncedParams.userId ? `userId=${debouncedParams.userId}` : null}`,
                      `${
                        debouncedParams.approvalStatus
                          ? `approvalStatus=${debouncedParams.approvalStatus}`
                          : null
                      }`,
                      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
                      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
                      `${debouncedParams.crm ? `crm=${debouncedParams.crm}` : null}`,
                      'order=desc',
                      'sortby=createdAt',
                      `${
                        debouncedParams.industry ? `industryId=${debouncedParams.industry}` : null
                      }`,
                      `${debouncedParams.location ? `location=${debouncedParams.location}` : null}`,
                      `organizationId=${organizationId}`,
                      `${
                        debouncedParams.status
                          ? `status=${
                              debouncedParams.status === 'active'
                                ? true
                                : debouncedParams.status === 'inActive'
                                  ? false
                                  : null
                            }`
                          : null
                      }`,
                      'type=export',
                      'screenType=view',
                    ]
                      .filter((ea: any) => ea !== 'null')
                      .join('&')}`}
                    fileName={'clients'}
                    setLoader={setLd}
                  />
                </span>
                <span style={{ width: '12.17px' }}></span>
                <span>{/* <PdfIcon /> */}</span>
              </div>
              {R.findIndex(R.propEq('Approve Client', 'screenName'))(
                clientModule ? clientModule : [],
              ) !== -1 && (
                <Typography
                  component={'div'}
                  className='add-btn-apruv'
                  onClick={() => {
                    if (approvetotal > 0) {
                      gotoNavigate(AdminPrivateRoutes.CLIENTS.CLIENTAPPROVE);
                    }
                  }}>
                  <Typography component={'div'}>Approve Client</Typography>{' '}
                  <Typography className='apruv-cunt'>{approvetotal}</Typography>
                </Typography>
              )}

              <div style={{ width: '16px' }}></div>
              {R.findIndex(R.propEq('Add Client', 'screenName'))(
                clientModule ? clientModule : [],
              ) !== -1 && (
                <Typography
                  component={'div'}
                  className='client-add-btn'
                  onClick={() => gotoNavigate(AdminPrivateRoutes.CLIENTS.CLIENTADD)}>
                  <img src={AddIcon} alt='plus' />
                  <Typography onClick={localstorageclear}>Add Client</Typography>
                </Typography>
              )}
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <Typography component={'div'} className='d-flex-cc flex-sb'>
                <TextField
                  className='custom-search'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '320px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 50,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIcon style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Typography
                  component={'div'}
                  className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                  onClick={dweropn}>
                  <img src={Funnelimg} alt='filter funnel icon' />
                  <span className='filterfunnel'>More Filters</span>
                </Typography>
              </Typography>
              <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    className='custom-table custom-table-client'
                    aria-label='sticky Header'>
                    <TableHead>
                      <TableRow
                        sx={{
                          marginLeft: '50px',
                          marginRight: '43px',
                          // width: 'calc(100% - 93px)',
                        }}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Name
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('name', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('name', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Industry
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('industry', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('industry', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Country
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('country', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('country', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          City
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('city', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('city', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          CRM
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('crm', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('crm', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Created By
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdBy', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdBy', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Created On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Modified On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('updatedAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('updatedAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Approval Status
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('updatedAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('updatedAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Positions
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('positionCount', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('positionCount', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Opening
                          {/* <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('Opening', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('Opening', 'desc')}
                            />
                          </span> */}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                          Status
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('status', 'desc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('status', 'asc')}
                            />
                          </span>
                        </TableCell>

                        {R.findIndex(R.propEq('Add Client', 'screenName'))(
                          clientModule ? clientModule : [],
                        ) !== -1 && (
                          <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                            Action
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commonstate && commonstate.length > 0 ? (
                        commonstate.map((row) => {
                          const clientCountry = Country.getAllCountries().filter(
                            (country) => country.isoCode === row.country,
                          );
                          return (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                // verticalAlign: 'baseline',
                                marginLeft: '50px',
                                marginRight: '43px',
                                width: 'calc(100% - 93px)',
                              }}>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  whiteSpace: 'nowrap',
                                  minWidth: '100px',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    // justifyContent:'center',
                                    alignItems: 'center',
                                    gap: '10px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    localStorage.setItem(
                                      'crmlist',
                                      JSON.stringify(
                                        row.clientsCrm.map((p: any) =>
                                          p && p.userId ? p.userId : '',
                                        ),
                                      ),
                                    );
                                    localStorage.setItem(
                                      'crmlistid',
                                      JSON.stringify(row.clientsCrm ? row.clientsCrm : ''),
                                    );
                                    dispatch(addObject(row));
                                    row && !row.status
                                      ? setinactiveclidata({
                                          name: row && row.corporateName ? row.corporateName : '',
                                          id: row && row.id ? row.id : '',
                                        })
                                      : setclientData(row);
                                    row && !row.status
                                      ? handleinactiveopen()
                                      : gotoNavigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEWDETAILS, {
                                          state: { id: row.id },
                                        });
                                  }}>
                                  <span style={{ width: '30px', height: '30px' }}>
                                    {row && row.logoId ? (
                                      <ImageWithAuth
                                        styled={{
                                          width: '100%',
                                          height: '100%',
                                          borderRadius: '100%',
                                          objectFit: 'cover',
                                          transform: 'translateY(-4px)',
                                          // objectPosition: 'center',
                                        }}
                                        id={row.logoId}
                                      />
                                    ) : (
                                      <img
                                        src={Dummyimgn}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          borderRadius: '100%',
                                          objectFit: 'cover',
                                          transform: 'translateY(-4px)',
                                          // objectPosition: 'center',
                                        }}
                                      />
                                    )}
                                  </span>
                                  <span style={{ color: '#5F2EE5', cursor: 'pointer' }}>
                                    {row && row.corporateName ? row.corporateName : ' '}
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '120px' }}>
                                {row?.industry?.name?.length > 9 ? (
                                  <TableTooltip
                                    value={row && row?.industry?.name ? row?.industry?.name : '-'}
                                  />
                                ) : (
                                  row?.industry?.name
                                )}
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                                {row && row.country ? clientCountry[0]?.name : '-'}
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                                {row && row.city ? row.city : ' '}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  maxWidth: '200px',
                                  minWidth: '100px',
                                  hypens: 'auto',
                                  cursor: 'pointer',
                                }}>
                                <TableTooltip
                                  value={
                                    row &&
                                    row.clientsCrm &&
                                    !_.isEmpty(row.clientsCrm) &&
                                    _.isArray(row.clientsCrm)
                                      ? row.clientsCrm
                                          .map((eh1: any) => {
                                            if (
                                              eh1 &&
                                              !_.isEmpty(eh1) &&
                                              eh1.user &&
                                              !_.isEmpty(eh1.user) &&
                                              eh1.user &&
                                              eh1.user.firstName &&
                                              eh1.user.lastName
                                            ) {
                                              return `${eh1.user.firstName} ${eh1.user.lastName}`;
                                            }
                                            return '';
                                          })
                                          .filter((k1: any) => JSON.stringify(k1).length > 0)
                                          .join(', ')
                                      : ' '
                                  }>
                                  {' '}
                                </TableTooltip>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                <TableTooltip
                                  value={
                                    row &&
                                    row.createdBy &&
                                    !_.isEmpty(row.createdBy) &&
                                    row.createdBy.firstName &&
                                    row.createdBy.lastName
                                      ? `${row.createdBy.firstName} ${row.createdBy.lastName}`
                                      : '-'
                                  }></TableTooltip>
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '150px' }}>
                                {row && row.createdAt
                                  ? moment(row.createdAt).format('DD-MMM-YYYY')
                                  : ' '}
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '150px' }}>
                                {row && row.updatedAt
                                  ? moment(row.updatedAt).format('DD-MMM-YYYY')
                                  : '-'}
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                                <Typography
                                  component={'button'}
                                  className='client-status-btn'
                                  sx={{
                                    backgroundColor:
                                      row.approvalStatus === 'approved'
                                        ? '#70a415'
                                        : row.approvalStatus === 'inDraft'
                                          ? '#f455ef'
                                          : '#f5aa1a',
                                    width: '100% !important',
                                  }}>
                                  <Typography
                                    component={'span'}
                                    sx={{ textTransform: 'capitalize' }}>
                                    {row && row.approvalStatus && row.approvalStatus === 'approved'
                                      ? 'Approved'
                                      : row.approvalStatus === 'inDraft'
                                        ? 'In-Complete'
                                        : 'Awaiting Approval'}
                                  </Typography>
                                </Typography>
                              </TableCell>
                              <TableCell
                                align='center'
                                sx={{ textTransform: 'capitalize', minWidth: '150px' }}>
                                {/* {row && row.description ? row.description.slice(0, 6) : ' '} */}
                                {row?.positionCount ? row?.positionCount : '-'}
                              </TableCell>
                              <TableCell
                                align='center'
                                sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                                <div
                                  style={{ cursor: row?.openings > 0 ? 'pointer' : '' }}
                                  onClick={() => {
                                    if (row?.openings > 0) {
                                      gotoNavigate(`${AdminPrivateRoutes.POSITIONS.POSITIONSVIEW}`);
                                    }
                                  }}>
                                  <span style={{ color: '#5F2EE5', cursor: 'pointer' }}>
                                    {row?.openings ? row?.openings : '-'}
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell className='client-status-edit-icon-colum'>
                                <div className='innercontainer'>
                                  <Typography
                                    className={
                                      row && row.status ? 'active-badge' : 'inactive-badge'
                                    }>
                                    {row && row.status ? 'Active' : 'Inactive'}
                                  </Typography>
                                </div>
                              </TableCell>

                              {R.findIndex(R.propEq('Add Client', 'screenName'))(
                                clientModule ? clientModule : [],
                              ) !== -1 ? (
                                row &&
                                row.createdBy &&
                                !_.isEmpty(row.createdBy) &&
                                row.createdBy.id &&
                                row.createdBy.id === userId ? (
                                  <TableCell
                                    sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                                    <span style={{ marginLeft: '16px' }}>
                                      <img
                                        src={EditIcon}
                                        alt='Edit'
                                        className='pointer'
                                        // onClick={() =>
                                        //   navigate(
                                        //     `${AdminPrivateRoutes.SETTINGS.QUALIFICATION.QUALIFICATIONEDIT}/${row.id}`,
                                        //   )
                                        // }
                                        onClick={() => {
                                          localStorage.setItem(
                                            'crmlist',
                                            JSON.stringify(
                                              row.clientsCrm.map((p: any) =>
                                                p && p.userId ? p.userId : '',
                                              ),
                                            ),
                                          );
                                          localStorage.setItem(
                                            'crmlistid',
                                            JSON.stringify(row.clientsCrm ? row.clientsCrm : ''),
                                          );
                                          dispatch(addObject(row));
                                          dispatch(storeclient(row));
                                          // console.log(clientreducer,'clientreducer');
                                          gotoNavigate(
                                            `${AdminPrivateRoutes.CLIENTS.CLIENTADD}?mode=edit&clientid=${row.id}`,
                                          );
                                        }}
                                      />
                                    </span>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                                    <span style={{ marginLeft: '16px' }}>
                                      <img
                                        src={EditIcon}
                                        alt='Edit'
                                        // className='pointer'
                                        style={{ filter: 'saturate(10%)', opacity: '50%' }}
                                      />
                                    </span>
                                  </TableCell>
                                )
                              ) : (
                                <></>
                              )}
                            </TableRow>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} align={'center'}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '43px',
                              }}>
                              <NCAdedIcon />
                              <Typography className='no-client'>No Client added</Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>
        </Grid>
        <Modalclient
          open={mdopen}
          handleClose={handlemdclose}
          Approved={!false}
          clientData={clientData}
        />
        <Modalclientinactive
          open={inactiveopen}
          handleClose={handleinactiveclose}
          clientData={{ name: inactiveclidata.name, id: inactiveclidata.id }}
        />
        <ClientFilterDrawer
          setParams={setParams}
          isOpen={dweropen}
          closeDrawer={dwerclose}
          // callback={handleFilterPag}
          setcommonstate={setcommonstate}
          setLoading={setLd}
          setpagetotal={setpagetotal}
          listdataapi={listdataapi}
        />
        <ConfirmDialog
          title='Confirm'
          floatingBtnShow={true}
          open={confirmOpen.show}
          close={() =>
            setConfirmOpen({
              show: false,
              id: '',
            })
          }
          visibleClose={true}
          onConfirm={(act: any) =>
            act === 'yes'
              ? deletebtn(confirmOpen.id)
              : setConfirmOpen({
                  show: false,
                  id: '',
                })
          }
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default Clients;
