import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { postRequest } from 'utils/Handlers';
import Storage from 'utils/Storage';

import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
} from '../types/UserTypes';
import { PublicRoutes } from 'routes/routeConstans';

export const login = async (email: string, password: string, dispatch: any, navigate: any) => {
  dispatch({
    type: USER_LOGIN_REQUEST,
  });
  const params = {
    data: {
      email,
      password,
    },
    token: '',
    url: MASTER_ENDPOINT.Login,
  };

  return await postRequest(params)
    .then((response: any) => {
      if (response) {
        if (response.isExpired) {
          navigate(PublicRoutes.RESETPASSWORD + `?email=${email}&hash=${response.hash}`, {
            state: { password, isExpired: true, email: email, hash: response.hash },
          });
        } else {
          navigate('/generate-otp', { state: { email } });

          // const { accessToken, refreshToken: refreshToken1 } = response;
          // const userData: any = jwtDecode(String(accessToken));
          // userData['token'] = accessToken;
          // userData['refreshToken'] = refreshToken1;
          // dispatch({
          //   payload: userData,
          //   type: USER_LOGIN_SUCCESS,
          // });
          // if (accessToken && refreshToken(refreshToken1) && userData?.id) {
          //   const tokenData: any = {
          //     refreshToken: refreshToken1,
          //     token: accessToken,
          //     userId: userData.id,
          //   };
          //   Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
          // }
        }
      }
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error.error.message) {
        errorMessage = error.error.message;
      }
      dispatch({
        payload: errorMessage,
        type: USER_LOGIN_FAIL,
      });

      toast.error(errorMessage || 'Invalid username or password' || String(error), alertOptions);
    });
};

export const refreshToken: any = (token: string) => (dispatch: any) => {
  dispatch({
    payload: token,
    type: USER_REFRESH_TOKEN,
  });
};

export const logout = (dispatch: any) => {
  dispatch({
    type: USER_LOGOUT,
  });
  // localStorage.clear();
  // localStorage.removeItem()
  Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
  Storage.removeItem(Storage.KEYS.MENUS);
};
