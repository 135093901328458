/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  Typography,
  Breadcrumbs,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  Box,
  NativeSelect,
  TextField,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CustomSelect from 'components/CustomSelect/customSelect';
import { Controller, useForm } from 'react-hook-form';
import { ctcSlabSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import _, { max, min } from 'lodash';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
// import { toast } from 'react-toastify';
import { updateData, addNewData, getAllListData } from 'services/CommonServices';
// import { alertOptions } from 'utils/Config';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { AdminPrivateRoutes } from 'routes/routeConstans';
// import { toast } from 'react-toastify';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/loader';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Alert from '@mui/material/Alert';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
import moment from 'moment';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { finalCurrency } from 'utils/Config';

const CtcSlabAdd = () => {
  const { editId } = useParams();
  const navigate = useNavigate();
  const currentDate = moment().format('YYYY-MM-DD');
  const [loader, setLoader] = useState<any>(false);
  const [confirmOpen, setConfirmOpen] = useState<any>({ open: false, data: {} });
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      minCtc: '',
      minCtcCurrency: 'inr',
      maxCtc: '',
      maxCtcCurrency: 'inr',
      target: '',
      startDate: '',
      status: 'true',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ctcSlabSchema),
  });

  const getInitialStatus = () => {
    if (editId) {
      getAllListData(`${SETTING_ENDPOINT.CtcSlab}?id=${editId}`, true)
        .then((resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            setValue(
              'minCtc',
              resp.data[0].minCtc
                ? finalCurrency(resp.data[0].minCtc, resp.data[0].minCurrency.toLocaleUpperCase())
                : '',
            );
            setValue(
              'maxCtc',
              resp.data[0].maxCtc
                ? finalCurrency(resp.data[0].maxCtc, resp.data[0].maxCurrency.toLocaleUpperCase())
                : '',
            );
            setValue('minCtcCurrency', resp.data[0].minCurrency);
            setValue('maxCtcCurrency', resp.data[0].maxCurrency);
            setValue('target', resp.data[0].target);
            setValue('startDate', resp.data[0].startDate);
          }
          setLoader(false);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
          // console.log(e);
        });
    }
  };
  const duplicateFunction = (data: any) => {
    addNewData(
      {
        minCurrency: data.minCtcCurrency,
        minCtc: data.minCtc ? parseInt(String(data.minCtc).replace(/,/g, '')) : '',
        maxCurrency: data.maxCtcCurrency,
        maxCtc: data.maxCtc ? parseInt(String(data.maxCtc).replace(/,/g, '')) : '',
        target: Number(data.target),
        startDate: data.startDate,
        isDuplicate: true,
      },
      `${String(SETTING_ENDPOINT.CtcSlab)}`,
      undefined,
      true,
    )
      .then(() => {
        resetForm();
        navigate(`${AdminPrivateRoutes.SETTINGS.CTC.CTCVIEW}`);
        setLoader(false);
      })
      .catch((e) => {
        // console.log(e);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
      });
  };
  const onSubmitForm = (data: any) => {
    setLoader(true);
    if (!_.isUndefined(editId)) {
      updateData(
        editId,
        {
          minCurrency: data.minCtcCurrency,
          minCtc: data.minCtc ? parseInt(String(data.minCtc).replace(/,/g, '')) : '',
          maxCurrency: data.maxCtcCurrency,
          maxCtc: data.maxCtc ? parseInt(String(data.maxCtc).replace(/,/g, '')) : '',
          target: Number(data.target),
          startDate: data.startDate,
          isDuplicate: false,
        },
        `${String(SETTING_ENDPOINT.CtcSlab)}`,
        true,
      )
        .then(() => {
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.CTC.CTCVIEW}`);
          setLoader(false);
        })
        .catch((e: any) => {
          // console.log(e);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    } else {
      addNewData(
        {
          minCurrency: data.minCtcCurrency,
          minCtc: data.minCtc ? parseInt(String(data.minCtc).replace(/,/g, '')) : '',
          maxCurrency: data.maxCtcCurrency,
          maxCtc: data.maxCtc ? parseInt(String(data.maxCtc).replace(/,/g, '')) : '',
        },
        `${String(SETTING_ENDPOINT.CtcSlab)}/duplicate`,
        undefined,
        true,
      )
        .then((res) => {
          if (res.duplicate) {
            setConfirmOpen({ open: true, data: data });
          } else {
            addNewData(
              {
                minCurrency: data.minCtcCurrency,
                minCtc: data.minCtc ? parseInt(String(data.minCtc).replace(/,/g, '')) : '',
                maxCurrency: data.maxCtcCurrency,
                maxCtc: data.maxCtc ? parseInt(String(data.maxCtc).replace(/,/g, '')) : '',
                target: Number(data.target),
                startDate: data.startDate,
                isDuplicate: false,
              },
              `${String(SETTING_ENDPOINT.CtcSlab)}`,
              undefined,
              true,
            )
              .then(() => {
                resetForm();
                navigate(`${AdminPrivateRoutes.SETTINGS.CTC.CTCVIEW}`);
                setLoader(false);
              })
              .catch((e) => {
                // console.log(e);
                if (e === 'Forbidden resource') {
                  toast.error(AccessDenied);
                  navigate('/dashboard');
                }
                setLoader(false);
              });
          }
          setLoader(false);
        })
        .catch((e) => {
          // console.log(e);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    }
  };
  useEffect(() => {
    getInitialStatus();
  }, []);

  return (
    <>
      <Grid container>
        {loader && <Loader />}
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit'>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Settings
              </Link>
              <Link
                underline='hover'
                color='inherit'
                href=''
                onClick={() => navigate('/settings/ctc-slab')}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                CTC-Slab
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {editId ? 'Edit' : 'Add'} CTC-Slab
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>{editId ? 'Edit' : 'Add'} CTC-Slab</Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button className='cancel-btn mr-16' onClick={() => navigate('/settings/ctc-slab')}>
                Cancel
              </Button>
              <Button form={'CTC-Slab-add'} className='s-add-btn' type='submit'>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form
                className='private-form'
                id={'CTC-Slab-add'}
                onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container>
                  <Grid item md={8} sm={12} xs={12}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Min CTC<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name='minCtc'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  className='custom-input'
                                  onChange={(e) => {
                                    const rawValue = e.target.value.replace(/[^\d.]/g, '');
                                    const numericValue = parseFloat(rawValue) || 0;
                                    const formatCurrency = (
                                      value: any,
                                      locale: any,
                                      currency: string,
                                    ) =>
                                      new Intl.NumberFormat(locale, {
                                        currency,
                                        maximumFractionDigits: 0,
                                      }).format(value);

                                    const targetCurrency: any = getValues('minCtcCurrency')
                                      ? getValues('minCtcCurrency').toLocaleUpperCase()
                                      : 'INR';
                                    const currencySettings: any = {
                                      USD: { locale: 'en-US', currency: 'USD' },
                                      INR: { locale: 'en-IN', currency: 'INR' },
                                      AED: { locale: 'ar-AE', currency: 'AED' },
                                    };
                                    const { locale, currency } = currencySettings[targetCurrency];
                                    const formattedValue = formatCurrency(
                                      numericValue,
                                      locale,
                                      currency,
                                    );
                                    onChange(formattedValue);
                                  }}
                                  value={value}
                                  name={name}
                                  placeHolder='Min. CTC'
                                  // type={'number'}
                                  currency
                                  error={errors?.minCtc?.message}
                                  customComponent={
                                    <Box
                                      sx={{
                                        minWidth: 120,
                                        position: 'absolute',
                                        right: '-40px',
                                        transform: 'scale(0.9)',
                                      }}>
                                      <Controller
                                        control={control}
                                        name='minCtcCurrency'
                                        render={({ field: { value, name } }) => {
                                          return (
                                            <NativeSelect
                                              value={value}
                                              name={name}
                                              onChange={(e: any) => {
                                                if (e.target.value !== value) {
                                                  setValue('minCtc', '');
                                                }
                                                setValue('minCtcCurrency', e.target.value);
                                              }}
                                              // disabled
                                              className='position-currency-option'
                                              IconComponent={ExpandMoreRoundedIcon}
                                              sx={{
                                                border: 'none !important',
                                                minHeight: '20px !important',
                                                '&::before': {
                                                  borderBottom: 'transparent !important',
                                                },
                                                '& > select': {
                                                  minWidth: '40px !important',
                                                  border: 'none !important',
                                                  outline: 'none !important',
                                                  marginRight: '8px',
                                                },
                                              }}>
                                              <option value={'inr'}>INR</option>
                                              <option value={'usd'}>USD</option>
                                              <option value={'aed'}>AED</option>
                                            </NativeSelect>
                                          );
                                        }}
                                      />
                                    </Box>
                                  }
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Max CTC<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name='maxCtc'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <>
                                  <CustomInput
                                    className='custom-input phnumber-posiotion'
                                    onChange={(e) => {
                                      const rawValue = e.target.value.replace(/[^\d.]/g, '');
                                      const numericValue = parseFloat(rawValue) || 0;
                                      const formatCurrency = (
                                        value: any,
                                        locale: any,
                                        currency: string,
                                      ) =>
                                        new Intl.NumberFormat(locale, {
                                          currency,
                                          maximumFractionDigits: 0,
                                        }).format(value);

                                      const targetCurrency: any = getValues('maxCtcCurrency')
                                        ? getValues('maxCtcCurrency').toLocaleUpperCase()
                                        : 'INR';
                                      const currencySettings: any = {
                                        USD: { locale: 'en-US', currency: 'USD' },
                                        INR: { locale: 'en-IN', currency: 'INR' },
                                        AED: { locale: 'ar-AE', currency: 'AED' },
                                      };
                                      const { locale, currency } = currencySettings[targetCurrency];
                                      const formattedValue = formatCurrency(
                                        numericValue,
                                        locale,
                                        currency,
                                      );
                                      onChange(formattedValue);
                                    }}
                                    value={value}
                                    name={name}
                                    placeHolder='Max CTC'
                                    currency
                                    // type={'number'}
                                    error={errors?.maxCtc?.message}
                                    customComponent={
                                      <Box
                                        sx={{
                                          minWidth: 120,
                                          position: 'absolute',
                                          right: '-40px',
                                          transform: 'scale(0.9)',
                                        }}>
                                        <Controller
                                          control={control}
                                          name='maxCtcCurrency'
                                          render={({ field: { value, name } }) => {
                                            return (
                                              <NativeSelect
                                                value={value}
                                                name={name}
                                                onChange={(e: any) => {
                                                  if (e.target.value !== value) {
                                                    setValue('maxCtc', '');
                                                  }
                                                  setValue('maxCtcCurrency', e.target.value);
                                                }}
                                                // disabled
                                                className='position-currency-option'
                                                IconComponent={ExpandMoreRoundedIcon}
                                                sx={{
                                                  border: 'none !important',
                                                  minHeight: '20px !important',
                                                  '&::before': {
                                                    borderBottom: 'transparent !important',
                                                  },
                                                  '& > select': {
                                                    minWidth: '40px !important',
                                                    border: 'none !important',
                                                    outline: 'none !important',
                                                    marginRight: '8px',
                                                  },
                                                }}>
                                                <option value={'inr'}>INR</option>
                                                <option value={'usd'}>USD</option>
                                                <option value={'aed'}>AED</option>
                                              </NativeSelect>
                                            );
                                          }}
                                        />
                                      </Box>
                                    }
                                  />
                                </>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Target<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name='target'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  className='custom-input'
                                  onChange={onChange}
                                  value={value}
                                  name={name}
                                  type={'number'}
                                  placeHolder='Target'
                                  error={errors.target?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Start Date<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name={'startDate'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <>
                                  <TextField
                                    value={value}
                                    name={name}
                                    id='date'
                                    type='date'
                                    placeholder={'Select Date of Birth'}
                                    className={`clientfilter-time-from-to ${
                                      errors.startDate && errors.startDate.message
                                        ? 'clientfilter-time-from-to-error'
                                        : ''
                                    }`}
                                    InputProps={{
                                      inputProps: {
                                        min: currentDate,
                                      },
                                    }}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                  {errors && errors.startDate && errors?.startDate?.message && (
                                    <div className='Custom-error '>
                                      {String(errors?.startDate?.message)}
                                    </div>
                                  )}
                                </>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Typography>
        </Grid>
        <ConfirmDialog
          title='Duplicate CTC-Slab'
          floatingBtnShow={true}
          open={confirmOpen.open}
          close={() => setConfirmOpen({ open: false, data: {} })}
          visibleClose={true}
          onConfirm={(act: any) =>
            act === 'yes'
              ? duplicateFunction(confirmOpen.data)
              : setConfirmOpen({ open: false, data: {} })
          }
          autoCloseDisable={true}>
          {
            "This CTC slab is duplicated. If you select 'Yes', the existing slab will end on the current date."
          }
        </ConfirmDialog>
      </Grid>
    </>
  );
};
export default CtcSlabAdd;
