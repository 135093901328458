/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  Typography,
  Breadcrumbs,
  Button,
  Link,
  Checkbox,
  styled,
  InputAdornment,
  TextField,
  NativeSelect,
  Box,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Loader from 'components/loader';
import { useEffect, useState } from 'react';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { To, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CustomInput from 'components/CustomInput/customInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { AddBilling } from 'types';
import { AddBillingSchema } from 'utils/ValidatorSchema';
import CustomSelect from 'components/CustomSelect/customSelect';
import { addNewData, getSingleData, updateData } from 'services/CommonServices';
import { BillingENDPOINT, ResumesENDPOINT } from 'utils/Constant';
import moment from 'moment';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import EmailIcon from 'assets/png/email.svg';
import { useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
import { Country, State, City } from 'country-state-city';
import { finalCurrency } from 'utils/Config';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const BillingAdd = () => {
  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const location = useLocation();
  const currentDate = moment().format('YYYY-MM-DD');
  const [searchParams]: any = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [resumeId, setResumeId] = useState<string>('');
  const [spocData, setSpocData] = useState<any>({});
  const [countryClient, setCountryClient] = useState([]);
  const [statesClient, setStatesClient] = useState([]);
  const [citiesClient, setCitiesClient] = useState([]);
  const [countryCourier, setCountryCourier] = useState([]);
  const [statesCourier, setStatesCourier] = useState([]);
  const [citiesCourier, setCitiesCourier] = useState([]);
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    watch,
  } = useForm<AddBilling>({
    criteriaMode: 'all',
    defaultValues: {
      canditateName: '',
      position: '',
      title: '',
      clientName: '',
      joinedDate: '',
      ctcOffered: '',
      ctcOfferedCurrency: 'inr',
      designationOffered: '',
      billingPercentage: undefined,
      billingAmount: '',
      billingDate: '',
      additionalClientName: '',
      additionalClientDesignation: '',
      additionalClientAddress1: '',
      additionalClientAddress2: '',
      additionalClientCountry: '',
      additionalClientState: '',
      additionalClientCity: '',
      additionalClientPincode: '',
      purchaseOrder: '',
      sameBillingAddress: false,
      sameSpocAddress: false,
      courierAddress1: '',
      courierAddress2: '',
      courierCountry: '',
      courierState: '',
      courierCity: '',
      courierPincode: '',
      gstn: '',
      phoneNumber: '',
      countryCodeText: '',
      phoneNumberFormatted: '',
      email: '',
      remarks: '',
      countryCode: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AddBillingSchema),
  });
  const onSubmit = (data: AddBilling) => {
    const obj: any = {
      resumeId: location?.state?.data?.id ? location?.state?.data?.id : resumeId ? resumeId : '',
      billingPercentage: data.billingPercentage,
      billingAmount: data.billingAmount
        ? parseInt(String(data.billingAmount).replace(/,/g, ''))
        : 0,
      offeredCtcCurrency: data.ctcOfferedCurrency,
      billingAmountCurrency: data.ctcOfferedCurrency,
      billingDate: data.billingDate,
      offeredCTC: data.ctcOffered ? parseInt(String(data.ctcOffered).replace(/,/g, '')) : 0,
      designationOffered: data?.designationOffered,
      title: data.title,
      name: data.additionalClientName,
      designation: data.additionalClientDesignation,
      billingAddress1: data.additionalClientAddress1,
      billingAddress2: data.additionalClientAddress2,
      billingCity: data.additionalClientCity,
      billingCountry: data.additionalClientCountry,
      billingState: data.additionalClientState,
      billingPincode: data.additionalClientPincode,
      isSameAsBillingAddress: data.sameBillingAddress,
      isSameAsSpocAddress: data.sameSpocAddress,
      purchaseOrder: data.purchaseOrder,
      courierAddress1: data.courierAddress1,
      courierAddress2: data.courierAddress2,
      courierCity: data.courierCity,
      courierCountry: data.courierCountry,
      courierState: data.courierState,
      courierPincode: data.courierPincode,
      gstn: data.gstn,
      remarks: data.remarks,
      countryCode: `+${(data?.countryCode ?? '').replace('+', '')}`,
      phoneNumber: data?.phoneNumberFormatted
        ? String(data?.phoneNumberFormatted).replace(
            `+${String(data?.countryCode).replace('+', '')}`,
            '',
          )
        : '',
      email: data.email,
    };

    if (searchParams.get('id')) {
      setLoading(true);
      updateData(searchParams.get('id'), obj, BillingENDPOINT.billing, true)
        .then(() => {
          navigate(AdminPrivateRoutes.BILLING.BILLINGLIST);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    } else {
      setLoading(true);
      addNewData(obj, BillingENDPOINT.billing, {}, true)
        .then(() => {
          navigate(AdminPrivateRoutes.BILLING.BILLINGLIST);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    }
  };
  const getResume = () => {
    getSingleData(
      '',
      `${ResumesENDPOINT.resumeList}/list/${searchParams?.get('positionId')}?organizationId=${
        userInfo.organizationId
      }&type=cv-status&id=${searchParams?.get('resumeId')}`,
      true,
    )
      .then((res) => {
        setSpocData(res.data[0].position.spoc);
      })
      .catch(() => setLoading(false));
  };

  const spocAddressCheck = () => {
    if (getValues('sameSpocAddress') === true) {
      const {
        title,
        firstName,
        lastName,
        address1,
        address2,
        state,
        pincode,
        country,
        city,
        phoneNumber,
        email,
        countryCode,
        designation,
      } = spocData;
      setValue('title', title);
      setValue('additionalClientName', `${firstName} ${lastName}`);
      setValue('additionalClientAddress1', address1);
      setValue('additionalClientCountry', country);
      setValue('additionalClientState', state);
      setValue('additionalClientPincode', pincode);
      setValue('additionalClientDesignation', designation.name);
      setValue('additionalClientAddress2', address2);
      setValue('additionalClientCity', city);
      setValue('countryCode', countryCode);
      setValue('phoneNumberFormatted', `+${countryCode}${phoneNumber.replace('+', '')}`);
      setValue('phoneNumber', `${countryCode}${phoneNumber}`);
      setValue('email', email);
      trigger('phoneNumberFormatted');
    } else {
      setValue('title', '');
      setValue('additionalClientName', '');
      setValue('additionalClientAddress1', '');
      setValue('additionalClientState', '');
      setValue('additionalClientState', '');
      setValue('additionalClientPincode', '');
      setValue('additionalClientDesignation', '');
      setValue('additionalClientAddress2', '');
      setValue('additionalClientCity', '');
      setValue('phoneNumberFormatted', '');
      setValue('countryCode', '');
      setValue('phoneNumber', '');
      setValue('email', '');
    }
  };

  const convertBillingAmount = () => {
    const ctc: any = getValues('ctcOffered')
      ? parseInt(getValues('ctcOffered').replace(/,/g, ''))
      : 0;
    const percent: any = getValues('billingPercentage');
    const finalValue: any = finalCurrency(
      (ctc * percent) / 100,
      getValues('ctcOfferedCurrency').toLocaleUpperCase(),
    );

    setValue('billingAmount', finalValue);
  };
  useEffect(() => {
    if (getValues('ctcOffered') !== undefined && getValues('billingPercentage') !== undefined) {
      convertBillingAmount();
    } else {
      setValue('billingAmount', '');
    }
  }, [getValues('ctcOffered'), getValues('billingPercentage')]);

  useEffect(() => {
    if (location?.state?.data) {
      setValue(
        'canditateName',
        `${location?.state?.data?.personal?.firstName} ${location?.state?.data?.personal?.lastName}`,
      );
      setValue('position', location?.state?.data?.position?.title);
      setValue('clientName', location?.state?.data?.client?.corporateName);
      setValue('joinedDate', location?.state?.data?.joinedDate);
      setValue(
        'ctcOffered',
        finalCurrency(
          location?.state?.data?.offeredCTC,
          location?.state?.data?.offeredCtcCurrency.toLocaleUpperCase(),
        ),
      );
      setValue('ctcOfferedCurrency', location?.state?.data?.offeredCtcCurrency);
    }
  }, [location?.state?.data]);

  useEffect(() => {
    if (searchParams.get('id')) {
      getSingleData(searchParams?.get('id'), BillingENDPOINT.billing, true)
        .then((res: any) => {
          if (res && res.data) {
            const data = res && res.data[0];
            setResumeId(data?.resumeId ? data?.resumeId : '');
            setValue(
              'canditateName',
              `${data?.resume?.personal?.firstName} ${data?.resume?.personal?.lastName}`,
            );
            setValue('title', data.title);
            setValue('position', data?.resume?.position?.title);
            setValue('clientName', data?.resume?.client?.corporateName);
            setValue('joinedDate', moment(data?.resume?.joinedDate).format('DD-MM-YYYY'));
            setValue(
              'ctcOffered',
              finalCurrency(data?.resume?.offeredCTC, data?.resume?.offeredCtcCurrency),
            );
            setValue('ctcOfferedCurrency', data?.resume?.offeredCtcCurrency);
            setValue('designationOffered', data?.resume?.designationOffered);
            setValue('billingPercentage', data?.billingPercentage);
            setValue('billingAmount', data?.billingAmount);
            setValue('billingDate', data?.billingDate);
            setValue('additionalClientName', data?.name);
            setValue('additionalClientDesignation', data?.designation);
            setValue('additionalClientAddress1', data?.billingAddress1);
            setValue('additionalClientAddress2', data?.billingAddress2);
            setValue('additionalClientCountry', data?.billingCountry);
            setValue('additionalClientState', data?.billingState);
            setValue('additionalClientCity', data?.billingCity);
            setValue('additionalClientPincode', data?.billingPincode);
            setValue('sameBillingAddress', data?.isSameAsBillingAddress);
            setValue('sameSpocAddress', data?.isSameAsSpocAddress);
            setValue('purchaseOrder', data?.purchaseOrder);
            setValue('courierAddress1', data?.courierAddress1);
            setValue('courierAddress2', data?.courierAddress2);
            setValue('courierCountry', data?.courierCountry);
            setValue('courierState', data?.courierState);
            setValue('courierCity', data?.courierCity);
            setValue('courierPincode', data?.courierPincode);
            setValue('gstn', data?.gstn);
            setValue('remarks', data?.remarks);
            setValue(
              'phoneNumberFormatted',
              `+${data?.countryCode.replace('+', '')}${data?.phoneNumber}`,
            );
            setValue('countryCode', `${data?.countryCode}`);
            setValue('email', `${data?.email}`);
            setValue('phoneNumber', `${data?.phoneNumber}`);
          }
          setLoading(false);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoading(false);
        });
    }
  }, [searchParams.get('id')]);

  useEffect(() => {
    getResume();
  }, []);

  useEffect(() => {
    if (watch('additionalClientCountry')) {
      const options: any = [];
      State.getStatesOfCountry(watch('additionalClientCountry')).map((state: any) => {
        options.push({ label: state.name, value: state.isoCode });
      });
      setStatesClient(options);
    }
    if (watch('courierCountry')) {
      const options: any = [];
      State.getStatesOfCountry(watch('courierCountry')).map((state: any) => {
        options.push({ label: state.name, value: state.isoCode });
      });
      setStatesCourier(options);
    }
  }, [watch('additionalClientCountry'), watch('courierCountry')]);

  useEffect(() => {
    if (watch('additionalClientState') && watch('additionalClientCountry')) {
      const options: any = [];
      City.getCitiesOfState(watch('additionalClientCountry'), watch('additionalClientState')).map(
        (city: any) => {
          options.push({ label: city.name, value: city.name });
        },
      );
      setCitiesClient(options);
    }
    if (watch('courierState') && watch('courierCountry')) {
      const options: any = [];
      City.getCitiesOfState(watch('courierCountry'), watch('courierState')).map((city: any) => {
        options.push({ label: city.name, value: city.name });
      });
      setCitiesCourier(options);
    }
  }, [
    watch('additionalClientCountry'),
    watch('additionalClientState'),
    watch('courierState'),
    watch('courierCountry'),
  ]);

  useEffect(() => {
    const options: any = [];
    Country.getAllCountries().map((country) => {
      options.push({ label: country.name, value: country.isoCode });
    });
    setCountryClient(options);
    setCountryCourier(options);
  }, []);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit' href={AdminPrivateRoutes.BILLING.BILLINGLIST}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Billing
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {searchParams.get('id') ? 'Edit' : 'Add'} Billing
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>
              {searchParams.get('id') ? 'Edit' : 'Add'} Billing
            </Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() => gotoNavigate(`${AdminPrivateRoutes.BILLING.BILLINGLIST}`)}>
                {' '}
                Cancel
              </Button>
              <Button className='s-add-btn' form={'billing-form'} type={'submit'}>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form id={'billing-form'} className='private-form' onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography component={'div'} className='basic-details-position'>
                      Candidate Information
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className='bdiveder' />
                  </Grid>
                  <Grid container columnSpacing={4}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Canditate Name<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='canditateName'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                disabled
                                className='custom-input'
                                placeHolder='Canditate name'
                                error={errors.canditateName?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Position<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='position'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                disabled
                                className='custom-input'
                                placeHolder='Position'
                                error={errors.position?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Client Name<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='clientName'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                disabled
                                className='custom-input'
                                placeHolder='Client name'
                                error={errors.clientName?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Joined Date<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='joinedDate'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                disabled
                                className='custom-input'
                                placeHolder='Joined date'
                                error={errors.joinedDate?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '25px' }}>
                      <Typography component={'div'} className='basic-details-position'>
                        Billing Information
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography className='bdiveder' />
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          CTC Offered<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='ctcOffered'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              // <CustomInput
                              //   value={value}
                              //   name={name}
                              //   className='custom-input'
                              //   placeHolder='CTC offered'
                              //   error={errors.ctcOffered?.message}
                              //   inputProps={{
                              //     inputProps: { min: 0 },
                              //   }}
                              //   onChange={(e) => {
                              //     onChange(e);
                              // convertBillingAmount();
                              // trigger('ctcOffered');
                              //   }}
                              // />
                              <CustomInput
                                className='custom-input'
                                onChange={(e) => {
                                  const rawValue = e.target.value.replace(/[^\d.]/g, '');
                                  const numericValue = parseFloat(rawValue) || 0;
                                  const formatCurrency = (
                                    value: any,
                                    locale: any,
                                    currency: string,
                                  ) =>
                                    new Intl.NumberFormat(locale, {
                                      currency,
                                      maximumFractionDigits: 0,
                                    }).format(value);

                                  const targetCurrency: any = getValues('ctcOfferedCurrency')
                                    ? getValues('ctcOfferedCurrency').toLocaleUpperCase()
                                    : 'INR';
                                  const currencySettings: any = {
                                    USD: { locale: 'en-US', currency: 'USD' },
                                    INR: { locale: 'en-IN', currency: 'INR' },
                                    AED: { locale: 'ar-AE', currency: 'AED' },
                                  };
                                  const { locale, currency } = currencySettings[targetCurrency];
                                  const formattedValue = formatCurrency(
                                    numericValue,
                                    locale,
                                    currency,
                                  );
                                  onChange(formattedValue);
                                  convertBillingAmount();
                                  trigger('ctcOffered');
                                }}
                                value={value}
                                name={name}
                                placeHolder='CTC offered'
                                currency
                                // type={'number'}
                                error={errors.ctcOffered?.message}
                                customComponent={
                                  <Box
                                    sx={{
                                      minWidth: 120,
                                      position: 'absolute',
                                      right: '-40px',
                                      transform: 'scale(0.9)',
                                    }}>
                                    <Controller
                                      control={control}
                                      name='ctcOfferedCurrency'
                                      render={({ field: { value, name } }) => {
                                        return (
                                          <NativeSelect
                                            // defaultValue={getValues('minCurrency')}
                                            value={value}
                                            name={name}
                                            onChange={(e: any) => {
                                              if (e.target.value !== value) {
                                                setValue('ctcOffered', '');
                                              }
                                              setValue('ctcOfferedCurrency', e.target.value);
                                            }}
                                            // disabled
                                            className='position-currency-option'
                                            IconComponent={ExpandMoreRoundedIcon}
                                            sx={{
                                              border: 'none !important',
                                              minHeight: '20px !important',
                                              '&::before': {
                                                borderBottom: 'transparent !important',
                                              },
                                              '& > select': {
                                                minWidth: '40px !important',
                                                border: 'none !important',
                                                outline: 'none !important',
                                                marginRight: '8px',
                                              },
                                            }}>
                                            <option value={'inr'}>INR</option>
                                            <option value={'usd'}>USD</option>
                                            <option value={'aed'}>AED</option>
                                          </NativeSelect>
                                        );
                                      }}
                                    />
                                  </Box>
                                }
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Designation Offered<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='designationOffered'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Designation offered'
                                // tabIndex={2}
                                error={errors.designationOffered?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Billing % <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='billingPercentage'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                // type='number'
                                // tabIndex={3}
                                className='custom-input'
                                placeHolder='Billing %'
                                error={errors.billingPercentage?.message}
                                inputProps={{
                                  inputProps: { min: 0 },
                                }}
                                onChange={(e) => {
                                  convertBillingAmount();
                                  onChange(e);
                                  trigger('billingPercentage');
                                  trigger('billingAmount');
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Billing Amount<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='billingAmount'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                // type={'number'}
                                currency
                                // tabIndex={4}
                                disabled={getValues('billingPercentage') ? true : false}
                                className='custom-input'
                                placeHolder='Billing amount'
                                error={errors.billingAmount?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Billing Date<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='billingDate'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <TextField
                                id='date'
                                type='date'
                                name={name}
                                value={value ? value : null}
                                className={`clientfilter-time-from-to ${
                                  errors.billingDate && errors.billingDate?.message
                                    ? 'clientfilter-time-from-to-error'
                                    : ''
                                }`}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputProps: {
                                    min: location?.state?.data?.joinedDate
                                      ? location?.state?.data?.joinedDate
                                      : currentDate,
                                  },
                                }}
                              />
                            );
                          }}
                        />
                        {errors && errors.billingDate && errors.billingDate?.message && (
                          <div className='Custom-error '>{String(errors.billingDate?.message)}</div>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>GSTN</Typography>
                        <Controller
                          control={control}
                          name='gstn'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='GSTN'
                                // tabIndex={20}
                                error={errors.gstn?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Purchase Order</Typography>
                        <Controller
                          control={control}
                          name='purchaseOrder'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Purchase Order'
                                // tabIndex={20}
                                error={errors.purchaseOrder?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '25px', display: 'flex' }}>
                      <Grid md={5}>
                        <Typography component={'div'} className='basic-details-position'>
                          Additional Client Information
                        </Typography>
                      </Grid>
                      <Grid md={7} className='d-flex flex-end'>
                        <Typography component={'div'} className='custom-field'>
                          <Controller
                            control={control}
                            name='sameSpocAddress'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <Checkbox
                                  className='custom-checkbox'
                                  sx={{
                                    '&:hover': { bgcolor: 'transparent' },
                                    marginRight: '10px',
                                  }}
                                  checked={value}
                                  name={name}
                                  // tabIndex={14}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    spocAddressCheck();
                                  }}
                                  color='default'
                                  checkedIcon={<CheckedIcon />}
                                  icon={<CheckIcon />}
                                />
                              );
                            }}
                          />
                          Check this box if the SPOC address same as the client address
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography className='bdiveder' />
                    </Grid>
                    <Grid item md={1} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Title<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='title'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                value={value}
                                name={name}
                                isClearable={false}
                                // tabIndex={6}
                                className='custom-input'
                                options={[
                                  { label: 'Mr.', value: 'mr' },
                                  { label: 'Mrs.', value: 'mrs' },
                                ]}
                                placeHolder='Title'
                                error={errors.title?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Name<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='additionalClientName'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                // tabIndex={7}
                                className='custom-input'
                                placeHolder='Name'
                                error={errors.additionalClientName?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Designation<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='additionalClientDesignation'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                // tabIndex={8}
                                className='custom-input'
                                placeHolder='Designation'
                                error={errors.additionalClientDesignation?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Billing Address1<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='additionalClientAddress1'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                // tabIndex={9}
                                className='custom-input'
                                placeHolder='Billing address1'
                                error={errors.additionalClientAddress1?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Billing Address2<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='additionalClientAddress2'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                // tabIndex={10}
                                className='custom-input'
                                placeHolder='Billing Address2'
                                error={errors.additionalClientAddress2?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Country<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='additionalClientCountry'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={countryClient}
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='Country'
                                error={errors.additionalClientCountry?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          State<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='additionalClientState'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={statesClient}
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='State'
                                // tabIndex={11}
                                error={errors.additionalClientState?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          City<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='additionalClientCity'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={citiesClient}
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='City'
                                // tabIndex={11}
                                error={errors.additionalClientCity?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Pincode<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='additionalClientPincode'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                type={'number'}
                                // tabIndex={12}
                                className='custom-input'
                                placeHolder='Pincode'
                                error={errors.additionalClientPincode?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Email <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=''
                          name='email'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                inputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <img src={EmailIcon} alt='email' />
                                    </InputAdornment>
                                  ),
                                }}
                                value={value}
                                name={name}
                                // tabIndex={14}
                                className='custom-input'
                                placeHolder='Email'
                                error={errors.email?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Phone No <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'phoneNumberFormatted'}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <CustomPhoneInput
                                placeholder=''
                                containerClass={
                                  errors.phoneNumber?.message ? 'phone-input-error' : ''
                                }
                                inputClass={
                                  errors.phoneNumber?.message
                                    ? 'phone-input phone-input-no-border'
                                    : 'phone-input'
                                }
                                initialCountry={'in'}
                                specialLabel={''}
                                countryCodeEditable={false}
                                searchClass={'search-custom-class'}
                                value={value}
                                enableSearch={true}
                                searchNotFound={'No Country Found'}
                                inputStyle={{
                                  width: '100%',
                                  padding: '9px 11px',
                                  borderRadius: '6px',
                                  paddingLeft: '60px',
                                  color: value && String(value).length > 0 ? '#344051' : '#bdbdbd',
                                  fontSize: '16px',
                                  fontFamily: 'Poppins-Regular',
                                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                }}
                                onChange={(value, data, event, formattedValue) => {
                                  const { countryCode, dialCode } = data;
                                  setValue('countryCodeText', countryCode);
                                  setValue('countryCode', dialCode);
                                  onChange(`+${value}`);
                                  setValue(
                                    'phoneNumber',
                                    value?.substring(countryCode?.length ?? 0, value.length ?? 0),
                                  );
                                }}
                                error={
                                  errors.phoneNumberFormatted?.message
                                    ? errors.phoneNumberFormatted?.message
                                    : ''
                                }
                                // inputProps={{ tabIndex: 13 }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ marginTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                      <Typography component={'div'} className='basic-details-position'>
                        Courier Information
                      </Typography>
                      <Typography component={'div'} className='custom-field'>
                        <Controller
                          control={control}
                          name='sameBillingAddress'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <Checkbox
                                className='custom-checkbox'
                                sx={{
                                  '&:hover': { bgcolor: 'transparent' },
                                  marginRight: '10px',
                                }}
                                checked={value}
                                name={name}
                                // tabIndex={14}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  if (getValues('sameBillingAddress') === true) {
                                    setValue(
                                      'courierAddress1',
                                      getValues('additionalClientAddress1'),
                                    );
                                    setValue(
                                      'courierAddress2',
                                      getValues('additionalClientAddress2'),
                                    );
                                    setValue('courierCity', getValues('additionalClientCity'));
                                    setValue(
                                      'courierPincode',
                                      getValues('additionalClientPincode'),
                                    );
                                    setValue('courierState', getValues('additionalClientState'));
                                    setValue(
                                      'courierCountry',
                                      getValues('additionalClientCountry'),
                                    );
                                    trigger('courierAddress1');
                                    trigger('courierAddress2');
                                    trigger('courierCity');
                                    trigger('courierPincode');
                                    trigger('courierState');
                                    trigger('courierCountry');
                                  } else {
                                    setValue('courierAddress1', '');
                                    setValue('courierAddress2', '');
                                    setValue('courierCity', '');
                                    setValue('courierPincode', '');
                                    setValue('courierState', '');
                                    setValue('courierCountry', '');
                                  }
                                }}
                                color='default'
                                checkedIcon={<CheckedIcon />}
                                icon={<CheckIcon />}
                              />
                            );
                          }}
                        />
                        Check this box if courier address same as billing address
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography className='bdiveder' />
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Courier Address1<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='courierAddress1'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                // tabIndex={15}
                                className='custom-input'
                                placeHolder='Courier address1'
                                error={errors.courierAddress1?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Courier Address2<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='courierAddress2'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                // tabIndex={16}
                                className='custom-input'
                                placeHolder='Courier Address2'
                                error={errors.courierAddress2?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Country<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='courierCountry'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={countryCourier}
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='Country'
                                // tabIndex={11}
                                error={errors.courierCountry?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          State<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='courierState'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={statesCourier}
                                value={value ? value : null}
                                name={name}
                                // tabIndex={17}
                                className='custom-input'
                                placeHolder='State'
                                error={errors.courierState?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          City<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='courierCity'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={citiesCourier}
                                value={value ? value : null}
                                name={name}
                                // tabIndex={18}
                                className='custom-input'
                                placeHolder='City'
                                error={errors.courierCity?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Pincode<span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='courierPincode'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                type={'number'}
                                // tabIndex={19}
                                className='custom-input'
                                placeHolder='Pincode'
                                error={errors.courierPincode?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={10} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Remarks</Typography>
                        <Controller
                          control={control}
                          name='remarks'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                // tabIndex={21}
                                isTextArea={true}
                                className='custom-input'
                                placeHolder='Remarks'
                                error={errors.remarks?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      {loading && <Loader />}
    </>
  );
};
export default BillingAdd;
