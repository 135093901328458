import axios from 'axios';
import { useCustomNavigation } from 'hooks';
import { store } from 'store';
// import { refreshToken } from 'store/actions/UserAction';
import Storage from 'utils/Storage';
import platform from 'platform';
import { USER_LOGOUT } from 'store/types/UserTypes';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
// import { internalIpV4 } from 'internal-ip';

const { dispatch }: any = store;
const deviceName = navigator.userAgent.split('(')[1].split(';')[0];

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
    // 'x-forwarded-for': Storage.getIp() ? Storage.getIp() : '',
    'x-ct-hiring-device': deviceName,
    'x-ct-hiring-browser': platform.name,
    // 'x-ct-hiring-location': 'usa',
    'x-ct-hiring-datetime': new Date() as any,
  },
});

instance.interceptors.request.use(
  (config: any) => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     async (position) => {
    //       const { latitude, longitude } = position.coords;
    //       try {
    //         const response = await axios.get(
    //           `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`,
    //         );
    //         Storage.setItem(Storage.KEYS.LOCATION, response?.data?.address?.country)
    //         config.headers['x-ct-hiring-location'] = response?.data?.address?.country;
    //       } catch (error) {
    //         console.error('Error fetching address:', error);
    //       }
    //     },
    //     (error) => {
    //       console.log(error);
    //     },
    //   );
    //   console.log(navigator.geolocation);
    // }

    const TokenStatus: any = Storage.getTokens();
    const ipAddress: any = Storage.getIp();
    const location: any = Storage.getItem(Storage.KEYS.LOCATION);

    config.headers['x-forwarded-for'] = ipAddress ? ipAddress : '';
    config.headers['x-ct-hiring-location'] = location ? location : '';

    const token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
    if (token) {
      config.headers['Authorization'] = 'Bearer '.concat(token);
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

let failedQueue: any = [];
let isRefreshing = false;

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const responseData = err.response.data;
    if (originalConfig.url !== 'login' && err.response) {
      // Access Token was expired

      if (err.response.status === 401) {
        if (isRefreshing) {
          new Promise((resolve, reject) => {            
            failedQueue.push({ reject, resolve });
          })
            .then((token: any) => {              
              originalConfig.headers['Authorization'] = 'Bearer '.concat(token);
              return instance(originalConfig);
            })
            .catch((err) => {              
              return Promise.reject(err);
            });
        }
        // originalConfig._retry = true;
        originalConfig._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          const TokenStatus: any = Storage.getTokens();
          instance
            .post('tokens/exchange', {
              refreshToken: TokenStatus && TokenStatus.refreshToken ? TokenStatus.refreshToken : '',
            })
            .then(async (rs) => {
              isRefreshing = false;
              const { accessToken, refreshToken: refreshToken1 } = rs.data;
              originalConfig.headers['Authorization'] = 'Bearer '.concat(accessToken);
              // dispatch(refreshToken(accessToken, refreshToken1));
              await Storage.updateAccessToken(accessToken, refreshToken1);
              // const tokenData: any = {
              // refreshToken: refreshToken1,
              // token: accessToken,
              // };
              processQueue(null, accessToken);
              // return instance(originalConfig);
              resolve(instance(originalConfig));
            })
            .catch((err) => {
              // processQueue(err, null);
              // dispatch({
              // type: USER_LOGOUT,
              // });
              // Storage.clear();
              // // toast.error('Please login again...', alertOptions);
              // useCustomNavigation('/login', { replace: true });
              reject(err);
            });
        });
      }

      if (err.response.status === 403 && err.response.data) {
        if (err?.response?.data?.error?.data?.message !== 'Forbidden resource') {
          dispatch({
            type: USER_LOGOUT,
          });
          Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
          toast.error(err?.response?.data?.error?.message || 'Please login again...', alertOptions);
          useCustomNavigation('/login', { replace: true });
        }
      }

      if (
        responseData.error?.message &&
        (responseData.error?.message === 'Invalid Token!' ||
          responseData.error?.message === 'Refresh token already exchanged' ||
          responseData.error?.message === 'Token expired' ||
          responseData.error?.message === 'Refresh token not found')
      ) {
       await dispatch({
          type: USER_LOGOUT,
        });
       await Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
        // toast.error('Session Expired! Please login again... ', alertOptions);
        useCustomNavigation('/login', { replace: true });
      }
    }    

    return Promise.reject(err);
  },
);
export default instance;
