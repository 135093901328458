import {
  Breadcrumbs,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
  Table,
  Link,
  IconButton,
  InputAdornment,
  TableHead,
  TextField,
} from '@mui/material';
import Loader from 'components/loader';
import { useDebounce } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import HomeIcon from 'assets/png/dashboardactive.svg';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { BillingENDPOINT } from 'utils/Constant';
import SearchIcon from 'assets/png/nsearch.svg';
import CloseIcon from '@mui/icons-material/Close';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import Paginate from 'components/Pagination/Pagination';
import ExcelIcon from 'assets/iconcomponents/excelicon';

const Defaultparam = {
  search: null,
  order: '',
  skip: 0,
  sortby: '',
  take: 50,
  organizationId: null,
};

export const ClientAgreement = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);

  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };
  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  const listdataapi = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${debouncedParams.search}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLoading(true);

    getAllListData(
      `${BillingENDPOINT.clientAgreement}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
          setpagetotal(Number(val.total));
        } else {
          setcommonstate([]);
        }
        setLoading(!true);
      })
      .catch(() => {
        setLoading(!true);
      });
  };

  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);
  return (
    <Grid container>
      {loading && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.DASHBOARD)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Agreements
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>Agreements</Typography>
          <div className='expot-icon'>
            <span>
              <ExcelIcon
                url={`${BillingENDPOINT.clientAgreement}${
                  Object.values({ ...params }).length > 0 && '?'
                }${[
                  `${orgdy ? `organizationId=${orgdy}` : null}`,
                  `${debouncedParams.search ? `search=${debouncedParams.search}` : null}`,
                  `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
                  `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
                  `${
                    debouncedParams.skip
                      ? `skip=${debouncedParams.skip}`
                      : debouncedParams.take
                        ? 'skip=0'
                        : null
                  }`,
                  `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
                  'type=export',
                ]
                  .filter((ea: any) => ea !== 'null')
                  .join('&')}`}
                fileName={'clientAgreements'}
                setLoader={setLoading}
              />
            </span>
            <span style={{ width: '12.17px' }}></span>
          </div>
        </Typography>

        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <Typography component={'div'} className='d-flex-cc flex-sb'>
              <TextField
                className='custom-search'
                placeholder='Search'
                id='outlined-start-adornment'
                value={params.search}
                sx={{ width: '320px' }}
                onChange={(e: any) =>
                  setParams((prev: any) => ({
                    ...prev,
                    search: e.target.value,
                    skip: 0,
                    take: 50,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img src={SearchIcon} alt='search' />
                    </InputAdornment>
                  ),
                  endAdornment: params.search ? (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={() => {
                          setParams(() => ({ ...Defaultparam, search: '' }));
                        }}>
                        <CloseIcon style={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Typography>
            <Typography
              component={'div'}
              className='mt-32'
              sx={{ position: 'relative', width: '100%' }}>
              <TableContainer component={Paper} className='sb-table2'>
                <Table
                  sx={{ overflowX: 'auto', minWidth: 650 }}
                  stickyHeader
                  className='custom-table custom-table-client'
                  aria-label='sticky Header'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Client Name
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('clientName', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('clientName', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Agreement Start Date{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('agreementStartDate', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('agreementStartDate', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Agreement End Date
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('agreementEndDate', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('agreementEndDate', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Type
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('type', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('type', 'desc')}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commonstate.length ? (
                      commonstate.map((row) => {
                        const ClientName = row?.corporateName ?? '-';
                        const Clientdata =
                          row?.clientsAgreement &&
                          row?.clientsAgreement?.find((e: any) => e.section === 'professionalFee');

                        return (
                          <TableRow key={row.id}>
                            <TableCell sx={{ whiteSpace: 'wrap', maxWidth: '100px' }}>
                              <Typography
                                onClick={() =>
                                  gotoNavigate(
                                    `${AdminPrivateRoutes.CLIENTAGREEMENT.CLIENTAGREEMENTVIEW}?id=${row?.id}`,
                                  )
                                }
                                sx={{ fontSize: '12px', color: '#5F2EE5', cursor: 'pointer' }}
                                component={'p'}>
                                {ClientName}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'wrap', maxWidth: '100px' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {moment(Clientdata?.agreementStartDate).format('DD-MMM-YYYY')}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', maxWidth: '100px' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {moment(Clientdata?.agreementEndDate).format('DD-MMM-YYYY')}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', maxWidth: '100px' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {Clientdata?.projectType}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={14} align={'center'}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginBottom: '43px',
                            }}>
                            <NCAdedIcon />
                            <Typography className='no-client'>Agreements not found</Typography>
                          </div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
