import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { getAllListData } from 'services/CommonServices';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { AccessDenied } from 'utils/helper';

// Custom tooltip for the BarChart
const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { name, pending, accepted, rejected } = payload[0].payload; // Get data from payload

    return (
      <div
        style={{
          backgroundColor: 'white', // White background
          padding: '10px',
          borderRadius: '5px',
          color: 'black', // Black text
          fontSize: '14px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
        }}>
        <p style={{ margin: 0 }}>
          <strong>{name}</strong>
        </p>
        <p style={{ margin: '5px 0' }}>
          Pending: <strong>{pending}</strong>
        </p>
        <p style={{ margin: '5px 0' }}>
          Accepted: <strong>{accepted}</strong>
        </p>
        <p style={{ margin: '5px 0' }}>
          Rejected: <strong>{rejected}</strong>
        </p>
      </div>
    );
  }
  return null;
};

// CustomLegend component for the chart legend
const CustomLegend = ({ payload, data }: any) => {
  const mergedArray = data.map(
    (item: { [s: string]: unknown } | ArrayLike<unknown>, index: string | number) => {
      const [status, count] = Object.entries(item)[0];
      return {
        ...payload[index],
        [status.toLowerCase()]: count,
      };
    },
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 20 }}>
      {mergedArray.map((entry: any, index: any) => (
        <div
          key={`item-${index}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 20,
            borderRadius: '5px',
            padding: '10px',
            backgroundColor: '#F2F2F7',
          }}>
          <div
            style={{
              width: 12,
              height: 12,
              backgroundColor: entry.color,
              marginRight: 5,
              borderRadius: '20%',
              marginBottom: '30px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <span style={{ color: '#000', marginRight: 5 }}>{entry.value}</span>
            <span
              style={{
                color: '#EE4B22',
                marginRight: 5,
                display: 'flex',
                justifyContent: 'center',
              }}>
              {entry.total}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const CustomLabel = (props: any) => {
  const { x, y, width, height, value } = props;
  if (value > 0) {
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill='#fff'
        fontSize='14px'
        fontWeight='600'
        textAnchor='middle'
        alignmentBaseline='middle'>
        {value}
      </text>
    );
  }
  return null;
};
const OfferData = ({ setLd, option }: any) => {
  const Today = new Date();
  const oneWeekBefore = new Date(Today);
  oneWeekBefore.setDate(Today.getDate() - 6);
  const navigate = useNavigate();
  const [offerData, setOfferData] = useState<any>([]);
  const [totalCount, settotalCount] = useState<any>([
    {
      total: '',
    },
    { total: '' },
    { total: '' },
  ]);

  const OfferData = () => {
    setLd(true);
    const oneWeekBefore = new Date(Today);
    oneWeekBefore.setDate(Today.getDate() - 6);
    getAllListData(
      `${DASHBOARD_ENDPOINT.Offers}?type=${option.toLowerCase()}&fromDate=${moment(
        oneWeekBefore,
      ).format('YYYY-MM-DD')}&toDate=${moment(Today).format('YYYY-MM-DD')}`,
      true,
    )
      .then((val: any) => {
        const options: any = [];
        val.data.map((e: any) => {
          options.push({
            name: moment(e.date).format('DD/MMM'),
            pending: Number(e.offerPending),
            accepted: Number(e.offerAccepted),
            rejected: Number(e.offerRejected),
          });
        });
        setOfferData(options);
        setLd(false);
        settotalCount([
          {
            total: val.total[0].offerPending,
          },
          { total: val.total[0].offerAccepted },
          { total: val.total[0].offerRejected },
        ]);
      })
      .catch((err) => {
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(false);
      });
  };

  React.useEffect(() => {
    OfferData();
  }, [option]);

  return (
    <div className='childcon2'>
      <header>
        <div className='title'>Offers</div>
      </header>
      <main style={{ marginTop: '50px' }}>
        <div
          className='donutchartout'
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '50px',
            height: '300px',
          }}>
          <ResponsiveContainer width='100%' height={400}>
            <BarChart
              data={offerData}
              barSize={50}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis axisLine={false} domain={[0, 'dataMax']} allowDecimals={false} />
              <Tooltip content={<CustomTooltip />} />
              <Legend content={<CustomLegend data={totalCount} />} />

              {/* Bar for Pending Offers */}
              <Bar dataKey='pending' stackId='a' fill='#4A90E2' name='Offer Pending'>
                <LabelList dataKey='pending' content={<CustomLabel />} />
              </Bar>

              {/* Bar for Accepted Offers */}
              <Bar dataKey='accepted' stackId='a' fill='#7ED321' name='Offer Accepted'>
                <LabelList dataKey='accepted' content={<CustomLabel />} />
              </Bar>

              {/* Bar for Rejected Offers */}
              <Bar dataKey='rejected' stackId='a' fill='#F5A623' name='Offer Rejected'>
                <LabelList dataKey='rejected' content={<CustomLabel />} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </main>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={'p'}
          className='custom-field'
          style={{
            marginTop: '120px',
            border: '1px solid #E4E7EC',
            borderRadius: '0px 0px 8px 8px',
            padding: '8px',
            backgroundColor: '#F2F4F7',
            fontSize: '14px',
            fontFamily: 'Poppins-Regular !important',
          }}>
          Offers for the Period{' '}
          <Typography
            component={'span'}
            style={{ fontSize: '14px', fontFamily: 'Poppins-Regular !important' }}>
            {` ${moment(oneWeekBefore).format('DD-MMM-YYYY')} to ${moment(Today).format(
              'DD-MMM-YYYY',
            )}`}
          </Typography>
        </Typography>
      </Grid>
    </div>
  );
};

export default OfferData;
