/* eslint-disable quotes */
import { useEffect, useState } from 'react';
import BackImg from 'assets/png/interior.png';
import CTLogo from 'assets/png/cthiring_new_logo.png';
import { Button, Grid, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createpasswordSchema } from 'utils/ValidatorSchema';
import { getSingleData, updateData } from 'services/CommonServices';
import CustomInput from 'components/CustomInput/customInput';
import Loader from 'components/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import Storage from 'utils/Storage';
import _ from 'lodash';

const RESETPASSWORD = () => {
  const [Ld, setLd] = useState(false);
  const ipAddress: any = Storage.getIp();
  const [email, setemail] = useState<string>('');
  const Searchparamas = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const locationPath = useLocation();
  const passwordState = Searchparamas.get('state');
  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
    watch,
  } = useForm<{
    password: any;
    confirmPassword: any;
  }>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(createpasswordSchema),
  });

  useEffect(() => {
    if (ipAddress && !locationPath?.state?.isExpired) {
      setLd(!true);
      getSingleData('', `password/validate?hash=${Searchparamas.get('hash')}`, true)
        .then((ed: any) => {
          setLd(true);
          setemail(ed.data.email);
          setLd(!true);
        })
        .catch(() => {
          setLd(!true);
          navigate('/login');
        });
      // } else {
      //   navigate('/login');
    }
  }, []);
  const onSubmitForm = async (data: any) => {
    if (watch('password') !== watch('confirmPassword')) {
      toast.error(`Passwords don't match`, alertOptions);
      return;
    }
    if (!/[a-z]+/.test(watch('password'))) {
      toast.error('Password must contain atleast one lower case letter', alertOptions);
      return;
    }
    if (Searchparamas.get('hash') && email && email.length > 0) {
      try {
        setLd(true);
        await updateData('', { ...data, email: email,hash:Searchparamas.get('hash') }, 'password/set-password', true);
        navigate('/login');
      } catch (error) {
        // console.log(error);
      } finally {
        setLd(false);
        resetForm();
      }
    } else if (!_.isEmpty(Searchparamas.get('email')) && locationPath?.state?.isExpired) {
      setLd(true);
      await updateData(
        '',
        {
          email: locationPath.state.email,
          currentPassword: locationPath.state.password,
          newPassword: data.password,
          hash: Searchparamas.get('hash'),
          confirmPassword: data.confirmPassword,
        },
        'password/expiry',
        true,
      )
        .then(() => {
          navigate('/login');
          setLd(false);
        })
        .catch(() => {
          setLd(false);
        });
    }
  };
  return (
    <>
      <Typography
        component={'div'}
        sx={{ backgroundImage: `url(${BackImg})` }}
        className='login-banner'>
        <Grid container className='px-40'>
          <Grid item md={6} sm={12} xs={12} className=''>
            <Typography component={'div'} className='pl-15'>
              <Typography className='mb-36 mt-5r'>
                <img src={CTLogo} alt='Carrier Tree' />
              </Typography>
              <Typography className='banner-text'>
                We have the <span>capabilities</span> and <span>experience</span> to navigate you in
                <span>finding solutions</span> you need to move forward
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography className='for-dis ml-60 mt-8'>
              <span style={{ color: 'red' }}>*</span>Please Enable the Location to Login the
              application.
            </Typography>
            <Typography component={'div'} className='login-form d-flex-ja'>
              <Typography component={'div'} className='login-box for-log-box'>
                <Typography className='mb-36'>
                  <img src={CTLogo} alt='Carrier Tree' style={{ width: '142px', height: '22px' }} />
                </Typography>
                <Typography className='otp-text'>
                  {locationPath?.state?.isExpired
                    ? 'Change Password'
                    : passwordState === 'reset-password'
                      ? 'Reset Password'
                      : 'Create Password'}
                </Typography>
                <form id={'branch-add'} onSubmit={handleSubmit(onSubmitForm)}>
                  <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              New Password <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            {/* <TextField
                            className='custom-input'
                            id='standard-basic'
                            placeholder='**********'
                            type='password'
                            variant='standard'
                          /> */}
                            <Controller
                              control={control}
                              name='password'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    id='standard-basic'
                                    placeHolder='Enter new password'
                                    type={'password'}
                                    isSecureTextEntry={true}
                                    error={errors.password?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Confirm Password <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            {/* <TextField
                            className='custom-input'
                            id='standard-basic'
                            placeholder='*********'
                            type='password'
                            variant='standard'
                          /> */}
                            <Controller
                              control={control}
                              name='confirmPassword'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    id='standard-basic'
                                    placeHolder='Enter confirm password'
                                    type={'password'}
                                    isSecureTextEntry={true}
                                    error={errors.confirmPassword?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='pass-title'>
                            Password must contain <span style={{ color: 'red' }}>*</span>
                          </Typography>
                          <Typography component={'div'} className='d-flex-a pass-info'>
                            {/[a-z]+/.test(watch('password')) ? (
                              <DoneIcon className='tick' />
                            ) : (
                              <CloseIcon className='wicon' />
                            )}{' '}
                            Lower Case
                          </Typography>
                          <Typography component={'div'} className='d-flex-a pass-info'>
                            {/[A-Z]+/.test(watch('password')) ? (
                              <DoneIcon className='tick' />
                            ) : (
                              <CloseIcon className='wicon' />
                            )}{' '}
                            Upper Case
                          </Typography>
                          <Typography component={'div'} className='d-flex-a pass-info'>
                            {/[0-9]+/.test(watch('password')) ? (
                              <DoneIcon className='tick' />
                            ) : (
                              <CloseIcon className='wicon' />
                            )}{' '}
                            Number
                          </Typography>
                          <Typography component={'div'} className='d-flex-a pass-info'>
                            {/[*@!$#%&()^~|{}]+/.test(watch('password')) ? (
                              <DoneIcon className='tick' />
                            ) : (
                              <CloseIcon className='wicon' />
                            )}{' '}
                            Special characters
                          </Typography>
                          <Typography component={'div'} className='d-flex-a pass-info'>
                            {watch('password').length >= 8 ? (
                              <DoneIcon className='tick' />
                            ) : (
                              <CloseIcon className='wicon' />
                            )}{' '}
                            Min. 8 character length
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography className='d-flex-ja' flexDirection='column'>
                    <Button
                      className='submit-btn'
                      sx={{ marginTop: '20px !important', width: '100% !important' }}
                      form={'branch-add'}
                      type='submit'>
                      Submit
                    </Button>
                  </Typography>
                </form>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Typography>
      {Ld && <Loader />}
    </>
  );
};

export default RESETPASSWORD;
