import React from 'react';
import Donutreactchart from './icon/donutreactchart';
import TagsAnnotator from './icon/tagsAnnotator';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Grid, Typography } from '@mui/material';

export const ResumeData = ({ resumeData }: any) => {
  const Today = new Date();
  const oneWeekBefore = new Date(Today);
  oneWeekBefore.setDate(Today.getDate() - 6);
  const navigate = useNavigate();
  const ResumeFinalData = resumeData && resumeData.length > 0;
  const defaultData = [
    {
      name: 'CV Sent',
      color: '#3B82F6',
    },
    {
      name: 'CV Shortlisted',
      color: '#14B8A6',
    },
    {
      name: 'CV Rejected',
      color: '#F59E0B',
    },
    {
      name: 'CV Feedback Pending',
      color: '#FACC15',
    },
  ];
  return (
    <div className='childcon2'>
      <header>
        <div className='title d-flex'>Resumes</div>
        <div className='viewdetail' onClick={() => navigate('/resumes')}>
          View Details
        </div>
      </header>
      {!ResumeFinalData ? (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '80px',
            fontSize: '16px',
            fontWeight: '600',
          }}>
          No data available
        </p> // Placeholder message
      ) : (
        <>
          <main>
            <div className='donutchartout'>
              <Donutreactchart resumeData={resumeData} interview={false} />
            </div>
            <div className='labelcon'>
              {defaultData.map((er: any, idx: number) => {
                const val = resumeData.filter((e: any) => e.name === er.name);
                return (
                  <div className='labelItem' key={idx}>
                    <TagsAnnotator txt={er.name} bg={er.color} value={val[0]?.value} />
                  </div>
                );
              })}
            </div>
          </main>
          <Grid item md={12} sm={12} xs={12} className='mt-15'>
            <Typography
              component={'p'}
              className='custom-field'
              style={{
                border: '1px solid #E4E7EC',
                borderRadius: '0px 0px 8px 8px',
                padding: '8px',
                fontSize: '14px',
                fontFamily: 'Poppins-Regular !important',
                backgroundColor: '#F2F4F7',
              }}>
              Resumes for the Period{' '}
              <Typography
                component={'span'}
                style={{ fontSize: '14px', fontFamily: 'Poppins-Regular !important' }}>
                {`${moment(oneWeekBefore).format('DD-MMM-YYYY')} to ${moment(Today).format(
                  'DD-MMM-YYYY',
                )} `}
              </Typography>
            </Typography>
          </Grid>
        </>
      )}
    </div>
  );
};
