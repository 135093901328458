import {
  Badge,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import { useDebounce } from 'hooks';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import CustomSelect from 'components/CustomSelect/customSelect';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import { CommomFilter } from './CommomFilter';
import moment from 'moment';

export const BusinessConversion = ({ setLd, option }: any) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const oneMonth = moment().clone().subtract(1, 'months').format('YYYY-MM-DD');
  const Defaultparam = {
    search: null,
    skip: 0,
    take: 10,
    from: oneMonth,
    to: currentDate,
    type: null,
    dateChange: false,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visible, setVisible] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const filterClose = () => setFilterOpen(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const listdataapi = () => {
    const searcharr = [
      `type=${option.toLowerCase()}`,
      `${debouncedParams.from ? `fromDate=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `toDate=${debouncedParams.to}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);

    getAllListData(
      `${DASHBOARD_ENDPOINT.BusinessConversion}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        setcommonstate([...val.data]);
        setLd(!true);
        filterClose();
      })
      .catch(() => {
        setLd(!true);
      });
  };
  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);

  const FilterChange = (value: number) => {
    const currentDate = moment(); // Get current date using Moment.js
    let fromDate: moment.Moment | null = null;
    let toDate: moment.Moment = currentDate; // 'toDate' is the current date

    switch (value) {
      case 1: // 1 Day
        fromDate = currentDate.clone().subtract(1, 'days');
        break;
      case 2: // 1 Week
        fromDate = currentDate.clone().subtract(1, 'weeks');
        break;
      case 3: // 1 Month
        fromDate = currentDate.clone().subtract(1, 'months');
        break;
      case 4: // 3 Month
        fromDate = currentDate.clone().subtract(3, 'months');
        break;
      case 5: // 6 Month
        fromDate = currentDate.clone().subtract(6, 'months');
        break;
      case 6: // 1 Year
        fromDate = currentDate.clone().subtract(1, 'years');
        break;
      default:
        fromDate = currentDate.clone().subtract(1, 'months');
        toDate = currentDate;
        break;
    }

    // Format dates to 'yyyy-mm-dd' using Moment.js format method
    const formattedFromDate = fromDate ? fromDate.format('YYYY-MM-DD') : null;
    const formattedToDate = toDate.format('YYYY-MM-DD');

    // Update the parameters
    setParams((prev: any) => ({
      ...prev,
      from: formattedFromDate,
      to: formattedToDate,
    }));
  };
  return (
    <Grid item md={6} sm={12} xs={12}>
      <div className='productivitycards'>
        <Typography component={'div'} className='d-flex-a flex-sb'>
          <Typography
            className='title'
            sx={{ display: 'flex', fontSize: '20px', fontWeight: '600' }}>
            {'Bussiness Conversion'}
            <div
              style={{
                marginLeft: '10px',
                fontFamily: 'Poppins medium !important',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '13px',
                color: '#808080',
                display: 'flex',
                alignItems: 'center',
              }}>
              individual
            </div>
          </Typography>
          <Typography className='d-flex'>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '150px', marginRight: '10px' }}>
              <CustomSelect
                labelKey={'label'}
                valueKey={'value'}
                options={[
                  { label: '1 Day', value: 1 },
                  { label: '1 Week', value: 2 },
                  { label: '1 Month', value: 3 },
                  { label: '3 Month', value: 4 },
                  { label: '6 Month', value: 5 },
                  { label: '1 Year', value: 6 },
                ]}
                onChange={(e) => {
                  FilterChange(e.target.value);
                }}
                placeHolder='Select'
              />
            </Typography>
            <Typography
              component={'div'}
              sx={{ marginRight: '10px' }}
              className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
              onClick={() => setFilterOpen(true)}>
              <Badge color='error' variant='dot' invisible={visible}>
                <img src={Funnelimg} alt='filter funnel icon' />
              </Badge>
            </Typography>
          </Typography>
        </Typography>
        <div className='borderdivider' style={{ marginTop: '20px' }}></div>
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            component={'p'}
            className='custom-field'
            style={{
              border: '1px solid #E4E7EC',
              borderRadius: '0px 0px 8px 8px',
              padding: '8px',
              backgroundColor: '#F2F4F7',
              fontSize: '14px',
              fontFamily: 'Poppins-Regular !important',
            }}>
            Business Conversion for the Period{' '}
            <Typography
              component={'span'}
              style={{ fontSize: '14px', fontFamily: 'Poppins-Regular !important' }}>
              {params.from
                ? `${moment(params.from).format('DD-MMM-YYYY')} to ${moment(params.to).format('DD-MMM-YYYY')}`
                : '-'}
            </Typography>
          </Typography>
        </Grid>
        <TableContainer
          component={Paper}
          className='sb-table2 mt-1'
          sx={{ width: '100% !important', maxHeight: '400px', height: '100%', display: 'flex' }}>
          <Table
            stickyHeader
            className='custom-table custom-table-client'
            aria-label='sticky Header'>
            <TableHead>
              <TableRow
                sx={{
                  marginLeft: '50px',
                  marginRight: '43px',
                  // width: 'calc(100% - 93px)',
                }}>
                <TableCell align='right' sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  Opening Related
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  {''}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commonstate && commonstate.length > 0 ? (
                <>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'Position Worked'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].positionWorked ? commonstate[0].positionWorked : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'Openings Handled'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].openingHandled ? commonstate[0].openingHandled : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'Openings Billed'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].openingBilled ? commonstate[0].openingBilled : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'Openings Not Billed'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].openingNotBilled ? commonstate[0].openingNotBilled : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'Billable Value'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].businessValue
                        ? new Intl.NumberFormat('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                            minimumFractionDigits: 2,
                          })
                            .format(commonstate[0].businessValue)
                            .replace('₹', '')
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'Billing Value'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].billingValue
                        ? new Intl.NumberFormat('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                            minimumFractionDigits: 2,
                          })
                            .format(commonstate[0].billingValue)
                            .replace('₹', '')
                        : '-'}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <tr>
                  <td colSpan={5} align={'center'}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '60px',
                      }}>
                      <NCAdedIcon />
                      <Typography className='no-client'>No Data</Typography>
                    </div>
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
          <Table
            sx={{ marginLeft: '10px' }}
            stickyHeader
            className='custom-table custom-table-client'
            aria-label='sticky Header'>
            <TableHead>
              <TableRow
                sx={{
                  marginLeft: '50px',
                  marginRight: '43px',
                  // width: 'calc(100% - 93px)',
                }}>
                <TableCell align='right' sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  CV Quality & Contribution
                </TableCell>{' '}
                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  {''}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commonstate && commonstate.length > 0 ? (
                <>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'CV Sent'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].cvSent ? commonstate[0].cvSent : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'Average Lead Time (Days)'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].avgTaktTime ? commonstate[0].avgTaktTime : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'CVs Billed'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].openingBilled ? commonstate[0].openingBilled : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'CVs Not Billed'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].openingNotBilled ? commonstate[0].openingNotBilled : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      // verticalAlign: 'baseline',
                      marginLeft: '50px',
                      marginRight: '43px',
                      width: 'calc(100% - 93px)',
                    }}>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {'% of Final Interview Candidate'}
                    </TableCell>
                    <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        minWidth: '100px',
                      }}>
                      {commonstate[0].cvSentInterviewedPercentage
                        ? commonstate[0].cvSentInterviewedPercentage
                        : '-'}{' '}
                      %
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <tr>
                  <td colSpan={5} align={'center'}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '60px',
                      }}>
                      <NCAdedIcon />
                      <Typography className='no-client'>No Data</Typography>
                    </div>
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {filterOpen && (
        <CommomFilter
          handleOpen={filterOpen}
          handleClose={filterClose}
          setParams={setParams}
          params={params}
          setVisible={setVisible}
          getApi={listdataapi}
          type='businessConversion'
        />
      )}
    </Grid>
  );
};
