/* eslint-disable @typescript-eslint/no-unused-vars */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  styled,
  Tab,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import payment from 'assets/png/payment.svg';
import repayment from 'assets/png/repayment.svg';
import professional from 'assets/png/professional.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { clientTermsCondition } from 'utils/ValidatorSchema';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import { fileTypewordPDF } from 'utils/helper';
import { ProfessionalFee } from './professionalFee';
import { PaymentTC } from './paymentTC';
import { ReplacementClause } from './replacementClause';
import CloseIcon from '@mui/icons-material/Close';
import Loader from 'components/loader';
import ClientSuccessIcon from 'assets/png/clientsuccessicon.png';
import { addNewData, getSingleData } from 'services/CommonServices';
import { ClientsENDPOINT } from 'utils/Constant';
import { useNavigate, useSearchParams } from 'react-router-dom';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const termsAndCondition = ({ formview, setFormfinish, tabValue, setTabValue }: any) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    // trigger,
    // setError,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      projectType: '',
      proposalSubmitted: '',
      agreementStartDate: '',
      agreementEndDate: '',
      acrossAllLevel: false,
      allLevelPercentage: '',
      acrossAllLevelId: '',
      professionalId: '',
      paymentId: '',
      replaceClauseId: '',
      clientDesignations: [
        {
          levelWise: false,
          levelWiseFrom: '',
          levelWiseTo: '',
          levelWisePercentage: '',
          id: '',
        },
      ],
      ClientDesignationCTC: [
        {
          ctcWise: false,
          ctcWiseFrom: '',
          ctcWiseTo: '',
          ctcWisePercentage: '',
          id: '',
        },
      ],
      replaceAcrossAllLevel: false,
      replaceAllLevelPercentage: '',
      replaceAcrossAllLevelId: '',
      replaceClientDesignations: [
        {
          levelWise: false,
          levelWiseFrom: '',
          levelWiseTo: '',
          levelWisePercentage: '',
          id: '',
        },
      ],
      replaceClientDesignationCTC: [
        {
          ctcWise: false,
          ctcWiseFrom: '',
          ctcWiseTo: '',
          ctcWisePercentage: '',
          id: '',
        },
      ],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(clientTermsCondition),
  });
  const [searchParams]: any = useSearchParams();
  const [Ld, setLd] = useState(false);
  const [open, setOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [removeProfessionalDesignationIds, setRemoveProfessionalDesignationIds] = useState<any>([]);
  const [removeReplacementDesignationIds, setRemoveReplacementDesignationIds] = useState<any>([]);
  const [fileUpload, setfileupload] = useState<any>();
  const hiddenFileInput: any = useRef(null);
  const navigate = useNavigate();
  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setTabValue(newValue);
  };

  const onFileChange = (file: FileList) => {
    if (file[0] && file[0].size) {
      if (fileTypewordPDF.includes(file[0].type)) {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 10240) {
          toast.error('File is too big, Please select a file less than 10MB', alertOptions);
          return;
        }
      } else {
        toast.error('You can upload documents of types  DOCX, DOC and PDF only', alertOptions);
        return;
      }
    }
    setfileupload(file);
    hiddenFileInput.current.value = '';
  };
  const handledelete = () => {
    // setRemoveId(resumeFileId ? resumeFileId : '');
    setfileupload('');
    // hiddenFileInput.current.value = '';
  };
  const handleClose = () => {
    setOpen(false);
    navigate('/clients');
  };
  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);
  const removeData = (ids: any, tab: any) => {
    ids.forEach((id: string) => {
      if (tab === 'professionalFee') {
        if (!removeProfessionalDesignationIds.includes(id)) {
          setRemoveProfessionalDesignationIds((pre: any) => [...pre, id]);
        }
      } else if (tab === 'replacement') {
        if (!removeReplacementDesignationIds.includes(id)) {
          setRemoveReplacementDesignationIds((pre: any) => [...pre, id]);
        }
      }
    });
  };

  const removeExistingDesignation = (tab: any) => {
    if (tab === 'professionalFee') {
      if (watch('acrossAllLevelId')) {
        removeData([watch('acrossAllLevelId')], 'professionalFee');
      }

      const filterLevel = watch('clientDesignations').filter((e: any) => e.id);
      if (filterLevel.length) {
        removeData(
          filterLevel.map((e: any) => e.id),
          'professionalFee',
        );
      }

      const filterCtc = watch('ClientDesignationCTC').filter((e: any) => e.id);
      if (filterCtc.length) {
        removeData(
          filterCtc.map((e: any) => e.id),
          'professionalFee',
        );
      }

      setValue('acrossAllLevel', false);
      setValue('allLevelPercentage', '');
      setValue('acrossAllLevelId', '');
      setValue('clientDesignations', [
        {
          levelWise: false,
          levelWiseFrom: '',
          levelWiseTo: '',
          levelWisePercentage: '',
          id: '',
        },
      ]);
      setValue('ClientDesignationCTC', [
        {
          ctcWise: false,
          ctcWiseFrom: '',
          ctcWiseTo: '',
          ctcWisePercentage: '',
          id: '',
        },
      ]);
    } else if (tab === 'replacement') {
      if (watch('replaceAcrossAllLevelId')) {
        removeData([watch('replaceAcrossAllLevelId')], 'replacement');
      }

      const filterLevel = watch('replaceClientDesignations').filter((e: any) => e.id);
      if (filterLevel.length) {
        removeData(
          filterLevel.map((e: any) => e.id),
          'replacement',
        );
      }

      const filterCtc = watch('replaceClientDesignationCTC').filter((e: any) => e.id);
      if (filterCtc.length) {
        removeData(
          filterCtc.map((e: any) => e.id),
          'replacement',
        );
      }

      setValue('replaceAcrossAllLevel', false);
      setValue('replaceAllLevelPercentage', '');
      setValue('replaceAcrossAllLevelId', '');
      setValue('replaceClientDesignations', [
        {
          levelWise: false,
          levelWiseFrom: '',
          levelWiseTo: '',
          levelWisePercentage: '',
          id: '',
        },
      ]);
      setValue('replaceClientDesignationCTC', [
        {
          ctcWise: false,
          ctcWiseFrom: '',
          ctcWiseTo: '',
          ctcWisePercentage: '',
          id: '',
        },
      ]);
    }
  };

  function onSubmitForm(data: any) {
    if (!fileUpload) {
      setLd(false);
      toast.error('File is required', {
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
      });
      return;
    }
    if (
      (watch(`ClientDesignationCTC.${0}.ctcWise`) ||
        watch(`clientDesignations.${0}.levelWise`) ||
        watch('acrossAllLevel')) &&
      (watch(`replaceClientDesignationCTC.${0}.ctcWise`) ||
        watch(`replaceClientDesignations.${0}.levelWise`) ||
        watch('replaceAcrossAllLevel'))
    ) {
      const postData: any[] = [];
      const payment = {
        section: 'paymentTC',
        paymentDays: data.proposalSubmitted,
        ...(data.paymentId ? { id: data.paymentId } : {}),
      };
      const datass = [
        ...(data.acrossAllLevel === true ? [data] : []),
        ...data.clientDesignations.filter((e: any) => e.levelWise === true),
        ...data.ClientDesignationCTC.filter((e: any) => e.ctcWise === true),
      ];

      const clientDesignationss: any = datass.map((e: any, i: number) => {
        return {
          section: 'professionalFee',
          type: data?.acrossAllLevel
            ? 'acrossAllLevel'
            : e?.levelWise
              ? 'levelWise'
              : e?.ctcWise
                ? 'ctcWise'
                : '',
          percentageId: data?.acrossAllLevel
            ? data?.allLevelPercentage
            : e?.levelWise
              ? e.levelWisePercentage
              : e?.ctcWise
                ? e?.ctcWisePercentage
                : '',
          ...(data.allLevelPercentage
            ? {}
            : e?.levelWise
              ? { levelFromId: e?.levelWiseFrom, levelToId: e?.levelWiseTo }
              : e?.ctcWise
                ? { ctcFromId: e?.ctcWiseFrom, ctcToId: e?.ctcWiseTo }
                : {}),
          ...(e.id ? { id: e.id } : e.acrossAllLevelId ? { id: e.acrossAllLevelId } : {}),
        };
      });
      const datassOne = [
        ...(data.replaceAcrossAllLevel === true ? [data] : []),
        ...data.replaceClientDesignations.filter((e: any) => e.levelWise === true),
        ...data.replaceClientDesignationCTC.filter((e: any) => e.ctcWise === true),
      ];

      const clientDesignationsReplacement: any = datassOne.map((e: any, i: number) => {
        return {
          section: 'replacementClause',
          type: data?.replaceAcrossAllLevel
            ? 'acrossAllLevel'
            : e?.levelWise
              ? 'levelWise'
              : e?.ctcWise
                ? 'ctcWise'
                : '',
          noOfDaysId: data?.replaceAcrossAllLevel
            ? data?.replaceAllLevelPercentage
            : e?.levelWise
              ? e.levelWisePercentage
              : e?.ctcWise
                ? e?.ctcWisePercentage
                : '',
          ...(data.replaceAllLevelPercentage
            ? {}
            : e?.levelWise
              ? { levelFromId: e?.levelWiseFrom, levelToId: e?.levelWiseTo }
              : e?.ctcWise
                ? { ctcFromId: e?.ctcWiseFrom, ctcToId: e?.ctcWiseTo }
                : {}),
          ...(e.id ? { id: e.id } : e.replaceAcrossAllLevelId ? { id: e.replaceAcrossAllLevelId } : {}),
        };
      });
      postData.push(
        {
          section: 'professionalFee',
          projectType: data.projectType,
          agreementStartDate: data.agreementStartDate,
          agreementEndDate: data.agreementEndDate,
          clientDesignations: JSON.stringify(clientDesignationss),
          removeClientDesignationIds: JSON.stringify(removeProfessionalDesignationIds),
          ...(data.professionalId ? { id: data.professionalId } : {}),
        },
        payment,
        {
          section: 'replacementClause',
          clientDesignations: JSON.stringify(clientDesignationsReplacement),
          removeClientDesignationIds: JSON.stringify(removeReplacementDesignationIds),
          ...(data.replaceClauseId ? { id: data.replaceClauseId } : {}),
        },
      );

      const formdata: any = new FormData();
      formdata.append('clientTab', 'agreementTC');
      formdata.append('clientId', searchParams.get('clientid') || formview.clientId);
      formdata.append('clientAgreements', JSON.stringify(postData));
      if (fileUpload) {
        formdata.append('file', fileUpload ? fileUpload[0] : '');
      }
      // formdata.append('editReason', '');
      setLd(true);
      addNewData(
        formdata,
        `${ClientsENDPOINT.Client}`,
        {
          'Content-Type': 'multipart/form-data',
        },
        true,
      )
        .then((res) => {
          setFormfinish((prev: any) => ({
            ...prev,
            crm: true,
            spoc: true,
            clientdetail: true,
            termsCondition: false,
            businessDetail: false,
          }));
          if (searchParams.get('mode') != 'edit') {
            setOpen(true);
          } else {
            navigate('/clients');
          }
          setLd(false);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(e);
            navigate('/dashboard');
          }
          setLd(false);
          console.log(e);
        });
    } else {
      toast.error('Designation is required', {
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
      });
    }
  }
  const getInitialStatus = async () => {
    if (searchParams.get('clientid') || formview.clientId) {
      setLd(true);
      await getSingleData(searchParams.get('clientid') || formview.clientId, ClientsENDPOINT.Client)
        .then((resp: any) => {
          setClientName(resp && resp?.data && resp?.data[0]?.corporateName);
          if (resp && resp.data && resp.data[0]?.clientsAgreement.length) {
            // profesonal fee tab //
            const professionalFee = resp.data[0]?.clientsAgreement.find(
              (e: any) => e.section === 'professionalFee',
            );
            const designation = professionalFee.designations.find(
              (re: any) => re.type === 'acrossAllLevel',
            );
            setFormfinish((prev: any) => ({
              ...prev,
              crm: resp?.data[0]?.clientsCrm.length ? true : false,
              spoc: resp?.data[0]?.clientsSpoc.length ? true : false,
              clientdetail: resp?.data[0]?.corporateName ? true : false,
              termsCondition: resp?.data[0]?.clientsAgreement.length ? true : false,
              businessDetail: resp?.data[0]?.clientsBusinessInfo.length ? true : false,
            }));
            setValue('projectType', professionalFee.projectType);
            setValue('agreementEndDate', professionalFee.agreementEndDate);
            setValue('agreementStartDate', professionalFee.agreementStartDate);
            setValue('professionalId', professionalFee.id);
            setfileupload([professionalFee.file]);
            professionalFee.designations.forEach((re: any, i: number) => {
              if (re.type === 'acrossAllLevel') {
                setValue('acrossAllLevel', true);
                setValue('acrossAllLevelId', re.id);
                setValue('allLevelPercentage', re?.percentageId);
              } else if (re.type === 'levelWise') {
                setValue(`clientDesignations.${i}.levelWise`, true);
                setValue(`clientDesignations.${i}.levelWiseFrom`, re?.levelFromId);
                setValue(`clientDesignations.${i}.levelWiseTo`, re?.levelToId);
                setValue(`clientDesignations.${i}.levelWisePercentage`, re?.percentageId);
                setValue(`clientDesignations.${i}.id`, re.id);
              } else if (re.type === 'ctcWise') {
                setValue(`ClientDesignationCTC.${i}.ctcWise`, true);
                setValue(`ClientDesignationCTC.${i}.ctcWiseFrom`, re?.ctcFromId);
                setValue(`ClientDesignationCTC.${i}.ctcWiseTo`, re?.ctcToId);
                setValue(`ClientDesignationCTC.${i}.ctcWisePercentage`, re?.percentageId);
                setValue(`ClientDesignationCTC.${i}.id`, re.id);
              }
            });

            // payment tab //
            const payment = resp.data[0]?.clientsAgreement.find(
              (e: any) => e.section === 'paymentTC',
            );
            setValue('proposalSubmitted', payment.paymentDays);
            setValue('paymentId', payment.id);
            // Replacement clause //
            const replaceMent = resp.data[0]?.clientsAgreement.find(
              (e: any) => e.section === 'replacementClause',
            );

            setValue('replaceClauseId', replaceMent.id);
            replaceMent.designations.forEach((re: any, i: number) => {
              if (re.type === 'acrossAllLevel') {
                setValue('replaceAcrossAllLevel', true);
                setValue('replaceAllLevelPercentage', re?.noOfDaysId);
                setValue('replaceAcrossAllLevelId', re.id);
              } else if (re.type === 'levelWise') {
                setValue(`replaceClientDesignations.${i}.levelWise`, true);
                setValue(`replaceClientDesignations.${i}.levelWiseFrom`, re?.levelFromId);
                setValue(`replaceClientDesignations.${i}.levelWiseTo`, re?.levelToId);
                setValue(`replaceClientDesignations.${i}.levelWisePercentage`, re?.noOfDaysId);
                setValue(`replaceClientDesignations.${i}.id`, re.id);
              } else if (re.type === 'ctcWise') {
                setValue(`replaceClientDesignationCTC.${i}.ctcWise`, true);
                setValue(`replaceClientDesignationCTC.${i}.ctcWiseFrom`, re?.ctcFromId);
                setValue(`replaceClientDesignationCTC.${i}.ctcWiseTo`, re?.ctcToId);
                setValue(`replaceClientDesignationCTC.${i}.ctcWisePercentage`, re?.noOfDaysId);
                setValue(`replaceClientDesignationCTC.${i}.id`, re.id);
              }
            });

            reset(getValues());
          }

          setLd(false);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(e);
            navigate('/dashboard');
          }
          setLd(false);
          console.log(e);
        });
    }
  };
  useEffect(() => {
    getInitialStatus();
  }, []);

  return (
    <Grid item md={12} sm={8} xs={8}>
      {Ld && <Loader />}
      <Typography component={'div'} className='form-box'>
        <Typography component={'div'} className='client-form-photo-area'>
          <Typography component={'p'} className='side-client-text' style={{ fontWeight: 500 }}>
            Agreement T&C
          </Typography>
          <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
            <Box>
              <TabContext value={tabValue}>
                <Typography component={'div'} className='d-flex-a d-flex-sb custom-position-tab'>
                  <Box
                    sx={{
                      borderColor: 'divider',
                      borderRadius: '8px',
                      background: '#F5F6FA',
                      padding: '5px',
                    }}>
                    <TabList onChange={handleChange} aria-label='lab API tabs example'>
                      <Tab
                        sx={{ width: '200px' }}
                        icon={
                          <img
                            src={professional}
                            alt='professional'
                            style={{
                              height: '18px',
                              width: '18px',
                              marginBottom: '0px',
                              marginRight: '10px',
                            }}
                          />
                        }
                        label='Professional fee'
                        value='1'
                      />

                      <Tab
                        sx={{ width: '200px' }}
                        icon={
                          <img
                            src={payment}
                            alt='payment'
                            style={{
                              height: '18px',
                              width: '18px',
                              marginBottom: '0px',
                              marginRight: '10px',
                            }}
                          />
                        }
                        label='Payment T&C'
                        value='2'
                      />

                      <Tab
                        sx={{ width: '250px' }}
                        icon={
                          <img
                            src={repayment}
                            alt='repayment'
                            style={{
                              height: '18px',
                              width: '18px',
                              marginBottom: '0px',
                              marginRight: '10px',
                            }}
                          />
                        }
                        label='Replacement Clause'
                        value='3'
                      />
                    </TabList>
                  </Box>
                </Typography>

                <TabPanel value='1' sx={{ padding: '24px 24px 24px 0px' }}>
                  <ProfessionalFee
                    clientName={clientName}
                    removeExistingDesignation={removeExistingDesignation}
                    removeData={removeData}
                    control={control}
                    errors={errors}
                    onDrop={onDrop}
                    fileUpload={fileUpload}
                    hiddenFileInput={hiddenFileInput}
                    handledelete={handledelete}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    setLd={setLd}
                    handleSubmit={handleSubmit}
                    onSubmitForm={onSubmitForm}
                  />
                </TabPanel>
                <TabPanel value='2'>
                  <PaymentTC
                    control={control}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    onSubmitForm={onSubmitForm}
                  />
                </TabPanel>
                <TabPanel value='3'>
                  <ReplacementClause
                    removeExistingDesignation={removeExistingDesignation}
                    removeData={removeData}
                    control={control}
                    errors={errors}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    setLd={setLd}
                    handleSubmit={handleSubmit}
                    onSubmitForm={onSubmitForm}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Typography>
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box className='logout-modal' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <Typography
            className='d-flex-ja mb-24'
            sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <img
              src={ClientSuccessIcon}
              alt='client success'
              style={{ height: '50%', width: '50%' }}
            />
            <Typography className='client-sucess-con'>
              <Typography className='title' style={{ marginBottom: '9px' }}>
                Client Creation Successful!
              </Typography>
              <Typography className='approval' style={{ marginBottom: '24px' }}>
                Awaiting Approval
              </Typography>
            </Typography>
            <Button className='client-success-modal-btn' onClick={handleClose}>
              Ok
            </Button>
          </Typography>
          <CloseIcon
            onClick={handleClose}
            style={{
              cursor: 'pointer',
              height: '20px',
              width: '20px',
              position: 'absolute',
              top: '16px',
              right: '16px',
            }}
          />
        </Box>
      </Modal>
    </Grid>
  );
};
export default termsAndCondition;
