import React, { useCallback, useRef, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import {
  Box,
  Button,
  Grid,
  Modal,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Loader from 'components/loader';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import { addNewData, getAllListData } from 'services/CommonServices';
import FileSaver from 'file-saver';
import { AccessDenied } from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import Deletebucket from 'assets/png/Deletebucket.svg';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Xlsxpng from 'assets/png/xlsx.png';
import Fileupload from 'assets/png/upload-cloud.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImportFunctionSchema } from 'utils/ValidatorSchema';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import moment from 'moment';
import CustomSelect from 'components/CustomSelect/customSelect';

const ImportFunctionality = ({
  importUpload,
  setImportUpload,
  name,
  header,
  productAdmin,
  getList,
}: any) => {
  const [loader, setLoader] = useState<any>(false);
  const [preview, setPreview] = useState<any>(false);
  const [downloadOption, setDownloadOption] = useState<any>('sample');
  const [tabValue, setTabValue] = useState('1');
  const [data, setData] = useState<any>({ errorData: [], sucessData: [] });
  const navigate = useNavigate();
  const [fileUpload, setfileupload] = useState<any>('');
  const hiddenFileInput: any = useRef(null);
  const { handleSubmit } = useForm({ resolver: yupResolver(ImportFunctionSchema) });
  const handlePreviewClose = () => {
    setTabValue('1');
    setPreview(false);
  };
  function getSampleUrl(name: string): string {
    return productAdmin ? `${name}/download-sample/admin` : `${name}/download-sample`;
  }
  function getDuplicateUrl(name: string): string {
    return productAdmin ? `${name}/check-duplicate/admin` : `${name}/check-duplicate`;
  }
  function getFinalUrl(name: string): string {
    return productAdmin ? `${name}/import/admin` : `${name}/import`;
  }
  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setTabValue(newValue);
  };
  const downloadSample = (value: string) => {
    if (value === 'sample') {
      getAllListData(getSampleUrl(name), true, {
        responseType: 'arraybuffer',
      })
        .then((res: any) => {
          const date = Date.now();
          const data = new Blob([res]);
          FileSaver.saveAs(
            data,
            `${productAdmin ? 'Product_Administrator' : name}_sample_${date}.xlsx`,
          );
          setLoader(false);
        })
        .finally(() => setLoader(false));
    } else {
      getAllListData(`users/download-${value}`, true, {
        responseType: 'arraybuffer',
      })
        .then((res: any) => {
          const date = Date.now();
          const data = new Blob([res]);
          FileSaver.saveAs(data, `${value}_sample_${date}.xlsx`);
          setLoader(false);
        })
        .finally(() => setLoader(false));
    }
  };
  const onSubmit = () => {
    setLoader(true);
    if (!fileUpload) {
      toast.error('File is required', alertOptions);
      setLoader(false);
      return;
    }

    const formdata = new FormData();
    formdata.append('file', fileUpload[0]);

    addNewData(
      formdata,
      getDuplicateUrl(name),
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then((res: any) => {
        if (res) {
          setData({ errorData: res.errors, sucessData: res.success });
          setPreview(true);
          handleClose();
          // console.log(res);
        }
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
        console.log(e);
      });
  };
  const finalSubmit = () => {
    addNewData({ importData: data.sucessData }, getFinalUrl(name), {}, true)
      .then(() => {
        setData({ errorData: [], sucessData: [] });
        getList();
        setPreview(false);
        handleClose();
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
        console.log(e);
      });
  };
  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);

  const onFileChange = (file: FileList) => {
    if (file[0] && file[0].size) {
      if (file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 10240) {
          toast.error('File is too big, Please select a file less than 10MB', alertOptions);
          return;
        }
      } else {
        toast.error('You can upload documents of types XLSX only', alertOptions);
        return;
      }
    }
    setfileupload(file);
    hiddenFileInput.current.value = '';
  };

  const handledelete = () => {
    setfileupload('');
  };
  const handleClose = () => {
    setfileupload('');
    setImportUpload(false);
  };
  return (
    <>
      {loader && <Loader />}
      <Modal open={importUpload} onClose={handleClose}>
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '1rem',
            }}>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
              />
            </Typography>
          </Typography>
          <Typography
            sx={{ fontSize: '20px', fontWeight: '600', textAlign: 'center', marginBottom: '1rem' }}>
            Import{' '}
            {productAdmin ? 'Product Administrator' : name.charAt(0).toUpperCase() + name.slice(1)}
          </Typography>
          <form id='offer-status' className='private-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Grid container className='mb-16'>
                    <Grid item md={8} sm={12} xs={12}>
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        value={downloadOption}
                        options={[
                          { label: 'Sample Document', value: 'sample' },
                          { label: 'Country', value: 'countries' },
                          { label: 'State', value: 'states' },
                          { label: 'City', value: 'cities' },
                          { label: 'Branches', value: 'branches' },
                          { label: 'Employees', value: 'users' },
                        ]}
                        disabled={name === 'users' || productAdmin ? false : true}
                        isClearable={false}
                        placeHolder='Select'
                        onChange={(e: any) => {
                          setDownloadOption(e.target.value);
                          console.log(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className='d-flex-cc'>
                      <Typography className='link-color'>
                        <div onClick={() => downloadSample(downloadOption)}>Download</div>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => {
                      return (
                        <div
                          {...getRootProps({
                            className: 'dropzone container container-position pointer',
                            onClick: () => {
                              hiddenFileInput.current?.click();
                            },
                          })}>
                          <div>
                            <input {...getInputProps()} />
                            <p className='img-container'>
                              <img src={Fileupload} alt='file upload' />
                            </p>
                            <div className='fil-uplod-desc'>
                              <p className='first-heading'>
                                <span className='frst-span' role='button'>
                                  Click to upload
                                </span>{' '}
                                <span className='drago'>or drag and drop</span>
                              </p>
                              <p className='docx'>Upload only XLSX (up to 10MB)</p>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Dropzone>
                </Typography>
                <div>
                  {fileUpload ? (
                    <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                      <Typography component={'div'} className='custom-field file-view-box'>
                        <Typography component={'p'} className='first-children first-green-children'>
                          <div className='image-container'>
                            <span className='image image-success'>
                              <img
                                style={{ width: '16px', height: '16px' }}
                                src={Xlsxpng}
                                alt='Wordpng'
                              />
                            </span>
                            <span className={'texted'}>
                              <TableTooltip
                                value={
                                  fileUpload && fileUpload[0]
                                    ? fileUpload[0]?.name
                                    : fileUpload
                                      ? fileUpload.fileName
                                      : ''
                                }
                                positionfile={true}></TableTooltip>
                            </span>
                          </div>
                          <div className='Crossicon Crossicon-deletebucket' onClick={handledelete}>
                            <img src={Deletebucket} alt='Crossicon' />
                          </div>
                        </Typography>
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'center' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button form={'offer-status'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <Modal open={preview} onClose={handlePreviewClose} sx={{ width: '80%' }}>
        <Box className='email-modal' sx={{ left: '62%', minHeight: '500px', overflowY: 'hidden' }}>
          <Typography component={'div'}>
            <Typography
              className='text-right'
              sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: '600',
                }}>
                List of{' '}
                {productAdmin
                  ? 'Product Administrator'
                  : name.charAt(0).toUpperCase() + name.slice(1)}
              </Typography>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handlePreviewClose}
              />
            </Typography>
          </Typography>
          <TabContext value={tabValue}>
            <Typography
              component={'div'}
              className='d-flex-a d-flex-sb custom-position-tab'
              style={{ marginTop: '10px' }}>
              <Box
                sx={{
                  borderColor: 'divider',
                  borderRadius: '8px',
                  background: '#F5F6FA',
                  padding: '5px',
                }}>
                <TabList onChange={handleChange} aria-label='lab API tabs example'>
                  <Tab label='Valid Data' value='1' />
                  <Tab label='Invalid Data' value='2' />
                </TabList>
              </Box>
            </Typography>
            <Typography component={'div'}>
              <TabPanel
                value='1'
                sx={{ padding: '10px 0px', overflowY: 'scroll', maxHeight: '60vh' }}>
                {data && data?.sucessData?.length ? (
                  <TableContainer
                    sx={{
                      border: '1px solid #E4E7EC',
                      borderRadius: '8px',
                    }}>
                    <Table className='custom-table' aria-label='simple table'>
                      <TableHead>
                        {header &&
                          header.map((e: any) => {
                            return (
                              <TableCell key={e} align='left'>
                                {e}
                              </TableCell>
                            );
                          })}
                      </TableHead>
                      {name === 'branch' ? (
                        <TableBody>
                          {data?.sucessData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.branchName ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.branchType ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'designation' ? (
                        <TableBody>
                          {data?.sucessData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.designationName ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.designationType ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'industry' ? (
                        <TableBody>
                          {data?.sucessData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.industryType ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.description ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'qualification' ? (
                        <TableBody>
                          {data?.sucessData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.qualification ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.degree ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.specialization ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'functional-area' ? (
                        <TableBody>
                          {data?.sucessData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.functionalArea ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.description ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'holiday' ? (
                        <TableBody>
                          {data?.sucessData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.branchName ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.eventName ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.eventDate
                                  ? moment(errors?.eventDate).format('DD-MMM-YYYY')
                                  : '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.description ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'users' && productAdmin ? (
                        <TableBody>
                          {data?.sucessData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '100px',
                                }}>
                                {errors?.employeeId ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.firstName ?? '-'}</TableCell>
                              <TableCell align='left'>{errors?.lastName ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                {errors?.country ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                {errors?.state ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.city ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.pincode ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ minWidth: '250px' }}>
                                {errors?.email ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '150px' }}>
                                {errors?.phoneNo
                                  ? `${
                                      errors?.countrycode ? errors?.countrycode : ''
                                    } ${errors?.phoneNo}`
                                  : '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '150px' }}>
                                {errors?.branch ?? '-'}
                              </TableCell>
                              <TableCell align='center' sx={{ minWidth: '100px' }}>
                                {errors?.saturdayLeave ?? '-'}
                              </TableCell>
                              <TableCell align='center' sx={{ minWidth: '100px' }}>
                                {errors?.sundayLeave ?? '-'}
                              </TableCell>{' '}
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'users' ? (
                        <TableBody>
                          {data?.sucessData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '100px',
                                }}>
                                {errors?.employeeId ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.firstName ?? '-'}</TableCell>
                              <TableCell align='left'>{errors?.lastName ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.role ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.designation ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                {errors?.country ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                {errors?.state ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.city ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.pincode ?? '-'}</TableCell>
                              <TableCell align='left'>{errors?.l1Reporting ?? '-'}</TableCell>{' '}
                              <TableCell align='left'>{errors?.l2Reporting ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ minWidth: '250px' }}>
                                {errors?.email ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '200px' }}>
                                {errors?.phoneNo
                                  ? `${
                                      errors?.countrycode ? errors?.countrycode : ''
                                    } ${errors?.phoneNo}`
                                  : '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.branch ?? '-'}
                              </TableCell>
                              <TableCell align='center'>{errors?.saturdayLeave ?? '-'}</TableCell>
                              <TableCell align='center'>
                                {errors?.sundayLeave ?? '-'}
                              </TableCell>{' '}
                              <TableCell align='center'>{errors?.crm ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        ''
                      )}
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '43px',
                    }}>
                    <NCAdedIcon />
                    <Typography className='no-client'>No Data Found</Typography>
                  </div>
                )}
              </TabPanel>
              <TabPanel value='2' sx={{ padding: '10px 0px', overflow: 'auto', maxHeight: '60vh' }}>
                {data && data?.errorData?.length ? (
                  <TableContainer sx={{ border: '1px solid #E4E7EC', borderRadius: '8px' }}>
                    <Table className='custom-table' aria-label='simple table'>
                      <TableHead>
                        {header &&
                          header.map((e: any) => {
                            return (
                              <TableCell key={e} align='left'>
                                {e}
                              </TableCell>
                            );
                          })}
                        <TableCell>Remarks</TableCell>
                      </TableHead>
                      {name === 'branch' ? (
                        <TableBody>
                          {data?.errorData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.branchName ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.branchType ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.remarks ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'designation' ? (
                        <TableBody>
                          {data?.errorData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.designationName ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.designationType ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.remarks ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'industry' ? (
                        <TableBody>
                          {data?.errorData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.industryType ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.description ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.remarks ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'qualification' ? (
                        <TableBody>
                          {data?.errorData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.qualification ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.degree ?? '-'}
                              </TableCell>{' '}
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.specialization ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.remarks ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'functional-area' ? (
                        <TableBody>
                          {data?.errorData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.functionalArea ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.description ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.remarks ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'holiday' ? (
                        <TableBody>
                          {data?.errorData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                }}>
                                {errors?.branchName ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.eventName ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.eventDate
                                  ? moment(errors?.eventDate).format('DD-MMM-YYYY')
                                  : '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.description ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                {errors?.remarks ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'users' && productAdmin ? (
                        <TableBody>
                          {data?.errorData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '100px',
                                }}>
                                {errors?.employeeId ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.firstName ?? '-'}</TableCell>
                              <TableCell align='left'>{errors?.lastName ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                {errors?.country ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                {errors?.state ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.city ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.pincode ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ minWidth: '300px' }}>
                                {errors?.email ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '200px' }}>
                                {errors?.phoneNo
                                  ? `${
                                      errors?.countrycode ? errors?.countrycode : ''
                                    } ${errors?.phoneNo}`
                                  : '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '150px' }}>
                                {errors?.branch ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.saturdayLeave ?? '-'}</TableCell>
                              <TableCell align='left'>{errors?.sundayLeave ?? '-'}</TableCell>{' '}
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '250px' }}>
                                {errors?.remarks ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : name === 'users' ? (
                        <TableBody>
                          {data?.errorData.map((errors: any, i: number) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                align='left'
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '100px',
                                }}>
                                {errors?.employeeId ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.firstName ?? '-'}</TableCell>
                              <TableCell align='left'>{errors?.lastName ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.role ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.designation ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                {errors?.country ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ textTransform: 'capitalize' }}>
                                {errors?.state ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.city ?? '-'}
                              </TableCell>
                              <TableCell align='left'>{errors?.pincode ?? '-'}</TableCell>
                              <TableCell align='left'>{errors?.l1Reporting ?? '-'}</TableCell>{' '}
                              <TableCell align='left'>{errors?.l2Reporting ?? '-'}</TableCell>
                              <TableCell align='left' sx={{ minWidth: '250px' }}>
                                {errors?.email ?? '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '200px' }}>
                                {errors?.phoneNo
                                  ? `${
                                      errors?.countrycode ? errors?.countrycode : ''
                                    } ${errors?.phoneNo}`
                                  : '-'}
                              </TableCell>
                              <TableCell align='left' sx={{ minWidth: '100px' }}>
                                {errors?.branch ?? '-'}
                              </TableCell>
                              <TableCell align='center'>{errors?.saturdayLeave ?? '-'}</TableCell>
                              <TableCell align='center'>
                                {errors?.sundayLeave ?? '-'}
                              </TableCell>{' '}
                              <TableCell align='center'>{errors?.crm ?? '-'}</TableCell>
                              <TableCell
                                align='left'
                                sx={{ paddingLeft: '23px !important', minWidth: '100px' }}>
                                {errors?.status ?? '-'}
                              </TableCell>
                              <TableCell
                                align='left'
                                sx={{ paddingLeft: '23px !important', minWidth: '250px' }}>
                                {errors?.remarks ?? '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        ''
                      )}
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '43px',
                    }}>
                    <NCAdedIcon />
                    <Typography className='no-client'>No Data Found</Typography>
                  </div>
                )}
              </TabPanel>
            </Typography>
          </TabContext>
          {data?.sucessData?.length ? (
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                component={'div'}
                sx={{ justifyContent: 'end', marginTop: '20px' }}
                className='d-flex-a'>
                <Button className='cancel-btn mr-16' onClick={handlePreviewClose}>
                  Cancel
                </Button>
                <Button className='s-add-btn' onClick={finalSubmit}>
                  Submit
                </Button>
              </Typography>
            </Grid>
          ) : (
            ''
          )}
        </Box>
      </Modal>
    </>
  );
};
export default ImportFunctionality;
